import React from 'react';
import InlineEditableElement from 'Components/InlineEditableElement';
import _ from 'underscore';
import 'jquery.appear';

function headingHoc(Component){

  return class HeadingHoc extends React.Component{

    constructor(props){
      super(...arguments);
      this.fontFamilyChanged = this.fontFamilyChanged.bind(this);
    }

    componentDidMount(){
      const section = this.props.section;
      const scrollAnimation = section.getAnimationClass('scroll');
      if (scrollAnimation) {
        const toBeAnimated = $(`#heading-${section.get('id')}`);
        toBeAnimated.appear(() => {
          toBeAnimated.addClass(scrollAnimation);
          toBeAnimated.one('webkitAnimationEnd oanimationend msAnimationEnd animationend',
            () => {
              toBeAnimated.removeClass(scrollAnimation);
            });
        });
      }
    }

    titleChanged(value){
      this.props.section.prop('title', value);
    }

    subtitleChanged(value){
      this.props.section.prop('subtitle', value || '');
    }

    fontFamilyChanged(fonts) {
      let extantFonts = this.props.section.prop('fonts') || [];
      extantFonts.forEach((font) => {
        fonts.push(font);
      });
      let uniqueFamilies = [];
      $.each(fonts, function(i, el) {
        if ($.inArray(el, uniqueFamilies) === -1) uniqueFamilies.push(el);
      });
      uniqueFamilies.sort((a, b) => {
        return a > b
      });
      if (uniqueFamilies.join(',') !== extantFonts.join(',')) {
        this.props.section.prop('fonts', uniqueFamilies);
        FW.store.get('page').getFonts();
      }
    }

    render(){
      let props = _.clone(this.props);
      props.fontFamilyChanged = this.fontFamilyChanged;
      const section = props.section;
      let shadowCSS = section.ls('text-shadow') ? section.ls('text-shadow').shadow_css : '';
      return <div style={{textShadow: shadowCSS}} className={section.getAnimationClass('load')} id={`heading-${section.get('id')}`}>
        <Component {...props} titleChanged={this.titleChanged} subtitleChanged={this.subtitleChanged} inlineEditorProps={{enter: 2}} />
      </div>
    }

  };

}

class Heading extends React.Component{

  render(){
    return (
      <div>
        <InlineEditableElement el='h1' {...this.props.inlineEditorProps} html={title} onChange={this.props.titleChanged.bind(this)} inlineEditingEnabled={this.props.inlineEditingEnabled} />
        {subtitle || this.props.inlineEditingEnabled ? <InlineEditableElement el='h4' {...this.props.inlineEditorProps} html={subtitle} onChange={this.props.subtitleChanged.bind(this)} inlineEditingEnabled={this.props.inlineEditingEnabled} /> : null}
      </div>
    );
  }

}

export {headingHoc as hoc};
export default headingHoc(Heading);
