import React from 'react';
import InlineEditableElement from 'Components/InlineEditableElement';
import _ from 'underscore';
import 'jquery.appear';

function jumbtronHoc(Component) {
  return class JumbotronHOC extends React.Component {
    constructor(props) {
      super(...arguments);
      this.props = props;
      this.mouseLeave = this.mouseLeave.bind(this);
      this.mouseOver = this.mouseOver.bind(this);
      this.state = {};
    }

    valueChanged(value) {
      this.props.section.prop('content', value);
    }

    fontFamilyChanged(fonts) {
      this.props.section.prop('fonts', fonts);
      FW.store.get('page').getFonts();
    }

    componentDidMount() {
      const section = this.props.section;
      const scrollAnimation = section.getAnimationClass('scroll');
      if (scrollAnimation) {
        const toBeAnimated = $(`#animatedContainer-${section.get('id')}`);
        toBeAnimated.appear(() => {
          toBeAnimated.addClass(scrollAnimation);
          toBeAnimated.one(
            'webkitAnimationEnd oanimationend msAnimationEnd animationend',
            () => {
              toBeAnimated.removeClass(scrollAnimation);
            }
          );
        });
      }
    }

    mouseOver() {
      let toBeAnimated = $(
        '#animatedContainer-' + this.props.section.get('id')
      ).find('.actionAnimated');
      toBeAnimated.addClass(this.props.section.getAnimationClass('hover'));
    }

    mouseLeave() {
      let toBeAnimated = $(
        '#animatedContainer-' + this.props.section.get('id')
      ).find('.actionAnimated');
      toBeAnimated.removeClass(this.props.section.getAnimationClass('hover'));
    }

    render() {
      let section = this.props.section;
      let props = _.clone(this.props);
      props.valueChanged = this.valueChanged;
      props.fontFamilyChanged = this.fontFamilyChanged;
      //Shadow
      let shadowCSS = section.ls('text-shadow')
        ? section.ls('text-shadow').shadow_css
        : '';
      //Animations
      props.loadAnimation = this.props.section.getAnimationClass('load');
      props.actionAnimation = 'actionAnimated';
      props.mouseOver = this.mouseOver;
      props.mouseLeave = this.mouseLeave;

      return (
        <div
          style={{
            height: section.prop('height') || null,
            textShadow: shadowCSS
          }}
          onMouseLeave={this.mouseLeave}
          onMouseOver={this.mouseOver}
          id={'animatedContainer-' + section.get('id')}
        >
          <Component {...props} />
        </div>
      );
    }
  };
}

class Jumbotron extends React.Component {
  render() {
    let section = this.props.section;
    return (
      <div className={this.props.loadAnimation || ''}>
        <div className={this.props.actionAnimation || ''}>
          <InlineEditableElement
            size={this.props.size}
            fontFamilyChanged={this.props.fontFamilyChanged.bind(this)}
            onChange={this.props.valueChanged.bind(this)}
            html={section.prop('content')}
            inlineEditingEnabled={this.props.inlineEditingEnabled}
          />
        </div>
      </div>
    );
  }
}

export {jumbtronHoc as hoc};
export default jumbtronHoc(Jumbotron);
