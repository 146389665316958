import React from 'react';
import {Helper} from '../../Helpers';
import Loading from '../../CommonElements/Loading';

class GroupBackground extends React.Component{

  constructor(props){
    super(...arguments);
    let backgroundFile = FW.Models.File.findOrCreate({id: props.group.prop('background_file_id')});
    backgroundFile.ensureLoaded().then(() => {
      this.forceUpdate();
    });
    this.video = React.createRef();
  }

  componentDidMount() {
    this.props.group.on('change:properties change:layout_settings', () => {
      this.forceUpdate();
    }, this);
  }

  componentDidUpdate() {
    if (this.video && this.video.current && this.video.current.currentTime === 0) {
      this.video.current.load();
      if (!FW.inEditor()) {
        this.video.current.play();
      }
    }
  }

  componentWillUnmount() {
    this.props.group.off(null, null, this);
  }

  render(){
    let group = this.props.group;
    let backgroundFile = FW.Models.File.findOrCreate({id: group.prop('background_file_id')});

    let parallaxClass = group.ls('parallax') || 'fw-parallax';
    let isParallax = parallaxClass === 'fw-parallax';

    let imageBackgroundSrc = backgroundFile ? backgroundFile.helper.thumbnailUrl('optimized') : null;
    let backgroundCss = group.getBackgroundCss();
    if (typeof backgroundCss === 'string' && backgroundCss.indexOf('rgba') === 0){
      backgroundCss = Helper.rgbaToHex(backgroundCss);
    }
    let backgroundMuted = typeof group.ls('background_muted') === 'undefined' || group.ls('background_muted') === 'true' || group.ls('background_muted');

    return [
      group.prop('background_type') === 'video' ? <div className={parallaxClass} key="background-video">
        {group.ls('show_loading_indicator') ? <div style={{
          position: 'absolute',
          display: 'flex',
          top: 0, left: 0, right: 0, bottom: 0,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: isParallax ? 0 : null
        }}>
          <Loading wheel />
        </div> : null}
        {group.prop('background_file_id') ? <video
          loop={group.ls('background_play_once') ? false : true}
          ref={this.video}
          autoPlay
          muted={backgroundMuted}
          poster={group.ls('show_loading_indicator') ? backgroundFile.helper.thumbnailUrl('optimized') : null}
          style={{zIndex: isParallax ? 1 : null, objectFit: !!group.ls('constrain_video_height') && !!group.ls('constrain_video_width') ? 'fill' : 'cover', height: group.ls('constrain_video_height') ? '100%' : null, width: group.ls('constrain_video_width') ? '100%' : null}}
          playsInline>
          <source src={backgroundFile.backgroundUrl} />
        </video> : null}
      </div> : null,
      group.prop('background_type') === 'image' ? <div
        key="background-image"
        className={parallaxClass}
        role="img"
        aria-label={backgroundFile.get('title')}
        style={{
          backgroundSize: group.ls('background_size') || 'cover',
          backgroundImage: 'url(' + imageBackgroundSrc + ')',
          backgroundRepeat: 'no-repeat'
        }}/> : null,
      <div
        key="background-overlay"
        className="fw-overlay"
        style={{
          opacity: group.getBackgroundOpacity(),
          background: backgroundCss,
          backgroundRepeat: 'repeat',
          marginTop: 0 // need to get this from $marginTop in php
        }}
      />
    ];
  }

}

export default GroupBackground;
