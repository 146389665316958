import {Model} from './Model';
import {Collection} from './Collection';

class SchedulableReservationOverlap extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/schedulable-reservation-overlaps';
  }

  /**
   */
  get defaults() {
    return {
      type: 'schedulable_reservation_overlap'
    };
  }
}

class SchedulableReservationOverlaps extends Collection {}

SchedulableReservationOverlaps.prototype.model = SchedulableReservationOverlap;

export {SchedulableReservationOverlap, SchedulableReservationOverlaps};
