import React from 'react';

export default function PayPalDisplay({value}) {

  return <div style={{textAlign: 'center'}}>
    <form target="_blank" action={'https://www.paypal.com/cgi-bin/webscr'} method="post">
      <input type="hidden" name="business"
             value={value.business}/>

      <input type="hidden" name="cmd" value="_donations"/>

      {value.item_name ? <input type="hidden" name="item_name" value={value.item_name}/> : null}
      {value.item_number ? <input type="hidden" name="item_number" value={value.item_number}/> : null}
      {value.fixed_amount && value.fixed_amount > 0 ? <input type="hidden" name="amount" value={value.fixed_amount}/> : null}
      <input type="hidden" name="currency_code" value={value.currency_code}/>

      <input type="image" name="submit"
             src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
             alt="Donate"/>
      <img alt="" width="1" height="1"
           src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"/>
    </form>
  </div>;

}
