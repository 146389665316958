import FileHelper from './FileHelper';

export default class Audio extends FileHelper {

  featuredUrl(size) {
    if (this._file.get('location') === 'remote' && this._file.get('details') && this._file.get('details').thumbnail_url) {
      return this._file.get('details').thumbnail_url;
    }
    return super.featuredUrl(size);
  }

}
