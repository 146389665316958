import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {ApplicationFormFieldOption, ApplicationFormFieldOptions} from './ApplicationFormFieldOption';
import _ from 'underscore';
import moment from 'moment';
import {File} from './File';
import config from '../../config';
import {ApplicationForm} from './ApplicationForm';

class ApplicationFormField extends Model {

  get modelUrl() {
    return '/application-form-fields';
  }

  get defaults() {
    return {
      type: 'application_form_field'
    };
  }

  get(key) {
    if (key === 'options') {
      return this.get('application_form_field_options');
    }
    return super.get(...arguments);
  }

  prop(){}
  setSelectedValue(){}

  hasOptions(){
    return ['Select', 'Radio', 'MultiCheckbox'].indexOf(this.get('field_type')) >= 0;
  }

  getAnswerForDisplay(application) {
    const answer = application.get('answers')[this.get('id')];
    if (this.hasOptions()) {
      const options = this.get('application_form_field_options');
      let textAnswer = [];
      if (typeof answer === 'string') {
        return options.get(answer).get('name');
      } else if (typeof answer === 'object') {
        answer.forEach((a) => {
          let option = options.get(a);
          textAnswer.push(<li key={a}>{option.get('name')}</li>);
        });
        return <ul>{textAnswer}</ul>;
      }
    }
    switch (this.get('field_type')) {
      case 'Input':
      case 'TextArea':
        return answer;
        break;
      case 'Checkbox':
        return answer ? 'Yes' : 'No';
      case 'Date':
        return moment(answer).format('LL');
      case 'Reference':
        const references = application.get('application_references').where({
          application_field_id: this.get('id')
        });
        return <ul>
          {references.map((r) => {
            return <li key={r.get('id')}>
              {r.get('first_name')} {r.get('last_name')} - {r.isSubmitted() ? <a href={`/admin/ame/references/${r.get('id')}`} target="_blank">View References</a> : <span>Pending Completion</span>}
            </li>
          })}
        </ul>
        break;
      case 'Document':
        const file = File.findOrCreate({
          id: answer
        });
        return <a href={file.downloadLink} target="_blank">{file.get('title')}</a>;
        break;
      default:
        return answer;
    }
  }

}

ApplicationFormField.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'application_form_field_options',
    relatedModel: ApplicationFormFieldOption,
    collectionType: ApplicationFormFieldOptions,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'application_form_field'
    }
  }
];
ApplicationFormField.prototype.sortField = "sort_order";
ApplicationFormField.prototype.sortOrder = 'ASC';

class ApplicationFormFields extends Collection {}
ApplicationFormFields.prototype.model = ApplicationFormField;

export {ApplicationFormFields, ApplicationFormField}
