import {Backbone} from 'FWBackbone';
import config from '../../config';
import _ from 'underscore';

/**
 * @class FW.Collection
 * @classDesc All collections should be extended from this. It implements a couple necessary root methods.
 */
class Collection extends Backbone.Relational.Collection{

  constructor(models, options){
    super(...arguments);
    this._query = {};
    if (options && options.parent){
      this._parent = options.parent;
    }
    if (typeof options === 'object'){
      _.extend(this, options);
    }
    let total = typeof models === 'object' && models !== null ? models.length : 0;
    this.paginationDefaults = {
      number: 1,
      last: 1,
      size: 20,
      total: total
    };
    this.pagination = _.clone(this.paginationDefaults);
    this._includedRelations = [];
    this._filterMode = 'or';
  }

  get comparator(){
    return (model1, model2) => {
      let col = this;
      let sortField = col.sortField;
      let sortOrder = col.sortOrder;
      if (typeof sortField === 'undefined'){
        return null;
      } else {
        let value1 = typeof model1.get(sortField) !== 'undefined' ? model1.get(sortField) : model1.get(model1.idAttribute);
        let value2 = typeof model2.get(sortField) !== 'undefined' ? model2.get(sortField) : model2.get(model1.idAttribute);
        if (sortField === 'created_at' || sortField === 'updated_at'){
          value1 = (new Date(value1)).getTime();
          value2 = (new Date(value2)).getTime();
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
          value1 = value1.toLowerCase();
          value2 = value2.toLowerCase();
        }

        if (value1 < value2 && sortOrder === 'ASC' || value1 > value2 && sortOrder === 'DESC'){
          return -1;
        } else {
          return 1;
        }
      }

    };
  }

  get url() {
    let modelUrl = this.model.prototype.modelUrl ? this.model.prototype.modelUrl : '';
    if (this._parent) {
      modelUrl = this._parent.modelUrl + '/' + this._parent.get('id') + modelUrl;
    }
    let queryString = this.model.prototype.getQueryString();
    if (queryString.indexOf('?') < 0) {
      queryString = queryString + '?';
    }
    if (this.pagination && this.pagination.number){
      queryString = queryString + '&page[number]=' + this.pagination.number;
      if (this.pagination.size){
        queryString = queryString + '&page[size]=' + this.pagination.size;
      }
    }
    if (this.sortField){
      let minus = this.sortOrder === 'DESC' ? '-' : '';
      queryString = queryString + '&sort=' + minus + this.sortField;
    }
    if (this._excludeRelationships){
      queryString = queryString + '&include=';
    } else if (this._includedRelations.length > 0) {
      queryString = queryString + '&include=' + this._includedRelations.join(',');
    }
    let i = 1;
    _.each(this._query, (obj, k) => {
      let ext = i > 1 ? ('_' + i) : '';
      _.each(obj.query, (q, k) => {
        if (typeof q === 'object' && !Array.isArray(q) && q !== null){
          q = _.map(q, (v, key) => {
            return key + ':' + v;
          });
        }
        queryString = queryString + '&filter' + ext + '[' + k + ']=' + q;
      });
      queryString = queryString + '&filter' + ext + '_mode=' + obj.mode;
      i++;
    });

    if (this._customFilter) {
      queryString += '&customFilter=' + encodeURIComponent((new URLSearchParams(this._customFilter).toString()));
    }



    var prepend;
    if (this._siteId) {
      const site = FW.store.get('sites').findWhere({
        id: this._siteId
      });
      prepend = '//' + site.get('dev_domain');
    } else {
      prepend = '';
    }

    return prepend + config.API_ROOT + modelUrl + queryString;
  }

  setSite(v) {
    this._siteId = v;
  }

  sortByField(field, order){
    order = order || (this.sortField === field ? this.sortOrder === 'DESC' ? 'ASC' : 'DESC' : 'ASC');
    this.sortField = field;
    this.sortOrder = order;
  }

  rollback(){
    return this.each((model) => {
      model.rollback();
    });
  }

  /**
   * Get models changed in the collection
   * @param options
   * @instance
   * @returns {{primary: FW.Collection, relations: {}}}
   */
  getChangedModels(opts) {
    opts = _.extend({
      returnAll: false
    }, opts);
    let saving = {
      primary: new Backbone.Relational.Collection,
      relations: {}
    };

    this.each(function(model) {
      if (model.hasChanged(true) || opts.returnAll) {
        if (model.isNew()){
          model.save();
        } else {
          saving.primary.add(model);
        }
        _.each(model.getChangedRelations({collection: 'FW.Models.Collection'}), function(obj, key) {
          if (saving.relations[key]) {
            saving.relations[key].add(obj.models);
          } else {
            saving.relations[key] = obj;
          }
        });
      }
    });

    return saving;
  }

  getIdsForDeletion(){
    let toDelete = [];
    this.each((model) => {
      if (model._toDelete) {
        toDelete.push(model.get('id'));
      }
    });
    return toDelete;
  }

  /**
   * Save all changed models in the collection
   * @instance
   * @returns {Promise|*}
   */
  save(options) {
    options = _.extend({
      parse: true,
      saveAll: false
    }, options);

    //remove models marked for deletion
    _.each(this.getIdsForDeletion(), (id) => {
      let m = this.get(id);
      if (m) {
        m.destroy();
      }
    });

    let collection = this.getChangedModels({returnAll: options.saveAll});

    _.each(collection.relations, function(relation) {
      relation.save();
    });

    if (collection.primary.length) {
      let data = {
        data: collection.primary.toJSON()
      };

      data = JSON.stringify(data);
      return _sendSaveRequest.call(this, data).then(function(newData) {
        if (options.parse){
          collection.primary.each(function(model) {
            //make sure the model's attributes match those that came back from the server.
            let attrs = {};
            attrs[model.idAttribute] = model.get(model.idAttribute);
            let serverData = _.findWhere(newData.data, attrs);
            model.set(model.parse(serverData));
            model.changed = {};
            model.newItem = false;
            model.trigger('sync');
          });
        }
        if (options.success){ options.success(); }
        //console.log('collection saved');
        return true;
      }, function () {
        Sentry.captureException(new Error('Collection not saved'));
        if (options.error) {
          options.error();
        }
        return false;
      });
    }

    return new Promise((resolve) => {
      resolve();
    });
  }

  whereIn(ids){
    let collection = new (Object.getPrototypeOf(this).constructor);
    this.each((model) => {
      if (_.indexOf(ids, model.get(model.idAttribute)) >= 0){
        collection.add(model);
      }
    });
    return collection;
  }

  destroy(ids){
    let models = null;
    if (ids){
      models = this.whereIn(ids);
    } else {
      models = this;
    }
    return new Promise((resolve, reject) => {
      let data = {
        data: models.map((model) => {
          return {
            type: Object.getPrototypeOf(model).defaults.type,
            id: model.get('id')
          };
        })
      };
      $.ajax(this.url, {
        data: JSON.stringify(data),
        method: 'DELETE',
        dataType: 'json',
        contentType: 'application/json',
        error: function(){
          reject();
        },
        success: () => {
          models.each((model) => {
            this.remove({id: model.get(model.idAttribute)});
          });
          resolve();
        }
      });
    });

  }

  /**
   * Returns an object that has key-value pairs for use in a select box
   * @param value
   * @param id
   * @returns {{}}
   */
  toSelectArray(value, id){
    if (!id){
      id = 'id';
    }
    if (!value){
      value = 'id';
    }
    let obj = {};
    this.each(function(model){
      let tempObj = {};
      tempObj[model.get(id)] = model.get(value);
      _.extend(obj, tempObj);
    });
    return obj;
  }

  handleMeta(meta){
    if (meta.page){
      this.pagination = _.extend(this.pagination, meta.page);
    }
  }

  addCustomFilter(key, value) {
    this._customFilter = {
      [key]: value
    };
  }

  removeCustomFilter(){
    delete this._customFilter;
  }

  addQuery(obj, key = 'default', mode = 'or'){
    this._query[key] = {
      mode: mode,
      query: _.clone(obj)
    };
  }

  removeQuery(key){
    delete this._query[key];
  }

  prevPage(){
    let prevPage = this.pagination.number - 1;
    return this.getPage(prevPage);
  }

  nextPage(){
    let nextPage = this.pagination.number + 1;
    return this.getPage(nextPage);
  }

  getPage(page, callback, force){
    callback = callback || function(){};
    if (!this.allLoaded && (page <= this.pagination.last || page === 1)){
      if (this.pagination.number !== page || force){
        this.pagination.number = page;
        this.fetch({
          success: () => {
            callback(this);
          }
        });
      } else {
        callback(this);
      }
    } else {
      let changed = false;
      if (page !== this.pagination.number) {
        this.pagination.number = page;
        changed = true;
      }
      let pageSize = this.pagination.size;
      let startAt = pageSize * this.pagination.number - pageSize;
      let pages = _.first(_.rest(this.models, startAt), this.pagination.size);
      callback(pages);
      if (changed)
        this.trigger('page_change');
      return pages;
    }
    return this;
  }

  getCurrentPage(page){
    if (this.allLoaded) {
      let pageSize = this.pagination.size;
      let startAt = pageSize * this.pagination.number - pageSize;
      return _.first(_.rest(this.models, startAt), this.pagination.size);
    }
    return this;
  }

  getAll(opts){
    opts = _.extend({
      excludeRelations: false
    }, opts);

    return new Promise((resolve) => {
      this._loading = true;
      opts = opts || {};
      this.pagination.number = 1;
      let origSize = this.pagination.size;
      this.pagination.size = 10000000;
      let optSuccess = opts.success;
      opts.success = (data) => {
        this.allLoaded = true;
        if (optSuccess) { optSuccess(data); }
        this._loading = false;
        this.pagination.size = origSize;
        resolve(data);
      };
      let old = this._excludeRelationships;
      this._excludeRelationships = opts.excludeRelations;
      this.fetch(opts);
      this._excludeRelationships = old;
    });
  }

  resetSearch(){
    this._searchQuery = null;
    this.allLoaded = false;
  }

  runSearch(query){
    if (query){
      if (!this._searchQuery && query){
        this._originalModels = _.clone(this.models);
      }
      const runSearch = () => {
        let fuse = new global.Fuse(_.pluck(this._originalModels, 'attributes'), {
          shouldSort: true,
          matchAllTokens: true,
          threshold: 0.1,
          location: 0,
          distance: 200,
          maxPatternLength: 200,
          keys: Object.getPrototypeOf(this).model.searchKeys
        });
        let ids = fuse.search(query).map((result) => {
          return result.item.id;
        });
        let models = _.filter(this._originalModels, (model) => {
          return _.indexOf(ids, model.get('id')) >= 0;
        });


        this._searchQuery = query;
        this.models = _.clone(models);
        this.calculatePagination();
        if (!this._searchQuery){
          this.pagination.number = 1;
        }
        this.length = this.models.length;
      };
      if (!global.Fuse) {
        import('fuse.js').then((mod) => {
          global.Fuse = mod.default;
          runSearch();
        });
      } else {
        runSearch();
      }
    } else {
      this._searchQuery = null;
      this.models = _.clone(this._originalModels);
      this.length = this.models.length;
      this.calculatePagination();
    }
    this.trigger('sync');
    this._loading = false;
    return this;
  }

  search(query, api = true, mode = 'or', callback = () => {}){
    if (api) {
      if (query === null || query === '') {
        this.removeQuery('search');
      } else {
        let obj = {};
        _.map(Object.getPrototypeOf(this).model.searchKeys, (key) => {
          key = key === 'key' ? 'id' : key;
          obj[key] = query;
        });
        this.addQuery(obj, 'search', mode);
      }
      this.getPage(1, callback, true);
    } else {
      if (!this.allLoaded){
        return this.getAll({
          success: () => {
            this.runSearch(query);
          }
        });
      }
      return this.runSearch(query);
    }
  }

  calculatePagination(){
    let size = this.pagination.size || this.paginationDefaults.size;
    let total = this.models.length;
    this.pagination = {
      size: size,
      total: total,
      last: Math.round(total / size),
      number: this.pagination.number || 1
    };
  }

  setPageSize(size, cb){
    let prevSize = this.pagination.size;
    let firstModel = this.pagination.number * prevSize - prevSize + 1;
    let newPageNum = Math.ceil(firstModel / size);
    this.pagination.number = newPageNum;
    this.pagination.size = size;
    this.currentPageSize = size;
    this.pagination.last = Math.round(this.pagination.total / size);
    if (!this.allLoaded){
      this.fetch({
        reset: true
      }).then(() => {
        if (cb)
          cb();
      });
    } else {
      this.trigger('sync');
      if (cb)
        cb();
    }
    return newPageNum;
  }

  loadMore(){
    if (this.length !== 0 && typeof this.last().get('created_at') !== 'undefined'){
      this.pagination.number = this.pagination.number + 1;
    }
    return this.fetch({remove: false});
  }

  fetchExisting(){
    return new Promise((resolve) => {
      let ids = _.map(this.models, (model) => {
        return model.get('id');
      }).toString(',');
      this._query = _.extend({
        id: ids
      }, _.clone(this._query));
      this.fetch().then(() => {
        resolve(this);
      });
      delete this._query.id;
    });

  }

  getExtraModelArgs(){
    return {site_id: FW.store.get('site').get('id')};
  }

}

/**
 * Sends the provided data to the api
 * @param {string} data - The JSON string to send
 * @private
 */
let _sendSaveRequest = function(data) {
  let col = this;
  return new Promise(function(resolve, reject) {
    $.ajax({
      url: '/api/v1' + (new col.model).modelUrl,
      method: 'PATCH',
      data: data,
      dataType: 'json',
      contentType: 'application/json',
      success: function(d) {
        col.trigger('sync');
        resolve(d);
      },
      error: function() {
        col.trigger('error');
        reject(data);
      }
    });
  });
};

export {Collection};
