import {Model} from './Model';
import {Collection} from './Collection';

class BlogCategory extends Model {
  get modelUrl() {
    return '/blog-categories';
  }

  get defaults() {
    return {
      type: 'blog_category'
    };
  }
}

class BlogCategories extends Collection {}
BlogCategories.prototype.model = BlogCategory;

export {
  /**
   * The blog post model
   */
  BlogCategory,
  /**
   * Collection of Blog Post models
   */
  BlogCategories
};
