import {Model} from './Model';
import updatesPage from './Decorators/UpdatesPage';

@updatesPage
class PageGroupLayoutSetting extends Model {
  get modelUrl() {
    return '/page-group-layout-settings';
  }

  get defaults() {
    return {
      type: 'page_group_layout_settings'
    };
  }
}

export {PageGroupLayoutSetting};
