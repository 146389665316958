import React from 'react';
import ShareButtons from 'Views/ShareButtons';

class SocialMedia extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    let options = this.props.section.prop('options');
    let shape = options.shape;
    return (
      <ShareButtons
        round={shape === 'round' ? 'round' : null}
        show_share_count={options.show_share_count}
        disabled={options.disabled}
        url={options.url || window.location.href}
        title={options.title}
        picture={options.picture}
        hashtags={options.hashtags}
        description={options.description}
      />
    );
  }
}

export default SocialMedia;
