import {Model} from './Model';
import {Collection} from './Collection';
import {PageSectionLayout, PageSectionLayouts} from './PageSectionLayout';
import {Backbone} from 'FWBackbone';

class PageSectionType extends Model {
  get modelUrl() {
    return '/page-section-types';
  }

  get defaults() {
    return {
      type: 'page_section_type'
    };
  }
}
PageSectionType.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'layouts',
    relatedModel: PageSectionLayout,
    collectionType: PageSectionLayouts,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'type'
    }
  }
];

class PageSectionTypes extends Collection {}
PageSectionTypes.prototype.model = PageSectionType;
PageSectionTypes.prototype.sortField = 'order_id';
PageSectionTypes.prototype.sortOrder = 'ASC';

export {PageSectionType, PageSectionTypes};
