import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {DnsRecord, DnsRecords} from './DnsRecord';
import config from '../../config';

class DomainName extends Model {
  get modelUrl() {
    return '/domain-names';
  }

  get defaults() {
    return {
      type: 'domain_name'
    };
  }

  static searchKeys = ['url'];

  makePrimary(){
    return $.post(`${config.API_ROOT}/domain-names/${this.get('id')}/make-primary`);
  }
}
DomainName.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'dns_records',
    relatedModel: DnsRecord,
    collectionType: DnsRecords,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'domain_name'
    }
  }
];

class DomainNames extends Collection {}
DomainNames.prototype.model = DomainName;

export {DomainName, DomainNames};
