import React, {useEffect, useState} from 'react';

export default React.memo(function BadgeCore({value, type}) {
  const [id, setId] = useState('custom_c2g_' + Helper.randomString(40));
  // include the javascript
  useEffect(() => {
    $('.c2g-sticky-button-span').parent().remove();
    $('#continue-badge').remove();
    let scriptTag = document.createElement('script');
    scriptTag.src = 'https://iframe.continuetogive.com/badges/badge_script.php';
    scriptTag.id = 'continue-badge';
    document.head.appendChild(scriptTag);
    scriptTag.onload = () => {
      let options = {
        container_selector: '#' + id,
        task: type
      };
      if (value.primary_color) {
        options.headercolor = value.primary_color;
      }
      if (value.secondary_color) {
        options.backgroundcolor = value.secondary_color;
      }
      if (value.font_family) {
        options.fontfamily = value.font_family;
      }
      if (value.font_size) {
        options.fontsize = value.v;
      }
      newC2gBadge().embed(value.account_id, options);
    };
  }, [value.display_type])

  return <div id={id}></div>;
}, () => {return true;});
