import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import {BlogPosts} from './BlogPost';

class Calendar extends Model {
  get modelUrl() {
    return '/calendars';
  }

  get defaults() {
    return {
      type: 'calendar'
    };
  }

  static searchKeys = ['title'];
}
Calendar.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'events',
    relatedModel: 'FW.Models.Event',
    collectionType: 'FW.Models.Events',
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'calendar'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'event_instances',
    relatedModel: 'FW.Models.EventInstance',
    collectionType: 'FW.Models.EventInstances',
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'calendar'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  }
];

class Calendars extends Collection {}

Calendars.prototype.sortField = 'title';
Calendars.prototype.sortOrder = 'ASC';
Calendars.prototype.model = Calendar;

export {
  /**
   * The calendar model
   */
    Calendar,
  /**
   * Collection of calendar models
   */
    Calendars
};
