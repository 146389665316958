// @flow
/**
 * The base Model that all Finalweb Models should extend
 */
import {Backbone} from 'FWBackbone';
import _ from 'underscore';
import config from '../../config';
import {Collection} from './Collection';
import moment from 'moment';
import {v1 as uuidv1} from 'uuid';
import clone from 'clone-deep';

class Model extends Backbone.Relational.Model {
  get attrs() {
    return {
      //id: 'int'
    };
  }

  static create(attrs) {
    let model = new this(
      _.extend(
        {
          id: uuidv1()
        },
        attrs
      )
    );
    model._savedToServer = false;
    model.once('request', () => {
      model._savedToServer = true;
    });
    return model;
  }

  isNew(): boolean {
    return this._savedToServer === false ? true : super.isNew();
  }

  get includedRelations(): Array<string> {
    return this._includedRelations || [];
  }

  get url(): string {
    let url = super.url();
    return () => {
      return url + this.getQueryString();
    };
  }

  get createdTime(): string {
    return this.get('created_at')
      ? moment
          .utc(this.get('created_at'))
          .local()
          .format('MM/DD/YYYY h:mm A')
      : null;
  }

  get modifiedTime(): string {
    return this.get('created_at')
      ? moment
          .utc(this.get('created_at'))
          .local()
          .calendar()
      : null;
  }

  initialize() {
    this._initAttributes = _.clone(this.attributes);
    this.justSaved = {};
    this.newItem = false;
    this._toDelete = false;
    super.initialize(...arguments);
    this._fetched = false;
    this.on('sync', () => {
      this._fetched = true;
      let iframe = document.getElementById('editFrame')
        ? document.getElementById('editFrame').contentWindow
        : {};
      if (iframe.FW) {
        iframe.FW.updateModel(this.constructor.name, this.toJSON());
      }
    });
  }

  parse(data: {}, options: {}): {} {
    if (options && options.skipMerge) {
      return;
    }
    let parsed = super.parse(data, options);
    this._initAttributes = _.extend(
      this._initAttributes || {},
      _.clone(parsed)
    );
    return parsed;
  }

  getQueryString() {
    let include = '';
    if (this.includedRelations.length > 0) {
      include = 'include=' + this.includedRelations.join(',');
    } else if (this._excludeRelationships) {
      include = 'include=';
    }
    if (this._includeHidden) {
      include += '&includeHidden';
    }
    let queryString = include ? '?' + include : '';
    return queryString;
  }

  urlRoot() {
    return (
      config.API_ROOT +
      (Object.getPrototypeOf(this).modelUrl
        ? Object.getPrototypeOf(this).modelUrl
        : '')
    );
  }

  /**
   * Get the next model in this model's collection
   * @returns {*}
   */
  next() {
    return this.collection.at(this.collection.indexOf(this) + 1);
  }

  /**
   * Get the previous model in this model's collcetion
   * @returns {*}
   */
  prev() {
    const index = this.collection.indexOf(this);
    if (index === 0) {
      return;
    }
    return this.collection.at(index - 1);
  }

  /**
   * Gets a model by its id.
   * @memberOf module:FW/Models/Model
   * @param {int} id - The id of the model
   */
  static find(id) {
    let obj = this.findOrCreate({id: id});
    obj.fetch({async: false});
    return obj;
  }

  setTimestamps() {
    let now = new Date()
      .toISOString()
      .substring(0, 19)
      .replace('T', ' ');
    this.set({
      created_at: now,
      updated_at: now
    });
  }

  rollback() {
    if (this.newItem) {
      this.destroy();
    } else {
      this._toDelete = false;
      let attrs = _.clone(this._initAttributes);
      _.each(this._relations, (value, key) => {
        delete attrs[key];
      });
      this.set(attrs);
    }
  }

  toDelete() {
    this._toDelete = true;
  }

  fetch(options) {
    options = typeof options !== 'undefined' ? options : {};
    this._fetching = true;
    this._includeHidden = options.includeHidden;
    this.once('sync', () => {
      delete this._fetching;
    });
    return super.fetch(...arguments);
  }

  isLoaded(opts){
    let prop = opts && opts.required ? opts.required : 'created_at';
    return typeof this.get(prop) !== 'undefined';
  }

  ensureLoaded(opts) {
    return new Promise((resolve, reject) => {
      let prop = opts && opts.required ? opts.required : 'created_at';
      if (this.get(prop)) {
        resolve(this);
      } else if (this.get('id') && !this._fetching) {
        this.fetch(opts).then(() => {
          resolve(this);
        }).catch((e) => {
          reject(e);
        });
      } else if (this._fetching) {
        let checkLoaded = setInterval(() => {
          if (this.get(prop)) {
            clearInterval(checkLoaded);
            resolve(this);
          }
        }, 50);
      } else {
        console.log('ELSE', this);
      }
    });
  }

  handleMeta(meta) {
    //console.log('Handle Model Meta: ', meta);
  }

  getAsync(attr, options) {
    if (this.get(attr) instanceof Collection) {
      this.get(attr).fetch(options);
    } else {
      super.getAsync.apply(this, arguments);
    }
  }

  static defaultCollectionOptions(model) {
    return {
      parent: model,
      parentPath: model.modelUrl,
      parentId: model.get('id')
    };
  }

  saveManyRelation(name) {
    return new Promise(resolve => {
      let data = [];
      let relation = this.get(name);
      relation.each(rel => {
        data.push({id: rel.get('id'), type: rel.defaults.type});
      });
      $.ajax({
        data: JSON.stringify({
          data: data
        }),
        method: 'PATCH',
        url: relation.url,
        headers: {'Content-Type': 'application/vnd.api+json'},
        success: () => {
          resolve();
        }
      });
    });
  }

  save() {
    try {
      this.justSaved = clone(this.changed);
    } catch(e){}
    return super
      .save(...arguments)
      .then(() => {
        if (this.hiddenAttributes) {
          _.each(this.hiddenAttributes, attr => {
            this.unset(attr, {silent: true});
          });
        }
      })
      .catch(function(resp) {
        if (resp.responseJSON.errors.length > 0) {
          let error = resp.responseJSON.errors[0];
          FW.getParent().swal.fire(error.title, error.detail, 'error');
        }
      });
  }
}

export {Model};
