import _ from 'underscore';
import clone from 'clone';

class PermissionsManager {
  constructor(model, permissionDetails) {
    this._model = model;
    this._details = permissionDetails || {};
  }

  getPermission(siteId, permission) {
    if (siteId === 'global') {
      return (clone(this._details).global || {})[permission];
    }
    return (clone(this._details)['site-' + siteId] || {})[permission];
  }

  getEffectivePermission(siteId, permission) {
    let details = clone(this._model.effectivePermissionDetails);
    if (siteId === 'global') {
      return (details.global || {})[permission];
    }
    return (details['site-' + siteId] || {})[permission];
  }

  setPermission(siteId, permission, value) {
    let details = clone(this._details);
    let siteString = siteId === 'global' ? 'global' : 'site-' + siteId;
    let curSite = details[siteString];
    let site = Array.isArray(curSite) ? {} : !curSite ? {} : curSite;
    site[permission] = value;
    details[siteString] = site;
    this._details = clone(details);
    this.persist();
    return this._details;
  }

  addModel(siteId, permission, model) {
    let id = model.get('uuid') ? model.get('uuid') : model.get('id');
    let perm = this.getPermission(siteId, permission) || [];
    if (typeof perm === 'boolean') {
      perm = [];
    }
    perm.push(id);
    this.setPermission(siteId, permission, perm);
    this.persist();
  }

  removeModel(siteId, permission, model) {
    let id = model.get('uuid') ? model.get('uuid') : model.get('id');
    let perm = this.getPermission(siteId, permission);
    this.setPermission(siteId, permission, _.without(perm, id));
    this.persist();
  }

  removeAll(siteId, permission) {
    this.setPermission(siteId, permission, []);
  }

  removePermission(siteId, permission) {
    let siteString = siteId === 'global' ? 'global' : 'site-' + siteId;
    let details = clone(this._details);
    let site = details[siteString] || {};
    delete site[permission];
    details[siteString] = site;
    this._details = clone(details);
    this.persist();
    return this._details;
  }

  persist() {
    this._model.set('permission_details', clone(this._details));
  }

  getPermissionDetails(siteId, p) {
    let permission = p.get('slug');
    let perm = this.getEffectivePermission(siteId, permission);
    let pe = this.getPermission(siteId, permission);
    let canDoEverything = this._model.may('do_everything');
    let effectiveChecked = typeof perm !== 'undefined' && perm !== false;
    let assignedChecked = typeof pe !== 'undefined' && pe !== false;
    let disabled = (!assignedChecked && effectiveChecked) || canDoEverything;

    return {
      effective: perm,
      assigned: pe,
      effectiveChecked: effectiveChecked,
      assignedChecked: assignedChecked,
      boxDisabled: disabled,
      determinate: perm === true || canDoEverything,
      showDetails:
        (this._model.may(p.get('slug'), 'partial', siteId) ||
          this._model.may('*')) &&
        p.get('model') &&
        !canDoEverything
    };
  }
}

export default PermissionsManager;
