import {Model} from './Model';
import {Collection} from './Collection';

class PageGroupLayout extends Model {
  get modelUrl() {
    return '/page-group-layouts';
  }

  get defaults() {
    return {
      type: 'page_group_layout'
    };
  }
}
class PageGroupLayouts extends Collection {}
PageGroupLayouts.prototype.model = PageGroupLayout;

export {PageGroupLayout, PageGroupLayouts};
