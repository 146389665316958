import PermissionsManager from '../PermissionsManager';
import _ from 'underscore';
import {Helper} from '../../../Helpers';

function hasPermissions(target){

  let permissionFunctions = {

    getPermittedModelIds(perm, siteId) {
      const details = this.effectivePermissionDetails;
      if (typeof siteId === 'undefined'){
        let site = FW.store.get('site');
        siteId = site ? FW.store.get('site').get('id') : null;
      }
      return details['site-' + siteId] ? details['site-' + siteId][perm] : [];
    },

    mayOnly(perm, model, siteId) {
      const details = this.effectivePermissionDetails;
      if (typeof siteId === 'undefined'){
        let site = FW.store.get('site');
        siteId = site ? FW.store.get('site').get('id') : null;
      }
      const siteDetails = details['site-' + siteId];

      if (typeof perm === 'object') {
        if (typeof siteDetails !== 'undefined' && Object.keys(siteDetails).length === perm.length) {
          return _.every(perm, (p) => {
            return siteDetails[p] && siteDetails[p].length > 0
          });
        }
      } else {
        return typeof siteDetails !== 'undefined' && Object.keys(siteDetails).length === 1 && siteDetails[perm] && siteDetails[perm].length > 0;
      }




    },

    may(perm, model, siteId){
      let id;
      if (typeof siteId === 'undefined'){
        let site = FW.store.get('site');
        siteId = site ? FW.store.get('site').get('id') : null;
      }
      if (typeof model === 'object' && model !== null){
        id = model.get('uuid') ? model.get('uuid') : model.get('id');
      } else {
        id = model;
      }
      let hasPermission = false,
      permission;
      let perms = this.effectivePermissionDetails;
      let siteDetails = perms['site-' + siteId] || {};
      if (perm.indexOf('*') >= 0 && perm !== '*') {
        let search = perm.replace(/\*/g, '');
        _.every(siteDetails, function(value, key){
          if (key.indexOf(search) >= 0) {
            permission = value;
            return false;
          }
          return true;
        });
      } else {
        permission = siteDetails[perm];
      }
      let globals = this.effectivePermissionDetails.global || {};

      if (globals.do_everything === true || siteDetails.do_everything === true) {
        hasPermission = true;
      } else if (typeof permission === 'boolean'){
        hasPermission = permission;
      } else if (typeof permission === 'object'){
        hasPermission = permission.indexOf(id) >= 0 || id === 'partial';
      } else if (typeof this.effectivePermissionDetails.global === 'object'){
        hasPermission = globals[perm] === true;
      }

      let newModel;
      if (model && model.hasPermissionsThrough) {
        if (typeof model.hasPermissionsThrough === 'object') {
          _.each(model.hasPermissionsThrough, (t) => {
            let through = model.get(t);
            if (through.length >= 0) {
              through.each((th) => {
                let newPerm = perm.split('_')[0] + '_' + th.get('type');
                if (this.may(newPerm, th)) {
                  hasPermission = true;
                }
              })
            } else {
              newModel = model.get(t);
              let newPerm = perm.split('_')[0] + '_' + newModel.get('type');
              if (this.may(newPerm, newModel)) {
                hasPermission = true;
              }
            }
          });
        } else {
          throw new Error('hasPermissionsThrough must be an array');
        }
      }

      if (model && model.specialPermissions) {
        hasPermission = model.specialPermissions(this, hasPermission, newModel)
      }

      return hasPermission;
    },

    isEditor(){
      let siteId = FW.store.get('site') ? FW.store.get('site').get('id') : null;
      return this.isEditorOfSite(siteId);
    },

    isEditorOfSite(siteId) {
      let perms = this.effectivePermissionDetails;
      let siteDetails = perms['site-' + siteId] || {};

      // make sure we don't check just view permissions
      siteDetails = _.omit(siteDetails, function(value, key) {
        return Helper.strContains(key, 'view_');
      });

      console.log('SITE DETAILS: ', this, siteDetails);

      return _.allKeys(siteDetails).length > 0 || this.may('*');
    }

  };

  Object.assign(target.prototype, permissionFunctions);
  Object.defineProperty(target.prototype, 'permissionManager', {
    get: function(){
      return new PermissionsManager(this, this.get('permission_details'));
    }
  });

}

export default hasPermissions;
