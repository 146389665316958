import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';

class PushNotification extends Model {
  get modelUrl() {
    return '/push-notifications';
  }

  get defaults() {
    return {
      type: 'push_notification'
    };
  }
}
PushNotification.prototype.relations = [];

class PushNotifications extends Collection {}
PushNotifications.prototype.model = PushNotification;
PushNotifications.prototype.sortField = 'sent_at_summary';
PushNotifications.prototype.sortOrder = 'desc';

export {
  /**
   * The push notification model
   */
    PushNotification,
  /**
   * Collection of Push Notification models
   */
    PushNotifications
};
