import {createTheme} from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#738d9e'
    },
    secondary: {
      main: '#2c4353'
    }
  }
});
