import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {ApplicationForm} from './ApplicationForm';

class ApplicationReference extends Model {

  get modelUrl() {
    return '/application-references';
  }

  get defaults() {
    return {
      type: 'application_reference'
    };
  }

  getStatus(){
    if (this.isSubmitted()) {

    } else {
      return 'In Progress'
    }
  }

  isSubmitted(){
    return this.get('submitted_at') !== null && typeof this.get('submitted_at') !== 'undefined';
  }

}

ApplicationReference.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'application_references',
    relatedModel: ApplicationReference,
    collectionType: ApplicationReferences,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'application_form',
    relatedModel: ApplicationForm
  }
];

class ApplicationReferences extends Collection {}
ApplicationReferences.prototype.model = ApplicationReference;

export {ApplicationReferences, ApplicationReference}
