import {Backbone} from 'FWBackbone';
import _ from 'underscore';
import {Model} from './Model';
import {Collection} from './Collection';
import {PageSection, PageSections} from './PageSection';
import {PageGroups, PageGroup} from './PageGroup';
import {Site} from './Site';
import config from '../../config';
import prop from './Decorators/Prop';
import hasBackground from './Decorators/HasBackground';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import PageView from '../../Page/PageView';

/**
 * Represents a website page
 */
@prop
@hasBackground
class PageModel extends Model {
  static versioned = true;

  static searchKeys = ['title'];

  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/pages';
  }

  /**
   */
  get defaults() {
    return {
      type: 'page'
    };
  }

  static sectionPath = 'groups[*].sections';
  static definitionOptionsSchema = {
    title: {
      name: 'Title',
      type: 'Input',
      display_on_definition: true
    }
  };
  static getRequiredCoreOptionValues() {
    return {};
  }
  static finishAddingFromContentDefinition(responseData) {
    let page = PageModel.findOrCreate(responseData);
    FW.editor.sendIframeTo('/page/' + page.get('slug'));
  }

  boot() {
    ReactDOM.render(
      <PageView page={this} />,
      document.getElementById('group-mount'),
      () => {
        FW.trigger('booted');
      }
    );
  }

  /**
   * Get the page's sections
   */
  sections() {
    let sections = new PageSections();
    this.get('groups').each(function(el) {
      sections.add(el.get('sections'));
    });
    return sections;
  }

  /**
   * Create a new draft for this page.
   */
  newDraft() {
    let url = [this.url().split('?')[0], 'drafts', 'new'].join('/'),
      drafts,
      self = this;
    return new Promise(function(resolve, reject) {
      $.ajax(url, {
        success: function(data) {
          drafts = new Pages(data);
          self.get('drafts').add(drafts.models);
          return resolve(drafts);
        },
        error: function() {
          reject(arguments);
        }
      });
      //this.get("drafts").add(drafts.models);
    });

    //return drafts;
  }

  /**
   * Returns true of this page is un-published.
   */
  isDraft() {
    return !this.get('latest') && typeof this.get('latest') !== 'undefined';
  }

  /**
   * Publishes the page
   * @param {{}} opts
   */
  publish(opts) {
    let self = this;
    return new Promise(resolve => {
      let success = function(data) {
        if (opts.success) {
          opts.success.apply(this, arguments);
        }
        self.set(data);
        resolve(this);
      };
      opts = _.extend(
        {
          error: function() {}
        },
        opts
      );
      let url = [this.url(), 'publish'].join('/');
      $.ajax(url, {
        success: success,
        error: opts.error
      });
    });
  }

  getLink() {
    let site = FW.Models.Site.findOrCreate({
      id: this.get('site_id')
    });
    if (site && site.get('created_at') && site.prop('simple_page_urls')) {
      return '/' + this.get('slug');
    }
    return '/page/' + this.get('slug');
  }

  addGroup(layoutId, isOnNewPage) {
    const page = this;
    return new Promise((complete, cancel) => {
      $.ajax({
        url: '/api/v1/pages/' + this.get('id') + '/groups/add/' + layoutId,
        method: 'post',
        data: {},
        success: function(data) {
          let group = new PageGroup(data.group);
          if (!isOnNewPage) Page.groups.add(group);
          complete({
            group: group,
            page: page
          });
        },
        error: cancel
      });
    });
  }

  addGroupFromResponse(responseData) {
    return new Promise((complete, cancel) => {
      let group = new FW.Models.PageGroup(responseData.group);
      group.get('sections').each(section => {
        let sectionData = responseData.sectionData[section.get('id')];
        FW.Models.PageSection.parseJsonData(sectionData);
        FW.store.set(
          'group-' + group.get('id') + '-background',
          FW.Models.File.findOrCreate(responseData.backgroundFile)
        );
        this.sections.add(section);
      });
      Page.groups.add(group);
      return complete({
        group: group,
        html: responseData.html
      });
    });
  }

  getAnchors() {
    let groups = this.get('groups');
    let anchors = [];
    groups.each(group => {
      let anchorName = group.prop('anchor_name');
      if (anchorName) {
        anchors.push(anchorName);
      }
    });
    return anchors;
  }

  getFonts() {
    let sections = [];
    FW.store
      .get('page')
      .get('groups')
      .each(function(el) {
        let models = el.get('sections').models;
        sections = sections.concat(models);
      });
    let fonts = [];
    sections.forEach(section => {
      if (section.get && section.get('properties')) {
        let sectionFonts = section.prop('fonts');
        if (sectionFonts)
          sectionFonts.forEach(font => {
            if (!fonts.includes(font)) fonts.push(font);
          });
      }
    });
    this.loadFonts(fonts);
    return fonts;
  }

  loadFonts(fonts, callback) {
    this.loadedFonts = this.loadedFonts || [];
    fonts.forEach(fontName => {
      if (fontName) {
        if (!this.loadedFonts.includes(fontName)) {
          if (window.WebFont)
            window.WebFont.load({
              google: {
                families: [fontName + '&display=swap']
              }
            });
        }
      }
    });
  }

  copy(title, siteId, copyId) {
    const id = this.get('id');
    return new Promise(complete => {
      $.post(config.API_ROOT + '/pages/' + id + '/copy/' + title, {
        siteId: siteId ? siteId : FW.store.get('site').get('id'),
        copyId: copyId
      }).then(
        slug => {
          complete({slug, siteId: siteId});
        }
      );
    });
  }

  ls(key, value) {
    return this.prop('layoutSettings.' + key, value);
  }

  showBackground() {
    let ls = this.prop('layoutSettings');
    return typeof ls !== 'undefined' && !ls.override;
  }
}

class Pages extends Collection {
  static getSimplePublished(opts) {
    opts = _.extend(
      {
        includeDrafts: false,
        siteId: FW.store.get('site').get('id')
      },
      opts
    );
    let pages = FW.store.get('simple-pages-' + opts.siteId);
    let simpleTime = FW.store.get('simple-pages-' + opts.siteId + '-time');
    if (
      typeof pages === 'undefined' ||
      (simpleTime && simpleTime.add(1, 'second') < moment())
    ) {
      pages = new Pages();
      let qs = opts.includeDrafts ? '?includeDrafts=true' : '';
      $.get(
        config.API_ROOT + '/sites/' + opts.siteId + '/simple-pages' + qs,
        {}
      ).then(response => {
        response = JSON.parse(response);
        response.forEach(page => {
          pages.add(page);
        });
        pages.trigger('sync');
      });
      FW.store.set('simple-pages-' + opts.siteId, pages);
      FW.store.set('simple-pages-' + opts.siteId + '-time', moment());
    }
    return pages;
  }
}
Pages.prototype.model = PageModel;

PageModel.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'groups',
    relatedModel: PageGroup,
    collectionType: PageGroups,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'page',
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'sections',
    relatedModel: PageSection,
    collectionType: PageSections,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'page'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'drafts',
    relatedModel: PageModel,
    collectionType: Pages,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'versions',
    relatedModel: PageModel,
    collectionType: Pages,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  }
];

export {
  /**
   * The Page Model.
   */
  PageModel,
  /**
   * The Pages Collection.
   */
  Pages
};
