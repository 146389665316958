import React from 'react';

function selectHoc(Component) {
  return class SelectHOC extends React.Component {
    constructor(props) {
      super(...arguments);
      this.state = {};
    }

    static getDerivedStateFromProps(props){
      return {
        selectValue: props.value
      };
    }

    selectChanged = e => {
      this.setState({
        selectValue: e.target.value
      });
      this.props.onChange(e.target.value);
    };

    render() {
      return (
        <Component
          {...this.props}
          onChange={this.selectChanged}
          value={this.state.selectValue}
        />
      );
    }
  };
}

export default selectHoc;
