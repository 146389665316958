import React from 'react';
import searchTableHoc from 'Administrator/Elements/SearchTableHoc';
import TextField from '@material-ui/core/TextField';
import Pagination from 'react-js-pagination';
import AdminAddButton from 'Administrator/Elements/AdminAddButton';
import AdminImportButton from './AdminImportButton';
import Loading from '../../CommonElements/Loading';
import AdminExportButton from './AdminExportButton';
import AdminDeleteButton from './AdminDeleteButton';
import AdminPurgeButton from './AdminPurgeButton';
import CustomSelectField from '../../Editor/Fields/CustomSelectField';
import {MenuItemCollection} from '../../FW/Models/MenuItem';

class SearchTable extends React.Component {

  constructor() {
    super();
    this.state = {
      sortBy: 'display_date',
      sortOrder: 'DESC',
      customFilter: null
    };
  }

  render() {
    const user = FW.store.get('user');
    return (
      <div style={{position: 'relative', height: this.props.height || 'calc(100% - 20px)'}}>
        {this.props.ready ? null : <Loading />}
        <div style={{visibility: this.props.ready ? 'visible' : 'hidden', position: 'relative', height: '100%'}}>
          <h3>{this.props.title}</h3>
          {this.props.children}
          {!this.props.hideFilters ? <div style={{
            float: 'right',
            position: 'absolute',
            top: 0,
            right: 30,
            marginTop: -5
          }}>
            {!this.props.hideSearch ? (
              <TextField
                placeholder="Search..."
                onChange={this.props.onSearch}
                value={this.props.searchBoxText}
                data-cy="search-field"
                style={{float: 'right'}}
              />
            ) : null}
            {this.props.showPaidFilter ? <CustomSelectField value={this.state.customFilter} onChange={(v) => {
              if (v === null) {
                this.props.collection.removeCustomFilter();
              } else {
                this.props.collection.addCustomFilter('paid', v);
              }
              this.props.collection.getPage(1, () => {
                this.setState({
                  customFilter: v
                });
              }, true);
            }} title="Filter By" items={[
              {
                title: 'None',
                value: null
              },
              {
                title: 'Paid',
                value: 'paid'
              },
              {
                title: 'Unpaid',
                value: 'unpaid'
              }
            ]} style={{float: 'right', marginTop: 0, width: 100, minWidth: 100}} allowNone={false} labelProps={{style: {marginTop: -10}}} />: null}
            <CustomSelectField value={this.state.sortOrder} onChange={(v) => {
              this.props.collection.sortByField(this.state.sortBy, v);
              this.props.collection.getPage(1, () => {
                this.setState({
                  sortOrder: v
                });
              }, true);
            }} title="Sort Order" items={[
              {
                title: 'Ascending',
                value: 'ASC'
              },
              {
                title: 'Descending',
                value: 'DESC'
              }
            ]} style={{float: 'right', marginTop: 0, width: 150, minWidth: 150}} allowNone={false} labelProps={{style: {marginTop: -10}}} />
            <CustomSelectField value={this.state.sortBy} onChange={(v) => {
              this.props.collection.sortByField(v, this.state.sortOrder);
              this.props.collection.getPage(1, () => {
                this.setState({
                  sortBy: v
                });
              }, true);
            }} title="Sort By" items={[
              {
                title: 'Date',
                value: 'display_date'
              },
              {
                title: 'Title',
                value: 'title'
              }
            ]} style={{float: 'right', marginTop: 0, width: 100, minWidth: 100}} allowNone={false} labelProps={{style: {marginTop: -10}}} />
          </div> : null}
          <div style={{height: 'calc(100% - 46px)', marginTop: 20, overflowY: 'scroll'}} ref={this.props.searchTable} className="searchTableScroll">
            {this.props.collection.pagination.total > this.props.collection.pagination.size && !this.props.hidePagination && !this.props.hideTopPagination ? <div style={{width: '100%', textAlign: 'center'}}>
              <Pagination
                activePage={this.props.activePage}
                itemsCountPerPage={this.props.collection.pagination.size}
                totalItemsCount={this.props.collection.pagination.total}
                pageRangeDisplayed={13}
                onChange={this.props.handlePageChange}
              />
            </div> : null}
            {this.props.table}
            {!this.props.hidePagination ? <div style={{width: '100%', textAlign: 'center'}}>
              <Pagination
                activePage={this.props.activePage}
                itemsCountPerPage={this.props.collection.pagination.size}
                totalItemsCount={this.props.collection.pagination.total}
                pageRangeDisplayed={13}
                onChange={this.props.handlePageChange}
              />
            </div> : null}
          </div>
          <div
            style={{
              textAlign: 'center',
              bottom: 0,
              position: 'absolute',
              width: '100%',
              zIndex: 20
            }}
          >
            {this.props.onAdd && (user.may('create_' + this.props.type) || this.props.forceAllowAdd) ? (
              <AdminAddButton onClick={this.props.onAdd} />
            ) : null}
            {this.props.onPurge && (user.may('delete_' + this.props.type)) ? (
              <AdminPurgeButton onClick={this.props.onPurge} />
            ) : null}
            {this.props.onImport && (user.may('create_' + this.props.type) || this.props.forceAllowAdd) ? (
              <AdminImportButton onClick={this.props.onImport} />
            ) : null}
            {this.props.onExport ? (
              <AdminExportButton onClick={this.props.onExport} />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default searchTableHoc(SearchTable);
