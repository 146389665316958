import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import {File as FWFile, Files as FWFiles} from './File';
import _ from 'underscore';

class PhotoGallery extends Model {
  get modelUrl() {
    return '/photo-galleries';
  }

  get defaults() {
    return {
      type: 'photo-gallery'
    };
  }
}
PhotoGallery.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'photos',
    relatedModel: FWFile,
    collectionType: FWFiles,
    collectionOptions: model => {
      let options = Model.defaultCollectionOptions(model);
      return _.extend(options, {
        sortField: 'gallery_order_id',
        sortOrder: 'ASC'
      });
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'cover_photo',
    relatedModel: FWFile
  }
];

class PhotoGalleries extends Collection {}
PhotoGalleries.prototype.model = PhotoGallery;
PhotoGalleries.prototype.sortField = 'sort_order';
PhotoGalleries.prototype.sortOrder = 'ASC';

export {
  /**
   * The blog model
   */
  PhotoGallery,
  /**
   * Collection of Blog models
   */
  PhotoGalleries
};
