import React from 'react';
import GroupBackground from './GroupBackground';

class NoWrapperGroupView extends React.Component {
  render() {
    let group = this.props.group;
    return (
      <div
        className="container-fluid"
        style={{
          minHeight: group.ls('min_height'),
          paddingTop: group.getPadding()
        }}
      >
        <GroupBackground group={group} />
        <div className="row group-section-holder">{this.props.children}</div>
      </div>
    );
  }
}

export default NoWrapperGroupView;
