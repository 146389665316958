var clone = require('clone');
import deepEqual from 'fast-deep-equal/react';

function prop(propField, functionName) {
  let applyFunction = function(target) {
    propField = typeof propField === 'string' ? propField : 'properties';
    functionName = typeof functionName === 'string' ? functionName : 'prop';
    let propFunctions = {};
    propFunctions[functionName] = function(key, value) {
      if (key.indexOf('.') >= 0) {
        return this['deepProp_' + functionName](key, value);
      }
      let props = clone(this.get(propField));
      if (!props || (props.length === 0 && value)) {
        props = {};
      }

      if (typeof value !== 'undefined' && !deepEqual(props[key],value)) {
        props[key] = value;
        this.set(propField, props);
      }
      return props[key];
    };
    propFunctions['deepProp_' + functionName] = function(key, value) {
      let path = key.split('.');
      let v = this[functionName](path[0]);
      let propKey = path[0];
      path.shift();
      if (typeof value !== 'undefined') {
        v = Helper.setPath(v, path.join('.'), value);
        this[functionName](propKey, v);
      }
      while (path.length > 0) {
        if (typeof v === 'undefined') {
          return v;
        }
        v = v[path.shift()];
      }
      return v;
    }

    Object.assign(target.prototype, propFunctions);
  };

  if (typeof propField === 'function') {
    applyFunction(propField);
  } else {
    return applyFunction
  }
}

export default prop;
