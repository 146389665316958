import {Helper} from '../../../Helpers';

function hasBackground(target) {
  let propFunctions = {
    bp(key) {
      if (this instanceof FW.Models.PageSection) {
        let bg = this.ls('background') || {};
        return bg[key];
      }
      return this.ls(key);
    },

    getGradientCSS(key, value) {
      let direction = this.bp('gradient_direction') || 'to right';
      let color1 = this.bp('background_color') || '#FFF';
      let color2 = this.bp('background_color2') || color1;
      return 'linear-gradient(' + direction + ',' + color1 + ',' + color2 + ')';
    },

    getBackgroundCss(key, value) {
      let css = '';
      let color = this.bp('background_color') || '';
      if (this.bp('texture') != null)
        css += 'url(../../images/patterns/' + this.bp('texture') + '), ';
      if (this.bp('gradient')) css += this.getGradientCSS();
      else css += color;

      if (css === '') {
        css = this.bp('background_css');
      }
      return css;
    },

    getBackgroundSize() {
      if (this.bp('contain')) {
        return 'contain';
      }
      return 'cover';
    },

    shouldBeFullHeight() {
      return this.ls('full_height') && !(window.innerWidth < 600 && this.bp('mobile_height') > 0);
    },

    getGroupHeight() {
      return new Promise((resolve, reject) => {
        if (this.bp('height_based_on_image')) {
          let backgroundFile = FW.Models.File.findOrCreate({
            id: this.prop('background_file_id')
          });
          let img = new Image();
          img.addEventListener('load', () => {
            let wideOrHigh = Helper.getWideOrHigh({
              height: img.naturalHeight,
              width: img.naturalWidth
            });
            resolve(wideOrHigh * 100 + 'vw');
          });
          img.src = backgroundFile.helper.thumbnailUrl('optimized');
        } else {
          if (window.innerWidth < 600 && this.bp('mobile_height') > 0) {
            resolve(this.bp('mobile_height'));
          } else {
            resolve(
              this.prop('height') && this.prop('height') > 0
                ? this.prop('height')
                : null
            );
          }
        }
      });
    },

    getBackgroundOpacity() {
      if (
        (this.prop('background_type') === 'color' ||
          this.bp('background_type') === 'color') &&
        typeof this.bp('background_opacity') === 'undefined'
      ) {
        return 1;
      }
      let opacity = this.bp('background_opacity');
      return opacity || 0;
    }
  };

  Object.assign(target.prototype, propFunctions);
}

export default hasBackground;
