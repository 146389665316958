import React from 'react';
import selectHOC from './SelectHOC';
import MenuItem from '@material-ui/core/MenuItem';
import SelectField from './FWSelectField';
import ListSubheader from '@material-ui/core/ListSubheader';

function CustomSelectField(props){

  let items = props.items ? props.items.map((item, i) => {
    if (item.type === 'header') {
      return <ListSubheader key={i}>{item.title}</ListSubheader>;
    }
    return <MenuItem key={i} value={item.value}>{item.title}</MenuItem>;
  }) : props.children;
  const errorText = props.errors && props.errors.length > 0 ? _.first(props.errors) : null;

  return <SelectField
    label={props.title}
    value={props.value}
    errorText={errorText}
    {...props}
  >
    {props.allowNone ? <MenuItem key="none" value={null}>{props.placeholder || 'None'}</MenuItem> : null}
    {items}
  </SelectField>;

}

export default selectHOC(CustomSelectField);
