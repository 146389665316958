import React from 'react';
import SelectField from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';

const styles = theme => ({
  formControl: {
    minWidth: 220,
    marginTop: 16,
    marginBottom: 8,
    marginRight: 20
  },
  selectMenu: {
    textAlign: 'left'
  }
});

class FWSelectField extends React.Component {
  render() {
    const {
      classes,
      className,
      errorText,
      value,
      helperText,
      allErrors,
      idOnly,
      isAutoField,
      allowEmpty,
      allowNone,
      labelProps,
      ...props
    } = this.props;
    const myLabelProps = labelProps ? labelProps : {};

    let renderValue = typeof value === 'undefined' || value === null ? '' : value;

    let renderValueFunc =
      typeof value === 'undefined' || value === null || value === ''
        ? value => {
            return this.props.placeholder || 'None';
          }
        : null;

    return (
      <FormControl
        name={this.props.name}
        style={this.props.style}
        className={classNames(classes.formControl, className)}
        error={typeof errorText !== 'undefined' && errorText !== null}
      >
        {this.props.label ? <InputLabel shrink {...myLabelProps}>{this.props.label}</InputLabel> : null}
        <SelectField
          {...props}
          renderValue={renderValueFunc}
          displayEmpty
          value={renderValue}
          classes={{selectMenu: classes.selectMenu}}
          inputProps={{className: this.props.classes.input, value: renderValue}}
        />
        {errorText || helperText ? (
          <FormHelperText>{errorText || helperText}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

export default withStyles(styles)(FWSelectField);
