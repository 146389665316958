import {Model} from './Model';
import {Collection} from './Collection';
import _ from 'underscore';

class Template extends Model{

  constructor(){
    super(...arguments);
    this.GroupViews = {};
  }

  get modelUrl(){ return '/templates'; }

  get defaults(){
    return {
      type: 'template'
    };
  }

  loadTemplateInfo(){
    return new Promise((resolve) => {
      if (!this._sectionClasses || !this._buttonClasses){
        let callback = (module) => {
          _.extend(this, module);
          this.trigger('sectionsloaded');
          resolve(this);
        };
        switch(this.get('id')) {
          case 1:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 2:
            require(/* webpackPrefetch: true */ ['Templates/2/index'], callback);
            break;
          case 3:
            require(/* webpackPrefetch: true */ ['Templates/3/index'], callback);
            break;
          case 4:
            require(/* webpackPrefetch: true */ ['Templates/4/index'], callback);
            break;
          case 5:
            require(/* webpackPrefetch: true */ ['Templates/5/index'], callback);
            break;
          case 6:
            require(/* webpackPrefetch: true */ ['Templates/6/index'], callback);
            break;
          case 7:
            require(/* webpackPrefetch: true */ ['Templates/7/index'], callback);
            break;
          case 8:
            require(/* webpackPrefetch: true */ ['Templates/8/index'], callback);
            break;
          case 9:
            require(/* webpackPrefetch: true */ ['Templates/5/index'], callback);
            break;
          case 10:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 11:
            require(/* webpackPrefetch: true */ ['Templates/3/index'], callback);
            break;
          case 12:
            require(/* webpackPrefetch: true */ ['Templates/7/index'], callback);
            break;
          case 13:
            require(/* webpackPrefetch: true */ ['Templates/2/index'], callback);
            break;
          case 14:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 15:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 16:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 17:
            require(/* webpackPrefetch: true */ ['Templates/3/index'], callback);
            break;
          case 18:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 19:
            require(/* webpackPrefetch: true */ ['Templates/1/index'], callback);
            break;
          case 20:
            require(/* webpackPrefetch: true */ ['Templates/3/index'], callback);
            break;
          case 21:
            require(/* webpackPrefetch: true */ ['Templates/2/index'], callback);
            break;
          case 22:
            require(/* webpackPrefetch: true */ ['Templates/4/index'], callback);
            break;
          case 23:
            require(/* webpackPrefetch: true */ ['Templates/6/index'], callback);
            break;
          case 24:
            require(/* webpackPrefetch: true */ ['Templates/7/index'], callback);
            break;
          case 25:
            require(/* webpackPrefetch: true */ ['Templates/8/index'], callback);
            break;
        }

      } else {
        resolve(this);
      }
    });
  }

}

class Templates extends Collection{
  elements() {
    let array = [];
    this.each(function(e) {
      array.push(e.element());
    });
    return array;
  }
}
Templates.prototype.model = Template;
Templates.prototype.sortField = 'id';
Templates.prototype.sortOrder = 'ASC';

export {Template};
export {Templates};
