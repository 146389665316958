import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Blog} from './Blog';
import {User} from './User';

class BlogSubscriber extends Model {
  get modelUrl() {
    return '/blog-subscribers';
  }

  get defaults() {
    return {
      type: 'blog_subscriber'
    };
  }

  static searchKeys = ['email'];
}
BlogSubscriber.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'user',
    relatedModel: User
  }
];

class BlogSubscribers extends Collection {}
BlogSubscribers.prototype.model = BlogSubscriber;

export {
  /**
   * The blog model
   */
  BlogSubscriber,
  /**
   * Collection of Blog models
   */
  BlogSubscribers
};
