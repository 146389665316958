import React from 'react';
import {File as FWFile} from 'FW/Models/File';
import {MediaLibrary} from 'FW/Models/MediaLibrary';

class AudioPlayer extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      audioID: props.audioID,
      elementID: props.elementID,
      playlistID: props.playlistID,
      autoPlay: props.autoPlay
    };
    this.makeAPlayer = this.makeAPlayer.bind(this);
    this.initWithSingleFile = this.initWithSingleFile.bind(this);
    this.initWithPlaylist = this.initWithPlaylist.bind(this);
    this.Ap = null;
  }

  static aPlayerFile(title, author, url, pic){
    return {
      title: title,
      author: author || '',
      url: url,
      pic: pic
    };
  }

  makeAPlayer(props){
    this._player = new this.Ap(props);
    this.forceUpdate();
  }

  initWithSingleFile(el, file){
    let props = {element: el, autoplay: this.state.autoPlay, music: {}};
    if (FW.store.get(this.state.elementID + '-featured-files')) {
      file.helper.loadThumbnail(FW.store.get(this.state.elementID + '-featured-files').models[0]);
      props.music = AudioPlayer.aPlayerFile(file.get('title'), file.get('author') || ' ', file.originalUrl(), file.helper.featuredUrl());
      this.makeAPlayer(props);
    } else if (file.helper.hasThumbnail()){
      file.helper.on('thumbnailLoaded', () => {
        props.music = AudioPlayer.aPlayerFile(file.get('title'), file.get('author') || ' ', file.originalUrl(), file.helper.featuredUrl());
        props.autoplay = this.state.autoPlay;
        this.makeAPlayer(props);
      });
      file.helper.loadThumbnail();
    } else {
      props.music = AudioPlayer.aPlayerFile(file.get('title'), file.get('author') || ' ', file.originalUrl(), '');
      props.autoplay = this.state.autoPlay;
      this.makeAPlayer(props);
    }
  }

  initWithPlaylist(el, playlist){
    let files = playlist.get('files');
    files = files.map((f, i) => {
      if (FW.store.get(this.state.elementID + '-featured-files'))
        f.helper.loadThumbnail(FW.store.get(this.state.elementID + '-featured-files').models[i]);
      return AudioPlayer.aPlayerFile(f.get('title'), f.get('author') || ' ', f.originalUrl(), f.helper.featuredUrl());
    });
    this.makeAPlayer({
      element: el,
      autoplay: this.state.autoPlay,
      music: files
    });
  }

  componentDidMount(){
    require(/* webpackPrefetch: true */ ['aplayer', 'aplayer/dist/APlayer.min.css'], (Ap) => {
      this.Ap = Ap;
      let file, playlist;
      let el = document.getElementById(this.state.elementID);
      let me = this;
      if (this.state.audioID) {
        file = FW.store.get(this.state.elementID + '-files') || FWFile.findOrCreate({id: this.state.audioID});
        if (file.get('created_at')){
          this.initWithSingleFile(el, file);
        } else {
          file.fetch({
            success: () => {
              me.initWithSingleFile(el, file);
            }
          });
        }
      } else if (this.state.playlistID){
        playlist = FW.store.get(this.state.elementID + '-files') || MediaLibrary.findOrCreate({id: this.state.playlistID});
        if (playlist.get('created_at')) {
          this.initWithPlaylist(el, playlist);
        } else {
          playlist.fetch({
            success: () => {
              me.initWithPlaylist(el, playlist);
            }
          });
        }
      }
    });
  }

  render(){
    return (
      <div>
        <div id={this.state.elementID} className="audio-container aplayer"/>
      </div>
    );
  }

}

export default AudioPlayer;
