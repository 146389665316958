import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import {Files} from './File';

class MediaSeries extends Model {
  get modelUrl() {
    return '/media-series';
  }

  get defaults() {
    return {
      type: 'media_series'
    };
  }

  static searchKeys = ['description', 'title'];
}
MediaSeries.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'cover',
    relatedModel: 'FW.Models.File'
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'files',
    relatedModel: 'FW.Models.File',
    collectionType: 'FW.Models.Files',
    collectionOptions: Model.defaultCollectionOptions
  }
];

class MediaSeriesCollection extends Collection {}

MediaSeriesCollection.prototype.model = MediaSeries;

export {
  /**
   * The blog model
   */
  MediaSeries,
  /**
   * Collection of Blog models
   */
  MediaSeriesCollection
};
