import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {
  MembershipDirectoryMember,
  MembershipDirectoryMembers
} from './MembershipDirectoryMember';
import {File as FWFile} from './File';
import {MembershipCategories, MembershipCategory} from './MembershipCategory';

class MembershipDirectoryFamily extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/membership-directory-families';
  }

  /**
   */
  get defaults() {
    return {
      type: 'membership_directory_family'
    };
  }

  static searchKeys = ['last_name'];
}

class MembershipDirectoryFamilies extends Collection {}
MembershipDirectoryFamilies.prototype.model = MembershipDirectoryFamily;
MembershipDirectoryFamilies.prototype.sortField = 'last_name';
MembershipDirectoryFamilies.prototype.sortOrder = 'ASC';

MembershipDirectoryFamily.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'members',
    relatedModel: MembershipDirectoryMember,
    collectionType: MembershipDirectoryMembers,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'family'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'featured_image',
    relatedModel: FWFile
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'membership_categories',
    relatedModel: MembershipCategory,
    collectionType: MembershipCategories,
    collectionOptions: Model.defaultCollectionOptions
  }
];

export {MembershipDirectoryFamily, MembershipDirectoryFamilies};
