import React from 'react';
import {Link} from 'react-router-dom';

class TableButton extends React.Component {
  render() {
    let className = 'fa fa-' + this.props.icon;
    let typeClass = this.props.type
      ? 'btn btn-' + this.props.type
      : 'btn btn-default';

    return this.props.onClick ? (
      <span className={typeClass} onClick={this.props.onClick}>
        <span className={className}></span>
      </span>
    ) : (
      <Link className={typeClass} to={this.props.to}>
        <span className={className}></span>
      </Link>
    );
  }
}

export default TableButton;
