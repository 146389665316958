import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';

class Tag extends Model{

  get modelUrl(){
    return '/tags';
  }

  get defaults() {
    return {
      type: 'tag'
    };
  }

}
Tag.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  }
];

class Tags extends Collection{

  getExtraModelArgs(){
    return {site_id: FW.store.get('site').get('id')};
  }

}
Tags.prototype.model = Tag;

export {
  /**
   * The blog model
   */
  Tag,

  /**
   * Collection of Blog models
   */
  Tags
};
