import 'proxy-polyfill';
import {FWCore} from './FW/Core';
import {Backbone} from './FWBackbone';
import _ from 'underscore';
import {rivets} from './FWRivets';
import {Store} from './FW/Store';
import Cookies from 'js-cookie';
import {Helper} from './Helpers';
import {$u} from './Utils';
import '../scss/build.scss';
import {Models} from './FW/Models/index';
//this is a fix for IE 11
import URLSearchParamsIE from '@ungap/url-search-params';
global.webp = Helper.supportsWebP();


if (typeof global.URLSearchParams === 'undefined') {
  //this is a fix for IE 11
  global.URLSearchParams = URLSearchParamsIE;
}

let FW = new FWCore({
  debug: true
});
FW.on('booted', () => {
  import(/* webpackPreload: true */ 'sweetalert2/dist/sweetalert2.css');
  import('sweetalert2').then(swal => {
    global.swal = swal.default;
  });
});
FW.Models = Models;
FW.store = new Store();
FW.bootErrorReporting();
FW.bootPage();
_.each(FW.Models, function(model) {
  if (typeof model.prototype.defaults !== 'undefined') {
    Backbone.Relational.modelFactory.registerModel(model);
  }
});
FW.bootResellerInfo();
global.$ = $;
global.$u = $u;
global.jQuery = $;
global.FW = FW;
global.rivets = rivets;
global.Cookies = Cookies;
global.Helper = Helper;
import('bootstrap');
