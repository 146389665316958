import React from "react";
import './fullScreenSearch.scss';


class FullScreenSearch extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      query: ''
    };
  }

  inputChanged = (e) => {
    const val = e.target.value;
    this.setState({
      query: val
    });
  };

  cancel = () => {
    $('#fullScreenSearch').remove();
  };

  search = () => {
    if(this.state.query){
      const link = `http://google.com/search?q=${this.state.query} site:${window.location.hostname}`;
      if(this.props.newWindow){
        window.open(link);
        this.cancel();
      } else {
        window.location = link;
      }
    }
  };

  render(){
    const status = FW.store.get('customer') ? FW.store.get('customer').get('status') : 'customer';
    return <div id={'searchWrapper'} className={'animated fadeIn faster'}>
      <div id={'searchBox'}>
        <h1>Search {window.location.hostname}</h1>
        {status !== 'customer' ? <p>The search function will not work properly until your site is live</p> : null}
        <input id={'textField'} value={this.state.query} onChange={this.inputChanged}/>
        <button onClick={this.search} id={'submitButton'}><i className="fa fa-search"/></button>
        <br/>
        <a className={'btn'} id={'cancelButton'} onClick={this.cancel}>Cancel</a>
      </div>
    </div>
  }
}

export default FullScreenSearch;