import {Model} from './Model';
import {Collection} from './Collection';

class DnsRecord extends Model {
  get modelUrl() {
    return '/dns-records';
  }

  get defaults() {
    return {
      type: 'dns_record'
    };
  }

  getName() {
    return this.get('name') === this.get('domain_name').get('url')
      ? '@'
      : this.get('name').replace('.' + this.get('domain_name').get('url'), '');
  }
}

class DnsRecords extends Collection {}
DnsRecords.prototype.model = DnsRecord;
DnsRecords.prototype.sortField = 'record_type';
DnsRecords.prototype.sortOrder = 'ASC';

export {
  /**
   * The blog post model
   */
  DnsRecord,
  /**
   * Collection of Blog Post models
   */
  DnsRecords
};
