import {Model} from './Model';
import {Collection} from './Collection';

class MembershipCategory extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/membership-categories'; }

  /**
   */
  get defaults(){
    return {
      type: 'membership_category'
    };
  }

}

class MembershipCategories extends Collection{

  getExtraModelArgs() {
    return super.getExtraModelArgs();
  }

}
MembershipCategories.prototype.model = MembershipCategory;

MembershipCategory.prototype.relations = [];

export {MembershipCategory, MembershipCategories};
