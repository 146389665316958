import {Model} from './Model';
import {Collection} from './Collection';

class SiteOrder extends Model {
  get modelUrl() {
    return '/site-orders';
  }

  get defaults() {
    return {
      type: 'site_order'
    };
  }
}

SiteOrder.prototype.relations = [];

class SiteOrders extends Collection {}
SiteOrders.prototype.model = SiteOrder;

export {
  /**
   * The site logo model
   */
    SiteOrder,
  /**
   * Collection of site logo models
   */
    SiteOrders
};
