import {Model} from './Model';
import {Collection} from './Collection';

class Questionnaire extends Model {
  get modelUrl() {
    return '/questionnaires';
  }

  get defaults() {
    return {
      type: 'questionnaire'
    };
  }
}

Questionnaire.prototype.relations = [];

class Questionnaires extends Collection {}

Questionnaires.prototype.model = Questionnaire;
Questionnaires.prototype.sortField = 'q_title';
Questionnaires.prototype.sortOrder = 'ASC';

export {
  /**
   * The Questionnaire model
   */
  Questionnaire,
  /**
   * Collection of Questionnaire models
   */
  Questionnaires
};
