import React from 'react';
import {loadReCaptcha} from 'react-recaptcha-v3';
import config from 'config';

class TrialSection extends React.Component{

  componentDidMount(){
    loadReCaptcha(config.RECAPTCHA3_PUBLIC_KEY);
    if (window.location.hash === '#autostart') {
      FW.startTrialSignup();
    }
  }

  render(){

    const buttonSize = typeof this.props.section.prop('button_size') === 'undefined' ? 'btn-lg' : this.props.section.prop('button_size');

    return <div>
      <div style={{
        textAlign: 'center'
      }}>
        {!this.props.section.prop('hide_description') ? <div>
          <h2 style={{textAlign: 'center', fontWeight: 200}}>Experience {config.PRODUCT_NAME}!</h2>
          <p className="lead">Try out {config.PRODUCT_NAME} for free!</p>
          {!FW.store.get('customer').get('reseller_white_label') ? <div style={{width: 445, height: 250, maxWidth: '100%', margin: 'auto'}}>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/vNlbUCiYPfk?rel=0&amp;showinfo=0" frameBorder={0} allowFullScreen={true}></iframe>
          </div> : null}
        </div> : null}
        <span className={`btn btn-primary ${buttonSize}`} style={{marginTop: 30}} onClick={() => {
          FW.startTrialSignup(this.props.section.prop('blueprint_group_id'), this.props.section.prop('wels'));
        }}>{this.props.section.prop('button_text') || 'Start Trial'}</span>
      </div>
    </div>;
  }

}

export default TrialSection;
