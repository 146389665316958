import React from 'react';
import GroupMaster from './Groups/GroupMaster';

class PageView extends React.Component {
  componentDidMount() {
    this.props.page.get('groups').on(
      'add remove',
      () => {
        this.forceUpdate();
      },
      this
    );
  }

  render() {
    let groups = this.props.page.get('groups').map(group => {
      return <GroupMaster key={group.get('id')} group={group} />;
    });

    return <div>
      {groups}
    </div>;
  }
}

export default PageView;
