import {Model} from './Model';
import {Backbone} from 'FWBackbone';
import {Site, Sites} from './Site';
import config from 'config';
import prop from './Decorators/Prop';
import {Helper} from '../../Helpers';

@prop('user_registration_properties', 'userProp')
class Customer extends Model {
  get modelUrl(): string {
    return '/customers';
  }

  get packageType(): string {
    return this.get('sk_package') ?? 'best';
  }

  isBest() {
    return this.packageType === 'best';
  }

  get defaults() {
    return {
      type: 'customer'
    };
  }

  getAppStatus() {
    return $.get(config.API_ROOT + '/app/status');
  }

  getFW1Info(getLoginKey) {
    return $.get(config.API_ROOT + '/fw1/info', {
      'loginKey': typeof getLoginKey !== 'undefined' ? getLoginKey : true
    });
  }

  getStorageAllottment(){
    if (this.get('space_override')) {
      return Helper.formatFileSize(this.get('space_override'), true);
    } else  if (this.get('sk_package') === 'better') {
      return '10GB';
    } else if (this.get('sk_package') === 'best') {
      return '1000GB';
    }
  }

}
Customer.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'sites',
    relatedModel: Site,
    collectionType: Sites,
    reverseRelation: {
      key: 'customer',
      type: Backbone.Relational.HasOne,
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'reseller_logo',
    relatedModel: 'FW.Models.File'
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'reseller',
    relatedModel: 'FW.Models.Customer'
  }
];

export default Customer;
