import React from 'react';
import InlineEditableElement from 'Components/InlineEditableElement';
import {File as FWFile} from 'FW/Models/File';
import 'jquery.appear';

class PlainText extends React.Component {
  constructor(props) {
    super(...arguments);
    this.valueChanged = this.valueChanged.bind(this);
    this.updateImage = this.updateImage.bind(this);
    this.fontFamilyChanged = this.fontFamilyChanged.bind(this);
    let file =
      this.props.section.bp('background_file_id') &&
      this.props.section.bp('background_type') === 'image'
        ? FWFile.findOrCreate({id: this.props.section.bp('background_file_id')})
        : null;
    if (
      this.props.section.bp('background_file_id') &&
      file &&
      this.props.section.bp('background_type') === 'image'
    ) {
      file.fetch().then(() => {
        this.forceUpdate();
      });
    }
    this.state = {
      backgroundFile: file
    };
  }

  componentDidMount() {
    const section = this.props.section;
    const scrollAnimation = section.getAnimationClass('scroll');
    if (scrollAnimation) {
      const toBeAnimated = $(`#plainText-${section.get('id')}`);
      toBeAnimated.appear(() => {
        toBeAnimated.addClass(scrollAnimation);
        toBeAnimated.one(
          'webkitAnimationEnd oanimationend msAnimationEnd animationend',
          () => {
            toBeAnimated.removeClass(scrollAnimation);
          }
        );
      });
    }
  }

  mouseOver = () => {
    let toBeAnimated = $(
      '#plainText-' + this.props.section.get('id')
    ).find('.actionAnimated');
    toBeAnimated.addClass(this.props.section.getAnimationClass('hover'));
  };

  mouseLeave = () => {
    let toBeAnimated = $(
      '#plainText-' + this.props.section.get('id')
    ).find('.actionAnimated');
    toBeAnimated.removeClass(this.props.section.getAnimationClass('hover'));
  };

  updateImage() {
    let file =
      this.props.section.bp('background_file_id') &&
      this.props.section.bp('background_type') === 'image'
        ? FWFile.findOrCreate({id: this.props.section.bp('background_file_id')})
        : null;
    if (
      this.props.section.bp('background_file_id') &&
      file &&
      this.props.section.bp('background_type') === 'image'
    ) {
      file.fetch().then(() => {
        this.forceUpdate();
      });
    }
    this.setState({
      backgroundFile: file
    });
  }

  valueChanged(value) {
    this.props.section.prop('content', value);
  }

  fontFamilyChanged(fonts) {
    this.props.section.prop('fonts', fonts);
    if (FW.store.get('page')) {
      FW.store.get('page').getFonts();
    }
  }

  render() {
    const section = this.props.section;
    const bkgFile = this.state.backgroundFile;
    if (section.bp('background_type') === 'image') {
      if (
        (bkgFile && section.bp('background_file_id') !== bkgFile.get('id')) ||
        (!bkgFile && section.bp('background_file_id'))
      ) {
        this.updateImage();
      }
    }
    let padding = section.ls('padding') + 'px' || '0px';
    let backgroundCSS = section.getBackgroundCss();
    let shadowCSS = section.ls('text-shadow')
      ? section.ls('text-shadow').shadow_css
      : '';
    const fileUrl =
      bkgFile && bkgFile.helper && section.bp('background_type') === 'image'
        ? bkgFile.helper.thumbnailUrl('optimized')
        : null;
    return (
      <div
        id={`plainText-${section.get('id')}`}
        style={{padding: padding, textShadow: shadowCSS, color: section.ls('text_color')}}
        className={section.getAnimationClass('load') + ' ' + section.prop('extra_classes')}
        onMouseOver={this.mouseOver}
        onMouseLeave={this.mouseLeave}
      >
        {fileUrl ? (
          <div
            className={section.bp('parallax') ? 'fw-parallax' : 'fw-noParallax'}
            style={{
              backgroundSize: section.bp('background_size'),
              backgroundRepeat: 'no-repeat',
              backgroundImage: 'url("' + fileUrl + '")',
              zIndex: -1000
            }}
          />
        ) : null}
        <div
          style={{
            background: backgroundCSS,
            opacity: section.getBackgroundOpacity(),
            zIndex: -100,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
        <div className="actionAnimated">
          <InlineEditableElement
            className={section.prop('inline_classes')}
            html={section.prop('content')}
            size={this.props.size}
            onChange={this.valueChanged}
            fontFamilyChanged={this.fontFamilyChanged}
            inlineEditingEnabled={this.props.inlineEditingEnabled}
          />
        </div>
      </div>
    );
  }
}

export default PlainText;
