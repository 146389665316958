import React from 'react';
import './TableButtons.scss';

class TableButtons extends React.Component{

  render(){
    return <div className="btn-group fw-table-buttons">
      {this.props.children}
    </div>;
  }

}

export default TableButtons;
