import React, {useEffect, useState} from 'react';

export default React.memo(function ContinueToGivePopup({value, stickyButton}) {
  const [id, setId] = useState('custom_c2g_' + Helper.randomString(40));
  // include the javascript
  useEffect((moreProps) => {
    $('.c2g-sticky-button-span').parent().remove();
    $('#continue-badge').remove();
    let scriptTag = document.createElement('script');
    scriptTag.src = 'https://iframe.continuetogive.com/badges/badge_script.php';
    document.head.appendChild(scriptTag);
    scriptTag.onload = () => {
      newC2gBadge().popup(value.account_id, {
        button_selector: '.' + id,
        task: 'show_donation_badge_popup_window',
        stickyButton: stickyButton
      });
    };
  }, [value.display_type, stickyButton && stickyButton.position ? stickyButton.position : false, stickyButton && stickyButton.alignment ? stickyButton.alignment : false]);
  const isSticky = typeof stickyButton !== 'undefined';
  return <>
    <div id={id} className={!isSticky ? 'btn btn-primary ' + id : null}>{!isSticky ? 'Donate Now' : null}</div>
    {!isSticky ? <style>
      {`
        #${id}{
          background: ${value.primary_color};
          border-color: ${value.primary_color};
          font-family: ${typeof value.font_family !== 'undefined' ? value.font_family : ''};
          font-size: ${typeof value.font_size !== 'undefined' ? value.font_size : '100'}%;
        }
        
        #${id}:hover{
          background: ${value.secondary_color};
          border-color: ${value.secondary_color};
        }
      `}
    </style> : null}
  </>;
  }, (newProps, oldProps) => {
  return newProps.stickyButton === oldProps.stickyButton && newProps.value.display_type === newProps.display_type;
});
