import {Model} from './Model';
import {Collection} from './Collection';

class MembershipStatus extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/membership-statuses'; }

  /**
   */
  get defaults(){
    return {
      type: 'membership_status'
    };
  }

}

class MembershipStatuses extends Collection{}
MembershipStatuses.prototype.model = MembershipStatus;

MembershipStatus.prototype.relations = [];

export {MembershipStatus, MembershipStatuses};
