import {Model} from './Model';
import {Collection} from './Collection';

class SiteBlueprintGroup extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/site-blueprint-groups';
  }

  /**
   */
  get defaults() {
    return {
      type: 'site_blueprint_group'
    };
  }
}

class SiteBlueprintGroups extends Collection {}
SiteBlueprintGroups.prototype.model = SiteBlueprintGroup;
SiteBlueprintGroups.prototype.sortField = 'created_at';
SiteBlueprintGroups.prototype.sortOrder = 'DESC';

SiteBlueprintGroups.prototype.relations = [];

export {SiteBlueprintGroup, SiteBlueprintGroups};
