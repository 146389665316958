import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {Site} from './Site';
import {
  MembershipDirectoryFamilies,
  MembershipDirectoryFamily
} from './MembershipDirectoryFamily';
import {
  MembershipDirectoryMember,
  MembershipDirectoryMembers
} from './MembershipDirectoryMember';
import {MembershipStatus, MembershipStatuses} from './MembershipStatus';
import {MembershipCategories, MembershipCategory} from './MembershipCategory';
import prop from './Decorators/Prop';

@prop
class MembershipDirectory extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/membership-directories';
  }

  /**
   */
  get defaults() {
    return {
      type: 'membership_directory'
    };
  }

  static searchKeys = [
    'title',
    'description'
  ];
}

class MembershipDirectories extends Collection {}
MembershipDirectories.prototype.model = MembershipDirectory;

MembershipDirectory.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'families',
    relatedModel: MembershipDirectoryFamily,
    collectionType: MembershipDirectoryFamilies,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'membership_directory'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'members',
    relatedModel: MembershipDirectoryMember,
    collectionType: MembershipDirectoryMembers,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'membership_statuses',
    relatedModel: MembershipStatus,
    collectionType: MembershipStatuses,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'membership_directory'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'membership_categories',
    relatedModel: MembershipCategory,
    collectionType: MembershipCategories,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'membership_directory'
    }
  }
];

export {MembershipDirectory, MembershipDirectories};
