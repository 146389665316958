import {Model} from './Model';
import {Collection} from './Collection';

class PageGroupProperty extends Model {
  get modelUrl() {
    return '/page-group-properties';
  }

  get defaults() {
    return {
      type: 'page_group_property'
    };
  }

  get(key) {
    if (key === 'value') {
      let val = super.get(key);
      try {
        val = JSON.parse(val);
      } catch (e) {}
      return val;
    }
    return super.get(...arguments);
  }

  set(key, val) {
    if (key === 'value' && typeof val === 'object') {
      return super.set(key, JSON.stringify(val));
    }
    return super.set(...arguments);
  }
}

class PageGroupPropertiesCollection extends Collection {}
PageGroupPropertiesCollection.prototype.model = PageGroupProperty;

export {PageGroupProperty, PageGroupPropertiesCollection};
