/* global CKEDITOR */
import rivets from 'rivets';
import {Backbone} from './FWBackbone';
import _ from 'underscore';
import {File as FWFile} from 'FW/Models/File';
import {Helper} from './Helpers';
//import moment from 'moment';

let Model = Backbone.Model,
  Collection = Backbone.Relational.Collection;

/**
 * @param {Model}  model
 * @param {String} keypath
 * @param {*}      [value]
 *
 * @returns {*}
 */
function getterSetter(obj, keypath, value) {
  if (!(obj instanceof Model) && !(obj instanceof Collection)) {
    return;
  }

  if (arguments.length === 3) {
    if (keypath === '*') {
      // setting all attributes
      // value should be an Object
      obj.set(value);
      return;
    }
    // setting the only attribute
    obj.set(keypath, value);
    return;
  }

  if (keypath === '*') {
    // all attributes
    value = obj.attributes;
  } else {
    // one attribute
    value = obj.get(keypath);
  }

  // rivets cant iterate over Backbone.Collection -> return Array
  if (value instanceof Collection) {
    return value.models;
  }

  return value;
}

/**
 * @param {String} action on or off
 * @returns {Function}
 */
function onOffFactory(action) {
  /**
   * @param {Model}    model
   * @param {String}   keypath
   * @param {Function} callback
   */
  return function(model, keypath, callback) {
    if (!(model instanceof Model)) {
      return;
    }

    let value = model.get(keypath);

    let eventName = 'change' + (keypath === '*' ? '' : ':' + keypath);
    model[action](eventName, callback);

    if (value instanceof Collection) {
      value[action]('add remove reset sort', callback);
    }
  };
}

// Configure rivets data-bind for Backbone.js
rivets.adapters[':'] = {
  observe: onOffFactory('on'),
  unobserve: onOffFactory('off'),
  get: getterSetter,
  set: getterSetter
};

rivets.configure({
  // Attribute prefix in templates
  prefix: 'fw',

  // Preload templates with initial data on bind
  preloadData: true,

  // Root sightglass interface for keypaths
  rootInterface: '.',

  // Template delimiters for text bindings
  templateDelimiters: ['{', '}'],

  // Augment the event handler of the on-* binder
  handler: function(target, event, binding) {
    this.call(target, event, binding.view.models);
  }
});

rivets.binders.cols = function(el, value) {
  if (typeof value !== 'undefined') {
    let classList = $(el)
        .attr('class')
        .split(/\s+/),
      i;
    for (i in classList) {
      if (Helper.strContains(classList[i], 'col-md-')) {
        $(el).removeClass(classList[i]);
      }
    }
    $(el).addClass('col-md-' + value);
  }
};

rivets.binders.border = function(el, value) {
  if (typeof value !== 'undefined') {
    el.style.setProperty('border', value);
  }
};

rivets.binders.border_radius = function(el, value) {
  if (typeof value !== 'undefined') {
    el.style.setProperty('border-radius', value);
  }
};

rivets.binders.width = function(el, value) {
  if (typeof value !== 'undefined') {
    el.style.setProperty('width', value);
  }
};

rivets.binders.height = function(el, value) {
  if (typeof value !== 'undefined') {
    el.style.setProperty('height', value);
  }
};

rivets.binders.label = function(el, value) {
  if (typeof value !== 'undefined') {
    try {
      $(el).attr('aria-label', $('<div>' + value + '</div>').text());
    } catch (e) {
      console.log('MENU ITEM LABEL ERROR ', e);
    }
  }
};

rivets.binders.test = function(el, value) {
  console.log('fw-test Value: ', value);
};

rivets.binders.content = {
  bind: function(el) {
    let self = this;

    this.callback = function() {
      console.log('typing now');
      clearTimeout(CKEDITOR.typingTimer);
      function finishedTyping() {
        console.log('finished typing');
        let value = $(self.el).html();

        //ckeditor doesn't restore the cursor when we change the dom, so we need to do this
        let editor;
        _.each(CKEDITOR.instances, instance => {
          if ($(instance.element.$)[0] === self.el) {
            editor = instance;
          }
        });

        let bookmarks;
        if (editor) {
          let selection = editor.getSelection();
          bookmarks = selection.createBookmarks2(true);
        }

        self.observer.setValue(value);

        //put the cursor back where it belongs
        if (editor) {
          editor.getSelection().selectBookmarks(bookmarks);
        }
      }

      CKEDITOR.typingTimer = setTimeout(finishedTyping, 500);
    };

    return $(el).on('keyup blur', this.callback);
  },

  unbind: function(el) {
    $(el).off('keyup blur', this.callback);
  },

  routine: function(el, value) {
    $(el).html(value);
  }
};

rivets.binders.parallax = function(el, value) {
  if (!value) value = 'fw-parallax';
  if (el.addedClass) {
    $(el).removeClass(el.addedClass);
    delete el.addedClass;
  }

  if (value) {
    $(el).addClass(value);
    el.addedClass = value;
  }
};

rivets.binders.background_size = function(el, value) {
  if (!value) value = 'cover';
  el.style.setProperty('background-size', value);
  el.style.setProperty('background-repeat', 'no-repeat');
};

rivets.binders.addclass = function(el, value) {
  if (el.addedClass) {
    $(el).removeClass(el.addedClass);
    delete el.addedClass;
  }

  if (value) {
    $(el).addClass(value);
    el.addedClass = value;
  }
};

rivets.binders['style-*'] = function(el, value) {
  if (this.args[0] === 'min-height') {
    value = value + 'px';
  } else if (this.args[0] === 'padding-top') {
    value = value + 'px';
  } else if (this.args[0] === 'margin-top') {
    value = value + 'px';
  }
  if (this.args[0] === 'background') {
    this.args[0] = 'background-image';
    this.args[0] = 'background-image';
    value = 'url(' + value + ')';
  }

  el.style.setProperty(this.args[0], value);
};

rivets.binders.group_padding = function(el, group) {
  let padding = group.get('layout_settings')
    ? group.get('layout_settings').get('settings')
      ? typeof group.get('layout_settings').get('settings').padding_top !==
        'undefined'
        ? group.get('layout_settings').get('settings').padding_top
        : -1
      : -1
    : -1;
  let isFirstGroup = false;
  if (
    Page &&
    Page.get('groups') &&
    Page.get('groups').models
  ) {
    isFirstGroup =
      group.get('id') ===
      Page
        .get('groups')
        .models[0].get('id');
  }
  if (isFirstGroup && padding === -1) {
    padding = 0;
    el.style.setProperty('padding-top', padding + 'px');
  } else {
    el.style.setProperty('padding-top', (padding || 0) + 'px');
  }
};

rivets.binders.muted = function(el, value) {
  if (value === 'false' || value === false) {
    el.muted = false;
  } else {
    el.muted = true;
  }
};

rivets.binders.videosource = function(el, value) {
  el.src = value;
  let video = $(el).parent()[0];

  //only play the videos if we're not editing.
  if (!FW.inEditor()) {
    video.load();
    video.play();
  }
};

rivets.binders.background = function(el, value) {
  if (
    value === null ||
    value === 'null' ||
    value === 'undefined' ||
    typeof value === 'undefined'
  ) {
    el.style.setProperty('background-image', '');
    $(el)
      .parent()
      .css({overflow: ''});
  } else {
    $(el)
      .parent()
      .css({overflow: 'hidden'});
    el.style.setProperty('background-image', 'url(' + value + ')');
  }
};

rivets.binders.imagebackground = function(el, value) {
  let file = FWFile.findOrCreate({id: value});
  // if the file is loaded, don't load it again
  if (!file.get('created_at')) {
    file.fetch().then(function() {
      el.style.setProperty('background-image', 'url(' + file.originalUrl() + ')');
    });
  } else {
    el.style.setProperty('background-image', 'url(' + file.originalUrl() + ')');
  }
};

rivets.binders['background-color'] = function(el, value) {
  el.style.setProperty('background-color', value);
};

rivets.binders.css_background = function(el, value) {
  if (!value) return;
  let opacity = value.background_opacity || 1;
  value = value.background_css;
  if (value && value.includes('#')) {
    let rgb = Helper.hexToRgb(value);
    if (rgb) {
      value =
        'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + opacity + ')';
    }
  }
  el.style.setProperty('background', value);
};

rivets.binders.raw_background = function(el, value) {
  if (typeof value === 'string' && value.indexOf('rgba') === 0) {
    value = Helper.rgbaToHex(value);
  }
  el.style.setProperty('background', value);
};

rivets.binders['menu-item-options'] = function(el, value) {
  if (value.get('children').length > 0) {
    $(el).addClass('dropdown-toggle');
    $(el).attr('data-toggle', 'dropdown');
  } else {
    $(el).attr('data-toggle', '');
    $(el).removeClass('dropdown-toggle');
  }
};

rivets.binders['group-min-height'] = function(el, value) {
  if (!value) value = 0;
  value = value + 'px';
  el.style.setProperty('min-height', value);
  $(el).attr('group-min-height', value);
};

const OVERLAP_MIN_WIDTH = 650;

rivets.binders['group-top-margin'] = function(el, value) {
  if (!value) value = 0;
  let negative = -parseInt(value);
  value = value + 'px';
  const width = window.innerWidth;
  if (width >= OVERLAP_MIN_WIDTH) {
    el.style.setProperty('margin-top', value);
    let oldHeight = $(el).attr('group-min-height')
      ? parseInt($(el).attr('group-min-height'))
      : 0;
    let newHeight = Math.max(negative + 10, oldHeight);
    el.style.setProperty('min-height', newHeight + 'px');
  } else {
    el.style.setProperty('margin-top', 0);
  }
};

rivets.binders['group-side-margins'] = function(el, value) {
  if (!value) value = 0;
  let complement = 100 - parseInt(value) * 2;
  value = value + '%';
  let width = complement + '%';
  const windowWidth = window.innerWidth;
  if (windowWidth >= OVERLAP_MIN_WIDTH) {
    el.style.setProperty('width', width);
    el.style.setProperty('margin-left', value);
  } else {
    el.style.setProperty('width', '100%');
    el.style.setProperty('margin-left', 0);
  }
};

rivets.binders['group-padding-top'] = function(el, value) {
  if (!value) value = 0;
  value = value + 'px';
  const windowWidth = window.innerWidth;
  if (windowWidth >= OVERLAP_MIN_WIDTH) {
    el.style.setProperty('padding-top', value);
  } else {
    el.style.setProperty('padding-top', Math.min(parseInt(value), 20) + 'px');
  }
};

rivets.binders['on-enter-key-press'] = {
  bind: bindKeypress,
  unbind: unbindKeypress,
  function: true
};

function bindKeypress(el) {
  var rivetsView = this,
    $el = $(el);

  $el.on('keypress', function(event) {
    if (event.keyCode === 13) {
      $el.blur();
      rivetsView.observer.value()(event);
    }
  });
}

function unbindKeypress(el) {
  $(el).off('keypress');
}

rivets.formatters.exists = function(value) {
  return typeof value !== 'undefined';
};

rivets.formatters.echo = function(value) {
  console.log('ECHOING: ', value);
};

rivets.binders.ignore = {
  block: true,
  routine: function() {
    /* do nothing */
  }
};

rivets.formatters.equals = function(value, equals) {
  return value === equals;
};

rivets.formatters.divisibleBy = function(value, by) {
  return value % by === 0;
};

rivets.formatters.s3file = function(value) {
  return FW.store.get('site').s3Url(value);
};

rivets.formatters.count = function(value, equals) {
  return value.length === equals;
};

rivets.formatters.lgt = function(value, equals) {
  return value.length > equals;
};

rivets.formatters.llt = function(value, equals) {
  return value.length < equals;
};

/*rivets.formatters.date = function(value) {
  return moment(value).format('LL');
};*/

rivets.formatters.file = function(value, equals, args) {
  if (value && value !== 'null' && value !== null) {
    let file;
    if (typeof value === 'string') {
      file = FWFile.findOrCreate({id: value});
    } else {
      file = value;
    }
    let helper = file.helper;
    let result;
    if (args) {
      result = helper[equals](args);
    } else {
      result = helper[equals];
    }
    return result;
  }
  return '';
};

rivets.formatters.fwFile = function(value, equals, args) {
  if (value !== 'null' && value !== null) {
    let file = FWFile.findOrCreate({id: value});
    return file.helper.thumbnailUrl(2880);
  }
  return '';
};

rivets.adapters['?'] = {
  observe: function(obj, keypath, callback) {
    let id = obj[keypath];
    if (id) {
      let file = FWFile.findOrCreate({id: id});
      file.on('change', callback, this);
    }
  },
  unobserve: function(obj, keypath, callback) {
    let id = obj[keypath];
    if (id) {
      let file = FWFile.findOrCreate({id: id});
      file.off('change', callback, this);
    }
  },
  get: function(obj, keypath) {
    let id = obj[keypath];
    if (id) {
      let file = FWFile.findOrCreate({id: id});
      return file;
    }
    return '';
  },
  set: function(obj, keypath, value) {
    obj.set(keypath, value);
  }
};

/*rivets.formatters.call = function() {
  let __slice = [].slice;
  let args, value;
  value = arguments[0], args = 2 <= arguments.length ? __slice.call(arguments, 1) : [];
  let ret = value ? value.call.apply(value, [this].concat(__slice.call(args))) : '';
  return ret;
};*/

/*rivets.binders.test = function(el, value){
  console.log('TEST: ', el, value);
};*/

export {rivets};
