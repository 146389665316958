import {Model} from './Model';
import {Collection} from './Collection';
import _ from 'underscore';

class ContentDefinition extends Model{

  get modelUrl(){ return '/content-definitions'; }

  get defaults(){
    return {
      type: 'content_definition'
    };
  }

  getModel(){
    return FW.Models[this.get('model_name')];
  }

}

class ContentDefinitions extends Collection{
  availableDefinitions(){
    return this.filter((def) => {
      if (FW.isSkCust() && def.get('hidden_from_sk')) {
        return false;
      }
      if (!FW.isSkCust() && def.get('hidden_from_fw')) {
        return false;
      }

      return _.indexOf(def.get('template_ids'), '*') >= 0 || _.indexOf(def.get('template_ids'), FW.store.get('site').get('template_id')) >= 0;
    });
  }
}
ContentDefinitions.prototype.model = ContentDefinition;

export {ContentDefinition};
export {ContentDefinitions};
