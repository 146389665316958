import React from 'react';
import './LoadingWheel.scss';

class LoadingWheel extends React.Component {
  render() {
    let style = this.props.style ? this.props.style : {};
    const innerClassName = this.props.white ? 'wInnerBall white' : 'wInnerBall';

    return (
      <div className="windows8" style={style}>
        <div className="wBall" id="wBall_1">
          <div className={innerClassName}></div>
        </div>
        <div className="wBall" id="wBall_2">
          <div className={innerClassName}></div>
        </div>
        <div className="wBall" id="wBall_3">
          <div className={innerClassName}></div>
        </div>
        <div className="wBall" id="wBall_4">
          <div className={innerClassName}></div>
        </div>
        <div className="wBall" id="wBall_5">
          <div className={innerClassName}></div>
        </div>
      </div>
    );
  }
}

export default LoadingWheel;
