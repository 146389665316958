import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';
import _ from 'underscore';
import FullScreenSearch from '../../FrontEnd/Search/FullScreenSearch';
import React from 'react';
import ReactDOM from 'react-dom';
import {TpegUrl} from './TpegUrl';

class MenuItem extends Model {
  get modelUrl() {
    return '/menu-items';
  }

  get defaults() {
    return {
      type: 'menu_item'
    };
  }

  static getStaticItem(details, href, text) {
    let keyMap = {
      linkType: 'type',
      siteId: 'site_id',
      pageId: 'pageUuid'
    };

    let de = {};
    _.each(details, (d, k) => {
      k = keyMap[k] || k;
      if (d !== '') {
        de[k] = d;
      }
    });
    if (!de.type && !de.pageUuid && href) {
      de.href = href;
      de.type = 'external';
    }
    if (typeof text !== 'undefined') {
      de.title = text;
    }
    let d = _.extend(
      {
        title: '',
        type: 'page',
        href: ''
      },
      de
    );
    let item = MenuItem.create({
      menu_id: -1,
      details: d,
      children: [],
      parent_id: -1,
      order_id: -1
    });
    item.newItem = true;
    return item;
  }

  getAttributes() {
    let details = this.getDetails();
    return {
      href: this.getLink(),
      target: this.getTarget(),
      'data-href': details.href,
      'data-page-id': details.pageUuid,
      'data-site-id': details.site_id,
      'data-blog-id': details.blogId,
      'data-file-id': details.fileId,
      'data-link-type': details.type,
      'data-target': details.target,
      'data-title': details.title,
      'data-email': details.email
    };
  }

  getPublicSiteUrlIfNeeded(siteId) {
    let currentSite = FW.store.get('site');
    if (siteId !== currentSite.get('id')) {
      let site = FW.Models.Site.findOrCreate({
        id: siteId
      });
      return site.get('public_url');
    }
    return '';
  }

  getLink() {
    let details = this.getDetails();
    let type = details.type;
    let menu = this.get('menu');
    let currentSite = FW.store.get('site');
    let href;
    let siteId = null;
    switch (type) {
      case 'tpeg':
        const user = FW.store.get('user');
        if (user && user.mayOnly(['edit_tpeg_url', 'edit_user'])) {
          const urls = user.getPermittedModelIds('edit_tpeg_url');
          href = '/admin/tpeg-urls/' + urls[0];
        } else if (user.isEditor()) {
          href = '/admin/tpeg-urls';
        } else if (user) {
          href = '/edit-user';
        }
      case 'page':
        let page;
        let store = typeof FW.editor !== 'undefined' ? FW.editor.getIframeWindow().FW.store : FW.store;
        if (menu && menu.get('site_id') !== currentSite.get('id')) {
          page = store
            .get('site-' + menu.get('site_id') + '-pages')
            .findWhere({uuid: details.pageUuid});
        } else {
          page = store.get('pages').findWhere({uuid: details.pageUuid});
        }
        siteId = page ? page.get('site_id') : null;
        href = page ? page.getLink() : details.href ? details.href : '#';
        break;
      case 'blog':
        let blog;
        if (menu && menu.get('site_id') !== currentSite.get('id')) {
          blog = FW.store
            .get('site-' + menu.get('id') + '-blogs')
            .findWhere({id: details.blogId});
        } else {
          blog = FW.store.get('blogs').findWhere({id: details.blogId});
        }
        siteId = blog ? blog.get('site_id') : null;
        let blogName = blog ? blog.get('slug') : '#';
        href = '/blog/' + blogName;
        break;
      case 'login-page':
        href = FW.store.get('user') ? '/logout' : '/login';
        break;
      case 'site':
        let id = details.siteId || details.site_id;
        let site = FW.store.get('sites').findWhere({id: id});
        if (site) {
          let publicUrl = site.get('public_url');
          if (details.pageUuid) {
            let pages =
              FW.store.get('site-' + details.site_id + '-pages') ||
              FW.store.get('pages');
            let p = pages
              ? pages.findWhere({uuid: details.pageUuid})
              : '/page/' + details.pageUuid;
            if (typeof p !== 'undefined' && typeof p !== 'string' && p.get('site_id') === id) {
              href = FW.inEditor() ? '' : publicUrl.slice(0, -1);
              href = href + p.getLink();
            } else {
              href = '#';
            }
          }
          if (!href && !FW.inEditor()) {
            href = site.get('public_url');
          } else if (FW.inEditor()) {
            let lastString = href ? '?r=' + encodeURIComponent(href) : '';
            href = '/site/' + id + lastString;
          }
        } else {
          href = '/';
        }
        break;
      case 'search':
        href = null;
        break;
      case 'email':
        href = 'mailto:' + details.email;
        break;
      default:
        href = details.href;
        break;
    }
    if (typeof href === 'string') {
      let publicUrl = this.getPublicSiteUrlIfNeeded(siteId);
      href = publicUrl ? publicUrl.slice(0, -1) + href : href;
    }

    if (
      FW.inEditor() &&
      (type === 'page' || type === 'blog') &&
      this.getDetails().target === '_blank'
    ) {
      href = '//' + FW.getParent().window.location.hostname + href;
    }

    if (FW.store.get('site').prop('is_tpeg') && !FW.inEditor()) {
      href = TpegUrl.replaceString(href);
    }

    return href;
  }

  clicked = (e, i, keypress = false) => {
    const status = FW.store.get('customer')
      ? FW.store.get('customer').get('status')
      : 'customer';
    const details = this.getDetails();
    if (details.type === 'search') {
      const specialTypes = ['text', 'icon', 'text_icon'];
      if (specialTypes.includes(details.search_type)) {
        $('#fullScreenSearch').remove();
        $('body').append(
          '<div id="fullScreenSearch" style="z-index: 100000" />'
        );
        let $el = $('#fullScreenSearch');
        ReactDOM.render(
          <FullScreenSearch newWindow={this.getTarget() === '_blank'} />,
          $el[0]
        );
      } else {
        if (
          keypress === 'key' ||
          e.target.id === 'menuSearchButton' ||
          e.target.parentNode.id === 'menuSearchButton'
        ) {
          const term = $('#menuSearchValue').val();
          if (status !== 'customer') {
            swal.fire(
              'Trial Limitation',
              'The search functionality will not work properly until your site is live',
              'warning'
            );
            return;
          }
          if (term && term.length > 0) {
            const link = `http://google.com/search?q=${term} site:${window.location.hostname}`;
            if (this.getTarget() === '_blank') window.open(link);
            else window.location = link;
          }
        }
      }
      if (keypress !== 'key') e.preventDefault();
    }
  };

  enterPressed = (e, i) => {
    this.clicked(e, i, 'key');
  };

  getDetails() {
    return this.get('details') || {};
  }

  getText() {
    const details = this.getDetails();
    if (details.type === 'login-page') {
      return FW.store.get('user') ? 'Logout' : 'Login';
    }
    if (details.type === 'search') {
      const form =
        '<input type="text" id="menuSearchValue"><button id="menuSearchButton"><i class="fa fa-search"></i></button>';
      const searchType = details.search_type || 'text';
      switch (searchType) {
        case 'text':
          break;
        case 'text_icon':
          return this.getDetails().title + ' <i class="fa fa-search"></i>';
        case 'icon':
          return '<i class="fa fa-search"></i>';
        case 'text_field':
          return this.getDetails().title + form;
        case 'field':
          return form;
      }
    }
    return (
      this.getDetails().title +
      (this.get('children').length > 0 ? '<span class="caret" role="presentation" />' : '')
    );
  }

  getClasses() {
    let details = this.getDetails();
    return details.customClasses || '';
  }

  getTarget() {
    let details = this.getDetails();
    if (details.type === 'login-page') {
      return '_parent';
    } else if (details.type === 'site' && FW.inEditor()) {
      return '_parent';
    }
    let target = details.target;
    target = target === '_current' ? '_self' : target;
    return target || '';
  }

  getChildren() {
    return this.get('children').filter(item => {
      return item.getDetails().title !== null;
    });
  }
}

class MenuItemCollection extends Collection {
  get comparator() {
    return 'order_id';
  }
}
MenuItemCollection.prototype.model = MenuItem;

MenuItem.prototype.relations = [
  {
    key: 'children',
    type: Backbone.Relational.HasMany,
    relatedModel: MenuItem,
    collectionType: MenuItemCollection,
    reverseRelation: {
      key: 'parent'
    }
  }
];

export default MenuItem;
export {MenuItemCollection};
