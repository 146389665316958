import React from 'react';
import PropTypes from 'prop-types';
//commented out until we upgrade to core-js 3
//import keydown from 'react-keydown';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from 'MuiWrappers/theme';

class ModalWrapper extends React.Component {
  //@keydown('esc')
  close() {
    if (FW.editor) {
      FW.editor.setMode('edit');
    }
    FW.store.set('modal', null);
  }

  getChildContext() {
    return {
      viewContext: 'modal'
    };
  }

  render() {
    let styles = {
      position: 'fixed',
      width:
        this.props.size === 'large'
          ? '95%'
          : this.props.size === 'small'
          ? '600px'
          : '1000px',
      height:
        this.props.size === 'large'
          ? '95%'
          : this.props.size === 'small'
          ? 'auto'
          : '600px',
      maxHeight: this.props.size === 'small' ? '80%' : null,
      top:
        this.props.size === 'large'
          ? '2.5%'
          : this.props.size === 'small'
          ? '10%'
          : '50%',
      left: this.props.size === 'large' ? '2.5%' : '50%',
      marginTop:
        this.props.size === 'large'
          ? '0px'
          : this.props.size === 'small'
          ? '0px'
          : '-300px',
      marginLeft:
        this.props.size === 'large'
          ? '0px'
          : this.props.size === 'small'
          ? '-300px'
          : '-500px',
      background: '#fff',
      padding: '10px',
      WebkitBoxShadow: 'rgba(0, 0, 0, 0.75) 0px 0px 35px 0px',
      MozBoxShadow: 'rgba(0, 0, 0, 0.75) 0px 0px 35px 0px',
      boxShadow: 'rgba(0, 0, 0, 0.75) 0px 0px 35px 0px',
      borderRadius: '8px',
      fontFamily: 'Helvetica',
      fontWeight: '100 !important',
      border: '1px solid #ddd',
      zIndex: this.props.style ? this.props.style.zIndex : 1000,
      overflow: this.props.style ? this.props.style.overflow : null,
    };
    styles = Object.assign(styles, this.props.styles || {});
    let h1Styles = {
      borderBottom: '1px solid #CEDBE4',
      paddingBottom: '10px',
      fontWeight: 300
    };

    let title = this.props.title ? (
      <h1 style={h1Styles}>{this.props.title}</h1>
    ) : (
      ''
    );

    return (
      <MuiThemeProvider theme={theme}>
        <div style={styles} className={this.props.className} data-cy="modal">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12" style={{textAlign: 'center'}}>
                {title}
                <p>{this.props.description}</p>
              </div>
            </div>
            <div className="row">{this.props.children}</div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}
ModalWrapper.childContextTypes = {
  viewContext: PropTypes.string
};

export {ModalWrapper};
