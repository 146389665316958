import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';
import {Site} from './Site';
import config from "../../config";
import $ from "jquery";
import moment from 'moment';

class SchedulableGroup extends Model {

  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/schedulable-groups';
  }

  /**
   */
  get defaults() {
    return {
      type: 'schedulable_group'
    };
  }

  setGroupPermission(permissionGroupId, permission, allow, callback) {
    console.log(permissionGroupId, permission, allow);
    $.post(config.API_ROOT + `/set-scheduling-group-permission/${this.get('id')}/${permissionGroupId}`, {
      permission: permission,
      allow: allow ? 1 : 0
    }).then(()=>{
      if(callback)
        callback();
    });
  }

  async getAllRecurrenceMoments(startMoment, endMoment) {
    const moments = [];
    const response = await $.get(config.API_ROOT +
      `/scheduling/resourceGroup/${encodeURIComponent(this.get('id'))}/events?` + 'timeMin=' + startMoment.format('YYYY-MM-DD[T]HH:mm:ss') + '&timeMax=' + endMoment.format('YYYY-MM-DD[T]HH:mm:ss')).promise();
    JSON.parse(response).items.map((event) => {
      const startDate = event.start.dateTime || event.start.date;
      const endDate = event.end.dateTime || event.end.date;
      let startMoment = moment(startDate);
      let endMoment = moment(endDate);
      moments.push({
        start: startMoment,
        end: endMoment,
        eventId: event.eventId,
        resourceId: event.resourceId
      });
    });
    return moments;
  }

}

class SchedulableGroups extends Collection {
}

SchedulableGroups.prototype.model = SchedulableGroup;
SchedulableGroups.prototype.sortField = 'created_at';
SchedulableGroups.prototype.sortOrder = 'DESC';

SchedulableGroup.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'schedulables',
    relatedModel: 'FW.Models.Schedulable',
    collectionType: 'FW.Models.Schedulables',
    collectionOptions: Model.defaultCollectionOptions,
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'unverified_reservations',
    relatedModel: 'FW.Models.SchedulableReservation',
    collectionType: 'FW.Models.SchedulableReservations',
    collectionOptions: Model.defaultCollectionOptions,
  }
];

export {SchedulableGroup, SchedulableGroups};
