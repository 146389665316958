import FileHelper from './FileHelper';

export default class Video extends FileHelper{

  get originalThumbnail(){
    let url = this._file.originalUrl();
    return url.replace(url.split('.')[url.split('.').length - 1], 'jpg');
  }

  get wowzaStreamingSources(){
    return [
      {file: this._file.get('live_stream_channel').archiveWowzaPath + this._file.get('extension') + ':' + this._file.get('remote_file') + '/playlist.m3u8'},
      {file: this._file.get('live_stream_channel').archiveWowzaRtmpPath + this._file.get('extension') + ':' + this._file.get('remote_file')}
    ];
  }

  get jwPlayerSources(){
    return this._file.get('location') === 'wowza' ? this.wowzaStreamingSources : {file: this.webUrl};
  }

  thumbnailUrl(size){
    if (this._file.get('location') === 'wowza'){
      const appId = this._file.get('live_stream_channel') ? this._file.get('live_stream_channel').appId : '';
      return 'https://transcoding.finalweb.net/get_thumbnail.php?folder_name=' + appId + '_archive&file_name=' + this._file.get('remote_file');
    } else if (this._file.get('location') === 'youtube' || this._file.get('location') === 'vimeo' || this._file.get('location') === 'faithlife_tv'){
      return this.featuredUrl(size);
    } else if (!this._file.get('processed')) {
      return null;
    }
    return super.thumbnailUrl(size);
  }

  featuredUrl(size){
    if (this._file.get('location') === 'youtube' && !this._file.get('featured_image_file_id')){
      return 'https://i.ytimg.com/vi/' + Video.getYouTubeId(this._file.get('remote_file')) + '/hqdefault.jpg';
    } if (this._file.get('location') === 'faithlife_tv' && !this._file.get('featured_image_file_id')){
      return this._file.get('details').faithlife_tv_thumbnail;
    } else if (this._file.get('location') === 'vimeo' && !this._file.get('featured_image_file_id')) {
      let details = this._file.get('details');
      return details && details.vimeo ? details.vimeo.thumbnail_url : null;
    } else if (this._file.get('location') === 'remote' && this._file.get('details') && this._file.get('details').thumbnail_url) {
      return this._file.get('details').thumbnail_url;
    } else {
      return super.featuredUrl(size);
    }
  }

  static getVimeoId(url){
    let regExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    let match = url.match(regExp);
    return match[3];
  }

  static getYouTubeId(url){
    url = url === null ? '' : url;
    let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : false;
  }

  static getFacebookId(url){
    const regex = /^(?:(?:https?:)?\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9\.]+\/videos\/(?:[a-zA-Z0-9\.]+\/)?([0-9]+)/;
    let match = url.match(regex);
    return (match && match[1]) ? match[1] : false;
  }

  static getRumbleId(url){
    let regex = /^.*(?:rumble\.com\/embed\/)([\w]+)(?:\/)/;
    let match = url.match(regex);
    return (match && match[1]) ? match[1] : false;
  }

}
