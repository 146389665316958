// @flow
import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {PageSection, PageSections} from './PageSection';
import {PageGroupLayout} from './PageGroupLayout';
import {PageGroupLayoutSetting} from './PageGroupLayoutSetting';
import prop from './Decorators/Prop';
import _ from 'underscore';
import {File as FWFile} from 'FW/Models/File';
import React from 'react';
import ReactDOM from 'react-dom';
import {layoutParent} from './Decorators/Layoutable';
import {insertGroupIntoPage} from './ModelHelpers';
import config from '../../config';
import $ from 'jquery';
import hasBackground from './Decorators/HasBackground';
import layoutSettings from './Decorators/LayoutSettings';
import GroupMaster from '../../Page/Groups/GroupMaster';
import updatesPage from './Decorators/UpdatesPage';
// import {$u} from '../../Utils';

/**
 * Represents a PageGroup
 */
@prop
@layoutParent('sections')
@hasBackground
@layoutSettings('settings')
@updatesPage
class PageGroup extends Model {
  get defaults(): {} {
    return {
      type: 'page_group'
    };
  }

  get syncRelations(): Array<string> {
    return ['layout_settings'];
  }

  get modelUrl(): string {
    return '/page-groups';
  }

  get background(): FWFile | null {
    let fileId = this.prop('background_file_id');
    return fileId ? FWFile.findOrCreate({id: fileId}) : null;
  }

  get isFooterGroup() {
    return this.get('site_id') !== null;
  }

  static sectionPath = 'sections';
  static definitionOptionsSchema = {};
  static getRequiredCoreOptionValues() {
    let IFW = FW.editor.getIframeWindow().FW;
    return {
      page_id: IFW.store.get('page').get('id')
    };
  }
  static finishAddingFromContentDefinition(responseData) {
    return Page.addGroupFromResponse(responseData)
      .then(insertGroupIntoPage)
      .then(() => {
        FW.editor.setMode('edit');
      });
  }

  bootAsFooter() {
    let el = document.getElementById('footer-mount');
    ReactDOM.render(
      <GroupMaster group={this} editor={FW.getParent().FW.editor} />,
      el
    );
  }

  getPadding() {
    let group = this;
    let padding = group.get('layout_settings')
      ? group.get('layout_settings').get('settings')
        ? typeof group.get('layout_settings').get('settings').padding_top !==
          'undefined'
          ? group.get('layout_settings').get('settings').padding_top
          : -1
        : -1
      : -1;
    let isFirstGroup = false;
    if (global.Page && Page.groups && Page.groups.models) {
      isFirstGroup = group.get('id') === Page.groups.models[0].get('id');
    }
    if (isFirstGroup && padding === -1) {
      padding = 0;
      return padding + 'px';
    } else {
      return (padding || 0) + 'px';
    }
  }

  shutdown() {}

  sections() {
    return this.get('sections');
  }

  /**
   * Gets the DOM element for the FW.PageGroup
   * @returns {*|jQuery|HTMLElement}
   */
  element() {
    return $("*[data-fw-model='PageGroup'][data-id='" + this.get('id') + "']");
  }

  setOrder() {
    let sections = new PageSections();
    this.element()
      .find("[data-fw-model='PageSection']")
      .each(function(i: number, e: Element) {
        let section = $(e).section();
        section.set('order_id', i);
        sections.add(section);
      });
    sections.save();
  }

  copy(opts): Promise<void> {
    opts = _.extend(
      {
        toPage: null
      },
      opts
    );
    const page = this.get('page');
    const id = this.get('id');
    if (parent) {
      parent.$u.loading();
    } else {
      $u.loading();
    }
    return new Promise(complete => {
      $.post(config.API_ROOT + '/page-groups/' + id + '/copy', {
        toPage: opts.toPage
      }).then(d => {
        if (opts.toPage) {
          window.parent.FW.editor.sendIframeTo(
            '/page/' + d.slug + '#PageGroup_' + d.page_group_id
          );
          complete();
        } else {
          if (d) {
            let group = new FW.Models.PageGroup(d.group);
            group.get('sections').each(function(section) {
              let sectionData = d.sectionData[section.get('id')];
              FW.Models.PageSection.parseJsonData(sectionData);
              FW.store.set(
                'group-' + group.get('id') + '-background',
                FW.Models.File.findOrCreate(d.backgroundFile)
              );
              page.sections.add(section);
            });
            Page.groups.add(group);
            insertGroupIntoPage({group: group, html: d.html}).then(() => {
              if (parent) parent.$u.loading(true);
              else $u.loading(true);
              complete();
            });
          }
        }
      });
    });
  }
}
PageGroup.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'layout',
    relatedModel: PageGroupLayout
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'sections',
    relatedModel: PageSection,
    collectionType: PageSections,
    reverseRelation: {
      key: 'group',
      type: Backbone.Relational.HasOne,
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'layout_settings',
    relatedModel: PageGroupLayoutSetting,
    reverseRelation: {
      key: 'group',
      type: Backbone.Relational.HasOne,
      includeInJSON: false
    }
  }
];

/**
 * The collection for the PageGroup model.
 */
@updatesPage
class PageGroups extends Collection {
  elements() {
    let array = [];
    this.each(function(e) {
      array.push(e.element());
    });
    return array;
  }
}
PageGroups.prototype.model = PageGroup;
PageGroups.prototype.sortField = 'order_id';
PageGroups.prototype.sortOrder = 'ASC';

export {PageGroup};
export {PageGroups};
