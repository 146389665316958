/* global SQUARE_APPLICATION_ID, WEBSOCKETS_WOWZA_PUBLISHER_ADDRESS, WEBSOCKET_HOST, PUSHER_APP_KEY, SAMPLE_CLOUDFRONT_ROOT, CLOUDFRONT_ROOT, FROALA_2_KEY, FROALA_KEY, AWS_SAMPLE_BUCKET, AWS_REGION, AWS_BUCKET, RECAPTCHA_PUBLIC_KEY, APP_DEBUG, ADOBE_SDK_KEY, SENTRY_PUBLIC_DSN, APP_ENV, RELEASE_HASH, FINALWEB_HOST_IP, FINALWEB_WEB_HOST, RECAPTCHA3_PUBLIC_KEY, DEVELOPMENT_HOST, PIXABAY_KEY, PRODUCT_NAME, PRODUCT_SHORT_NAME */
/**
 * @typedef {Object} config
 * @property DEBUG_MODE
 * @property MAX_COLS_IN_ROW
 * @property PAGE_SECTION_MODEL
 * @property PAGE_ROW_MODEL
 * @property PAGE_GROUP_MODEL
 * @property COL_WIDTH_PERCENTAGE
 * @property API_ROOT
 */
const AWS_ROOT = 's3.' + AWS_REGION + '.amazonaws.com';
const config = {
  DEBUG_MODE: APP_DEBUG,
  MAX_COLS_IN_ROW: 12,
  PAGE_SECTION_MODEL: 'PageSection',
  MENU_MODEL: 'Menu',
  LOGO_MODEL: 'SiteLogo',
  PAGE_ROW_MODEL: 'PageRow',
  PAGE_GROUP_MODEL: 'PageGroup',
  COL_WIDTH_PERCENTAGE: 8.333333333333,
  API_ROOT: '/api/v1',
  AWS_BUCKET: AWS_BUCKET,
  AWS_URL: 'https://' + AWS_ROOT,
  AWS_ROOT: AWS_ROOT,
  CLOUDFRONT_URL: 'https://' + CLOUDFRONT_ROOT,
  AWS_SAMPLE_URL: 'https://' + SAMPLE_CLOUDFRONT_ROOT,
  RECAPTCHA_PUBLIC_KEY: RECAPTCHA_PUBLIC_KEY,
  RECAPTCHA3_PUBLIC_KEY: RECAPTCHA3_PUBLIC_KEY,
  ADOBE_SDK_KEY: ADOBE_SDK_KEY,
  PAYPAL_URL: APP_DEBUG
    ? 'https://www.sandbox.paypal.com/cgi-bin/webscr'
    : 'https://www.paypal.com/cgi-bin/webscr',
  OTHER_FILE_TYPE_ID: 6,
  DOCUMENTATION_URL: 'http://docs.finalweb.com/',
  SENTRY_PUBLIC_DSN: SENTRY_PUBLIC_DSN,
  APP_ENV: APP_ENV,
  RELEASE_HASH: RELEASE_HASH,
  FINALWEB_HOST_IP: FINALWEB_HOST_IP,
  FINALWEB_WEB_HOST: FINALWEB_WEB_HOST,
  INCLUDED_DOMAIN_COST: 15,
  DEVELOPMENT_HOST: DEVELOPMENT_HOST,
  PIXABAY_KEY: PIXABAY_KEY,
  PRODUCT_NAME: PRODUCT_NAME,
  PRODUCT_SHORT_NAME: PRODUCT_SHORT_NAME,
  PRODUCT_LOGO: '/images/logo-tiny.png',
  PRODUCT_LOGO_LARGE: '/images/logo-large.png',
  FROALA_KEY: FROALA_KEY,
  FROALA_2_KEY: FROALA_2_KEY,
  PUSHER_APP_KEY: PUSHER_APP_KEY,
  WEBSOCKET_HOST: WEBSOCKET_HOST,
  WEBSOCKETS_WOWZA_PUBLISHER_ADDRESS: WEBSOCKETS_WOWZA_PUBLISHER_ADDRESS,
  SQUARE_APPLICATION_ID: SQUARE_APPLICATION_ID
};

export default config;
