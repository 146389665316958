import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';

class UrlAlias extends Model{

  get modelUrl(){ return '/url-aliases'; }

  get defaults(){
    return {
      type: 'url_aliases'
    };
  }

  static searchKeys = [
    'slug',
    'linked_title',
    'linked_url'
  ];

}
UrlAlias.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'linkable'
  }
];

class UrlAliases extends Collection{}
UrlAliases.prototype.model = UrlAlias;

export {UrlAlias, UrlAliases};
