// @flow
import React from 'react';
import {Responsive, WidthProvider as widthProvider} from 'react-grid-layout';
const ResponsiveReactGridLayout = widthProvider(Responsive);
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import _ from 'underscore';
import clone from 'clone';
import {PageSection, PageSections} from 'FW/Models/PageSection';
import {PageGroup} from 'FW/Models/PageGroup';
import ErrorBoundary from '../../ErrorBoundary';
import {withSize as sizeMe} from 'react-sizeme';
import {Helper} from '../../Helpers';
import './GroupView.scss';

class GroupView extends React.Component{

  state: {
    sections: PageSections,
    layouts: {},
    breakpoint: string,
    TemplateClasses: {}
  };

  changing: boolean;

  constructor(props: { group: PageGroup }){
    super(...arguments);

    let template = FW.store.get('template');

    this.state = {
      sections: this.props.group.get('sections'),
      layouts: props.group.childLayouts,
      breakpoint: 'lg',
      TemplateClasses: template._sectionClasses
    };

    this.renderHeight = this.renderHeight.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startChanging = this.startChanging.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.addSection = this.addSection.bind(this);
  }

  onBreakpointChange(breakpoint: string){
    this.props.group.get('sections').each((section) => {
      section.forceUpdate = true;
    });
    this.setState({
      breakpoint: breakpoint
    });
  }

  onChange(layout: LayoutableLayoutType, oldLayout: LayoutableLayoutType, newLayout: LayoutableLayoutType){
    let changedId = newLayout.i.replace('section-', '');
    if (FW.inEditor()){
      window.parent.FW.editor.modes.activeMode.lastChanged = changedId;
    }
    let allLayouts = clone(this.state.layouts);
    allLayouts[this.state.breakpoint] = layout;

    _.each(allLayouts[this.state.breakpoint], (s) => {
      let id = s.i.replace('section-', '');
      let sec = this.state.sections.get(id);
      let l = sec.prop('grid_layouts') || {};
      l[this.state.breakpoint] = s;
      sec.prop('grid_layouts', l);
      sec.forceUpdate = true;
    });
    if (this.state.breakpoint !== 'lg'){
      this.props.group.prop('custom_breakpoints.' + this.state.breakpoint, true);
      this.state.sections.save({parse: false}).then(() => {
        this.props.group.save();
      });
    } else {
      this.state.sections.save();
    }

    this.changing = false;
    this.setState({
      layouts: this.props.group.childLayouts
    });
  }

  getBodyScale(): number {
    const regExp = /scale\(([^)]+)\)/;
    let doc = window.name !== 'editFrame' ? (FW.editor ? FW.editor.getIframeWindow().document : document) : document;
    const bodyTransform = doc && doc.body ? doc.body.style.transform : '';
    const matches = regExp.exec(bodyTransform);
    return matches ? matches[1] : 1;
  }

  renderHeight(section: PageSection, sSize: SizeType){
    const scale = this.getBodyScale();
    let changed = false;
    let size = {
      width: sSize.width / scale,
      height: sSize.height / scale
    };
    if ((!this.changing && !window.zooming && (section.prevSize ? section.prevSize.height : null) !== size.height) || section.forceUpdate){
      let layouts = clone(this.state.layouts);
      _.each(layouts[this.state.breakpoint], (layout: LayoutableLayoutType, i: number) => {
        if (layout.i === 'section-' + section.get('id')){
          let h = layout.h;
          let neededHeight = Math.ceil(size.height / 30);
          // if the needed height is less than the minH, use the minH instead.
          neededHeight = neededHeight >= layout.minH ? neededHeight : layout.minH;
          //if (h < neededHeight || layout.minH !== neededHeight){
          if (h < neededHeight || h > neededHeight){
            changed = true;
            layouts[this.state.breakpoint][i].h = neededHeight;
            let l = clone(section.prop('grid_layouts')) || {};
            l[this.state.breakpoint] = layouts[this.state.breakpoint][i];
            section.prop('grid_layouts', l);
            //layouts[this.state.breakpoint][i].minH = neededHeight;
          }
        }
      });

      section.forceUpdate = false;
      section.prevSize = {
        height: size.height * scale,
        width: size.width * scale
      };
      if (changed){
        this.setState({
          layouts: layouts
        });
      }
    }
  }

  startChanging(){
    this.changing = true;
  }

  addSection(){
    window.parent.FW.editor.setMode('addSection', 'chooseSectionType', {group: this.props.group});
  }

  componentDidUpdate(){
    let group = this.props.group;
    let size = {
      height: this.props.size.height / (window.zoomScale || 1),
      width: this.props.size.width / (window.zoomScale || 1)
    };
    if (FW.inEditor()) {
      group.prop('apparent_size', size);
      if (typeof group.prop('aspect_ratio') === 'undefined') {
        group.prop('aspect_ratio', Helper.getAspectRatio(size.width, size.height));
      }
    }
    let ratio = group.prop('aspect_ratio') || {};
    let real = Helper.getAspectRatio(size.width, size.height);
    let correctHeight = (size.width / ratio.width) * ratio.height;
    if (ratio.height !== real.height && this.state.height !== correctHeight && group.prop('lock_aspect_ratio')) {
      this.setState({
        height: correctHeight
      });
    }
  }

  componentDidMount(){
    if (FW.inEditor()){
      window.parent.FW.editor.on('change:mode', () => {
        setTimeout(() => {
          this.forceUpdate();
        }, 1000);
      }, this);

      window.parent.FW.editor.on('modestop:arrangeSections', () => {
        setTimeout(() => {
          this.forceUpdate();
        }, 800);
      }, this);
    }
    if (FW.inEditor()){
      $(window).on('unload', () => {
        window.parent.FW.editor.off(null, null, this);
      });
    }
    this.props.group.getGroupHeight().then((height) => {
      this.setState({
        height: height
      });
    });

    this.props.group.on('change:layout_settings change:properties', (a, b, c) => {
      clearTimeout(this.updateTimer);
      this.updateTimer = setTimeout(() => {
        this.props.group.getGroupHeight().then((height) => {
          this.setState({
            height: height
          });
        })
      }, 100);
    }, this);

    this.props.group.on('sync', () => {
      this.setState({
        layouts: this.props.group.childLayouts
      });
    }, this);

    this.props.group.get('sections').on('update', () => {
      this.setState({
        layouts: this.props.group.childLayouts
      });
    }, this);

    FW.store.loaded('page', () => {
      FW.store.get('page').getFonts();
    });
  }

  componentWillUnmount(){
    this.props.group.off(null, null, this);
    this.props.group.get('sections').off(null, null, this);

    if (FW.inEditor())
      window.parent.FW.editor.off(null, null, this);
  }

  editSection(section){
    if (FW.store.get('user').may('edit_page', FW.store.get('page')) && (this.props.editor.mode.value === 'editSections' || this.props.editor.mode.value === 'edit')){
      if (Helper.isMobile()) {
        if (!section.tapTime) {
          section.tapTime = new Date();
        } else {
          let diff = ((new Date).getTime() - section.tapTime.getTime());
          if (diff < 900) {
            window.parent.FW.editor.setMode('editSections', 'editText', section);
            section.tapTime = null;
          } else {
            section.tapTime = new Date();
          }

        }
      } else {
        if (window.parent.FW.editor.mode.value !== 'editSections' && window.parent.FW.editor.modes.activeMode.currentAction !== 'editText') {
          window.parent.FW.editor.setMode('editSections', 'editText', section);
        }
      }
    }
  }

  render(){
    let windowHeight = this.state.windowHeight ? this.state.windowHeight : $(window).height();
    if (!this.state.TemplateClasses) return null;

    /*
     * TODO: there is an issue when pressing back on the browser if you're editing a page
     * that I worked around by checking if window exists, but there has to be a way to make sure
     * the listener is removed cleanly
     */
    let editor = (window && FW.inEditor()) ? window.parent.FW.editor : null;

    let sections = this.state.sections.map((section) => {
      let Section = section.getReactComponent();
      let inlineEditable = section.get('section_type') ? section.get('section_type').get('inline_editable') === 1 : false;
      return <div
        key={'section-' + section.get('id')}
        data-fw-model="PageSection"
        onClick={inlineEditable && this.props.editor ? this.editSection.bind(this, section) : null}
        data-id={section.get('id')}
        id={`PageSection_${section.get('id')}`}
        data-fw-inline-editable={inlineEditable}>
          <ErrorBoundary>
            <Section section={section} finishedRendering={this.renderHeight} editor={this.props.editor} />
          </ErrorBoundary>
        </div>;
    });

    let mode = editor ? editor.mode.value : 'visitor';

    let addTitle = this.props.group.get('site_id') ? 'Site Footer Group' : 'This Group is Empty';

    let latest = FW.store.get('page') ? FW.store.get('page').get('latest') : null;

    let placeholder = editor ? <div className="group-placeholder">
      <h1>{addTitle}</h1>
      { !latest || FW.store.get('site').get('auto_publish') === 1 ? <div>
        {this.props.group.get('site_id') !== null ? <p>This appears on every page.</p> : null}
          <p>You can add sections to make it beautiful. Click "Add Section" to start adding content.</p>
          <span className="btn btn-default add-section-btn" onClick={this.addSection}>
            Add Section
          </span>
        </div> : <div>
        <p>You can add sections to make it beautiful. Click "Edit Page" on the left to get started.</p>
      </div> }
    </div> : null;

    let classNames = this.props.group.shouldBeFullHeight() ? 'col-xs-12 full-height' : 'col-xs-12';
    let fontColor = typeof this.props.group.ls('font_color') !== 'undefined' ? this.props.group.ls('font_color') : null;
    let style = this.props.group.shouldBeFullHeight() ? {minHeight: windowHeight, color: fontColor} : {color: fontColor, height: this.state.height};

    style.opacity = this.props.group.prop('hide_group') && FW.inEditor() ? 0.1 : 1;

    let horizontalSpace = this.props.group.prop('section_margin_x') || 0;
    let verticalSpace = this.props.group.prop('section_margin_y') || 0;

    let compact = !this.props.group.get('site_id');

    return (
      <div className={classNames} style={{transitionDuration: '200ms', ...style}}>
        {sections.length > 0 ? <ResponsiveReactGridLayout
          className="layout"
          layouts={this.state.layouts}
          breakpoints={{lg: 889, sm: 600, xs: 0}}
          cols={{lg: 12, sm: 6, xs: 2}}
          rowHeight={30}
          transformScale={this.getBodyScale()}
          margin={[horizontalSpace, verticalSpace]}
          containerPadding={[this.props.size.width < 600 ? 0 : (this.props.group.ls('padding_side') || 0), 0]}
          onBreakpointChange={this.onBreakpointChange}
          onDragStop={this.onChange}
          onDragStart={this.startChanging}
          onResizeStart={this.startChanging}
          onResizeStop={this.onChange}
          isDraggable={mode === 'arrangeSections'}
          isResizable={mode === 'arrangeSections'}
          transformScale={0.5}
          compactType={compact ? 'vertical' : null}
        >
          {sections}
        </ResponsiveReactGridLayout> : placeholder}
      </div>
    );
  }

}

export default sizeMe({monitorHeight: true})(GroupView);
