import React from 'react';
import './BannerAlert.scss';

export default function BannerAlert({alert}) {
  const styleClass = 'alert-' + (alert.banner_style || 'success');

  const dismiss = () => {
    Cookies.set('banner-dismissed', true, {expires: 1});
  };

  return Cookies.get('banner-dismissed') && !FW.inEditor() ? null : <div id="banner-alert" className={'alert alert-dismissible banner-alert ' + styleClass} role="alert" style={{
    position: 'fixed', top: 0, right: 0, zIndex: 99999, width: '80vw', marginTop: 20,
    left: '50%',
    transform: 'translate(-50%, 0)'
  }}>
    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span
      aria-hidden="true" onClick={dismiss}>&times;</span></button>
    <span dangerouslySetInnerHTML={{__html: alert.banner_content}}></span>
  </div>;

}
