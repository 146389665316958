import {Model} from './Model';
import {Collection} from './Collection';
import s from 'striptags';
import {Backbone} from 'FWBackbone';
import {Comment, Comments} from './Comment';
import {Tag, Tags} from './Tag';
import {BlogCategory, BlogCategories} from './BlogCategory';
import {User, Users} from './User';
import $ from 'jquery';
import prop from './Decorators/Prop';

@prop
class BlogPost extends Model {
  get modelUrl() {
    return '/blog-posts';
  }

  get defaults() {
    return {
      type: 'blog_post'
    };
  }

  get hasPermissionsThrough(){
    return ['blog'];
  }

  specialPermissions(user, hasPermission, newModel) {
    if (this.get('blog').get('limit_users_to_own_posts') && this.get('user_id') !== user.get('id')) {
      return false;
    }
    return hasPermission;
  }

  static searchKeys = ['description', 'content', 'title'];

  get includedRelations() {
    return ['blog', 'file', 'user'];
  }

  send(csrf_token) {
    const postID = this.get('id');
    const blogSlug = this.get('blog').get('slug');
    $.post(`/blog/${blogSlug}/post/${postID}/send`, {csrf_token: csrf_token})
      .done(data => {
        swal.fire(
          'Email Sent',
          'Your subscribers have been notified!',
          'success'
        );
      })
      .fail(xhr => {
        const data = xhr.responseJSON;
        const message = data.message || 'Something went wrong';
        swal.fire('Uh oh...', message, 'error');
      });
  }

  get commentsAllowed() {
    let blog = this.get('blog');
    let user = FW.store.get('user');
    let site = FW.store.get('site');

    //TODO: make sure we implement security for this on the backend.
    //if the blog allows anonymous comments and the post allows comments OR the blog doesn't allow anonymous
    //comments but a user is logged in an the post allows comments
    return (
      ((!blog.get('allow_anonymous_comments') &&
        user &&
        user.get('customer_id') === site.get('customer_id')) ||
        blog.get('allow_anonymous_comments')) &&
      this.get('allow_comments')
    );
  }

  get requireCommentApproval() {
    return this.get('blog').get('require_comment_approval');
  }

  get author() {
    let user = this.get('user');
    return this.get('author')
      ? this.get('author')
      : user
      ? user.get('name')
      : 'Administrator';
  }

  getUrl() {
    return '/blog/' + this.get('blog').get('slug') + '/' + this.get('slug');
  }

  getPreview(charactersToDisplay = 750, showMulipleLines = false) {
    let separator = '<br />';
    let content = $('<div>' + this.get('content') + '</div>');
    content.find('script').remove();
    content.find('video').remove();
    content.find('.fr-img-caption').remove();
    let contentText = content
      .wrap('<div>')
      .parent()
      .html();
    //Match all the tags we want to separate in our plaintext representation
    //Otherwise <h1>Title</h1><p>Content</p> becomes TitleContent
    const pattern = /(<p|<h[1-5]|<br)/gim;
    contentText = contentText.replace(pattern, '||br||$1');
    //Strip all tags
    content = s(contentText, ['a']);

    //Replace our placeholder, ||br||, with ' - '
    var originalContent = content;
    if (content.indexOf('||br||') === 0) {
      content = content.substring(6, charactersToDisplay ?? 750);
    }
    const brRegEx = showMulipleLines ? /(\|\|br\|\|)/gi : /(\|\|br\|\|)+/gi;
    content = content.replace(brRegEx, separator);
    content = content.substring(0, charactersToDisplay ?? 750);
    if (content.lastIndexOf('<a') > content.lastIndexOf('</a')) {
      var charsNeeded = charactersToDisplay - content.lastIndexOf('<a');
      // need to get the end of the link
      content = originalContent.substring(0, originalContent.indexOf('</a>', content.lastIndexOf('<a')) + charsNeeded + 2) + ' ';
      content = content.replace(brRegEx, separator);
    }

    var lastSpace = content.lastIndexOf(' ');
    if (lastSpace >= 0) {
      content = content.substring(0, lastSpace) + '...';
    }

    //content = $('<textArea />').html(content).text();
    return content;
  }

  /**
   * Gets the DOM element for this section.
   * @returns {*|jQuery|HTMLElement}
   */
  element() {
    return $("*[data-fw-model='BlogPost'][data-id='" + this.get('id') + "']");
  }
}

BlogPost.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'comments',
    relatedModel: Comment,
    collectionType: Comments,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'post'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'user',
    relatedModel: User,
    collectionType: Users,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'blog_posts'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'tags',
    relatedModel: Tag,
    collectionType: Tags,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'post'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'categories',
    relatedModel: BlogCategory,
    collectionType: BlogCategories,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'post'
    }
  }
];

class BlogPosts extends Collection {}
BlogPosts.prototype.model = BlogPost;
BlogPosts.prototype.sortField = 'post_time';
BlogPosts.prototype.sortOrder = 'DESC';

export {
  /**
   * The blog post model
   */
  BlogPost,
  /**
   * Collection of Blog Post models
   */
  BlogPosts
};
