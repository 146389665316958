import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import {PayPalItem as Item, PayPalItems as Items} from './PayPalItem';

class PayPalGroup extends Model {
  get modelUrl() {
    return '/paypal-groups';
  }

  get defaults() {
    return {
      type: 'paypal_group'
    };
  }
}
PayPalGroup.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'paypal_items',
    relatedModel: Item,
    collectionType: Items,
    collectionOptions: Model.defaultCollectionOptions
  }
];

class PayPalGroups extends Collection {}
PayPalGroups.prototype.model = PayPalGroup;

export {
  /**
   * The blog model
   */
  PayPalGroup,
  /**
   * Collection of Blog models
   */
  PayPalGroups
};
