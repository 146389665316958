import React from 'react';
import ContentDelete from '@material-ui/icons/DeleteSweep';
import Fab from '@material-ui/core/Fab';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  secondary: {
    background: '#822823',
    '&:hover': {
      background: '#57221e'
    }
  }
});

class AdminDeleteButton extends React.Component {
  render() {
    return (
      <Fab
        color="secondary"
        aria-label="Delete"
        style={{position: 'absolute', right: 20, bottom: 20, zIndex: 1000}}
        {...this.props}
        data-cy="delete"
      >
        <ContentDelete />
      </Fab>
    );
  }
}

export default withStyles(styles)(AdminDeleteButton);
