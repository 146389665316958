import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';
import {User} from './User';
import {Site} from './Site';

class Comment extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/comments';
  }

  /**
   */
  get defaults() {
    return {
      type: 'comment'
    };
  }

  get needsApproval() {
    return this.get('approved_at') === null;
  }
}

class Comments extends Collection {}
Comments.prototype.model = Comment;
Comments.prototype.sortField = 'created_at';
Comments.prototype.sortOrder = 'DESC';

Comment.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'comments',
    relatedModel: 'FW.Models.Comment',
    collectionType: 'FW.Models.Comments',
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'parent_comment'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'user',
    relatedModel: User,
    reverseRelation: {
      type: Backbone.Relational.HasMany,
      key: 'comments'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'commentable',
    relatedModel: Model
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site,
    reverseRelation: {
      type: Backbone.Relational.HasMany,
      key: 'comments'
    }
  }
];

export {Comment, Comments};
