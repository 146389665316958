// @flow
import _ from 'underscore';
import FWModel from 'FW/Models/Model';

function layoutable(propName: string, parentName: string): Function {
  let layoutFunctions = {
    getMainBreakpoint: function() {
      let parent = this.get(parentName) || this.collection[parentName];
      return parent.get('main_breakpoint');
    },
    getLayout: function(breakpoint: string): LayoutableLayoutType {
      let parent = this.get(parentName) || this.collection[parentName];
      let customBreakpoints =
        parent && typeof parent.prop !== 'undefined'
          ? parent.prop('custom_breakpoints') || {}
          : {};
      let bp = customBreakpoints[breakpoint] !== true ? 'lg' : breakpoint;
      let layout = _.clone(
        this.prop('grid_layouts')
          ? this.prop('grid_layouts')[bp]
            ? this.prop('grid_layouts')[bp]
            : this.defaultLayouts[bp]
          : this.defaultLayouts[bp]
      );
      if (customBreakpoints[breakpoint] !== true) {
        if (breakpoint === 'sm') {
          layout.w = layout.w / 2;
          layout.h = layout.h / 2;
          if (layout.h < 1) {
            layout.h = 1;
          }
          layout.x = layout.x / 2;
        } else if (breakpoint === 'xs') {
          layout.w = 2;
          layout.h = layout.h / 4;
          if (layout.h < 1) {
            layout.h = 1;
          }
          layout.x = 0;
        }
      }
      //this ensures the correct id is given out with the layout
      layout.i = propName + '-' + this.get('id');
      layout.minH = typeof layout.minH === 'undefined' ? 1 : layout.minH;
      layout.h = layout.h >= layout.minH ? layout.h : layout.minH;
      return layout;
    },

    moveToEndOfLayout: function(parent: FWModel = this.get(parentName)) {
      /*
       * gets the y coordinate for the new section
       */
      let layouts = parent.childLayouts;
      let lasts = {lg: 0, sm: 0, xs: 0};
      _.each(layouts, (l, k) => {
        _.each(l, layout => {
          if (layout.y >= lasts[k]) {
            lasts[k] = layout.y + 1;
          }
        });
      });
      let sLayout = _.clone(this.defaultLayouts);
      sLayout.lg.y = lasts.lg;
      sLayout.sm.y = lasts.sm;
      sLayout.xs.y = lasts.xs;
      this.prop('grid_layouts', sLayout);
    }
  };

  return function(target: FWModel) {
    Object.defineProperty(
      target.prototype,
      'defaultLayouts',
      ({
        get: function(): {
          lg: LayoutableLayoutType,
          sm: LayoutableLayoutType,
          xs: LayoutableLayoutType
        } {
          let height =
            this.prop('default_height') || this.prop('min_height') || 3;
          return {
            lg: {
              i: propName + '-' + this.get('id'),
              x: 0,
              y: 0,
              w: this.prop('col_width') || 12,
              h: height,
              minH: this.prop('min_height') || 1,
              minW: 1
            },
            sm: {
              i: propName + '-' + this.get('id'),
              x: 0,
              y: 0,
              w: 6,
              h: height,
              minH: 1,
              minW: 1
            },
            xs: {
              i: propName + '-' + this.get('id'),
              x: 0,
              y: 0,
              w: 2,
              h: height,
              minH: 1,
              minW: 1
            }
          };
        }
      }: Object)
    );

    Object.assign(target.prototype, layoutFunctions);
  };
}

function layoutParent(childName: string): Function {
  let parentFunctions = {
    breakpoints: {
      desktop: 'lg',
      tablet: 'sm',
      mobile: 'xs'
    },

    hasCustomBreakpoint: function(): boolean {
      let cb = this.prop('custom_breakpoints') || {};
      let bp = this.breakpoints[window.parent.FW.editor.device];
      return cb[bp] === true;
    }
  };

  return function(target: FWModel) {
    Object.defineProperty(
      target.prototype,
      'childLayouts',
      ({
        get: function(): {
          lg: Array<LayoutableLayoutType>,
          sm: Array<LayoutableLayoutType>,
          xs: Array<LayoutableLayoutType>
        } {
          let layouts = {lg: [], sm: [], xs: []};
          if (this.get(childName) && typeof this.get(childName).each !== 'undefined') {
            this.get(childName).each((field: FWModel) => {
              _.each(layouts, (l: LayoutableLayoutType, k: number) => {
                layouts[k].push(field.getLayout(k));
              });
            });
          }
          return layouts;
        }
      }: Object)
    );
    Object.assign(target.prototype, parentFunctions);
  };
}

export default layoutable;
export {layoutParent};
