import Video from './Video';
import Image from './Image';
import Document from './Document';
import Archive from './Archive';
import FileHelper from './FileHelper';
import Audio from './Audio';

export default {
  video: Video,
  image: Image,
  document: Document,
  archive: Archive,
  audio: Audio,
  FileHelper: FileHelper
};
