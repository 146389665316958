import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';

class MediaLibrary extends Model {
  get modelUrl() {
    return '/media-libraries';
  }

  get defaults() {
    return {
      type: 'media_library'
    };
  }

  static searchKeys = [
    'description',
    'title',
    'key'
  ];
}
MediaLibrary.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'files',
    relatedModel: 'FW.Models.File',
    collectionType: 'FW.Models.Files',
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'cover',
    relatedModel: 'FW.Models.File'
  }
];

class MediaLibraries extends Collection {}
MediaLibraries.prototype.model = MediaLibrary;

export {
  /**
   * The blog model
   */
  MediaLibrary,
  /**
   * Collection of Blog models
   */
  MediaLibraries
};
