import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import config from 'config';

class LiveStreamChannelStream extends Model{

  get modelUrl(){ return '/live-stream-channel-streams'; }

  get defaults(){
    return {
      type: 'live_stream_channel_stream'
    };
  }

  async getNumberOfViewers() {
    let viewers = await $.get(config.API_ROOT + '/live-stream-channel-streams/' + this.get('id') + '/number-of-viewers');
    return viewers.number_of_viewers;
  }

}
LiveStreamChannelStream.prototype.relations = [];

class LiveStreamChannelStreams extends Collection{}
LiveStreamChannelStreams.prototype.model = LiveStreamChannelStream;
LiveStreamChannelStreams.prototype.sortField = 'date_ended';
LiveStreamChannelStreams.prototype.sortOrder = 'DESC';

export {LiveStreamChannelStream, LiveStreamChannelStreams};
