var clone = require('clone');
import _ from 'underscore';

function layoutSettings(propName) {
  propName = propName || 'settings';

  return function(target) {
    let layoutFunctions = {
      ls(key, value) {
        let settings =
          _.clone(
            this.get('layout_settings')
              ? this.get('layout_settings').get(propName) || {}
              : {}
          ) || {};
        if (Array.isArray(settings)) {
          settings = {};
        }
        if (typeof value !== 'undefined') {
          settings[key] = value;
          this.get('layout_settings').set(propName, settings);
          this.trigger('change:layout_settings change');
        }
        let ls = this.get('layout_settings')
          ? this.get('layout_settings').get(propName) || {}
          : {} || {};
        return ls[key];
      }
    };

    Object.assign(target.prototype, layoutFunctions);
  };
}

export default layoutSettings;
