import {Backbone} from 'FWBackbone';
import _ from 'underscore';
import {Model} from './Model';
import MenuItem, {MenuItemCollection} from './MenuItem';
import config from 'config';
import {Collection} from './Collection';

class Menu extends Model {
  get includedRelations() {
    return ['menu_item'];
  }

  get modelUrl() {
    return '/menus';
  }

  get defaults() {
    return {
      type: 'menu'
    };
  }

  initialize() {
    super.initialize.call(this, arguments);
  }

  save(attrs, options) {
    let oldError = options.error;
    options.error = function() {
      Sentry.captureException(new Error('Error saving menu!'));
      if (oldError) {
        oldError(...arguments);
      }
    };
    //mark all items as not new
    if (this.get('items').hasChanged()) {
      this.trigger('change:items');
    }

    //remove models marked for deletion
    _.each(this.get('items').getIdsForDeletion(), id => {
      this.get('items').remove(id);
    });

    let items = this.get('items').toJSON();
    let data = {data: items};
    $.ajax({
      method: 'patch',
      data: JSON.stringify(data),
      contentType: 'application/json',
      url: config.API_ROOT + '/menus/' + this.get('id') + '/menu-items',
      success: () => {
        super.save(attrs, options);
      },
      error: () => {
        Sentry.captureException(new Error('Error saving menu items!'));
      }
    });
  }

  rollback() {
    this.get('items').rollback();
    super.rollback();
  }

  boot() {
    if (this.element()) {
      this.view = rivets.bind(this.element(), {menu: this});
    }
  }

  getItems() {
    let items = _.sortBy(this.get('items').where({parent_id: null}), model => {
      return model.get('order_id');
    });
    return _.filter(items, item => {
      return item.getDetails().title !== null;
    });
  }

  element() {
    return $('*[data-fw-model="Menu"][data-id="' + this.get('id') + '"]');
  }
}
Menu.prototype.relations = [
  {
    key: 'items',
    type: Backbone.Relational.HasMany,
    relatedModel: MenuItem,
    collectionType: MenuItemCollection,
    collectionOptions: {
      parentPath: '/menus',
      parentRelation: 'menu'
    },
    reverseRelation: {
      key: 'menu',
      includeInJSON: false
    }
  }
];

class Menus extends Collection {}
Menus.prototype.model = Menu;

export {Menu, Menus};
