/**
 * Created by ianandersen on 4/12/17.
 */
import {Model} from './Model';
import {Collection} from './Collection';

class SiteLogo extends Model {
  get modelUrl() {
    return '/site-logos';
  }

  get defaults() {
    return {
      type: 'site_logo'
    };
  }
}

SiteLogo.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'image',
    relatedModel: 'FW.Models.File'
  }
];

class SiteLogos extends Collection {}
SiteLogos.prototype.model = SiteLogo;

export {
  /**
   * The site logo model
   */
  SiteLogo,
  /**
   * Collection of site logo models
   */
  SiteLogos
};
