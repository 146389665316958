import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';
import moment from 'moment';
import $ from 'jquery';
import config from '../../config';

class Schedulable extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/schedulables';
  }

  /**
   */
  get defaults() {
    return {
      type: 'schedulable'
    };
  }

  async getAllRecurrenceMoments(startMoment, endMoment) {
    const moments = [];
    const response = await $.get(
      config.API_ROOT +
        `/scheduling/resource/${encodeURIComponent(this.get('id'))}/events?` +
        'timeMin=' +
        startMoment.format('YYYY-MM-DD[T]HH:mm:ss') +
        '&timeMax=' +
        endMoment.format('YYYY-MM-DD[T]HH:mm:ss')
    ).promise();
    JSON.parse(response).items.map(event => {
      const startDate = event.start.dateTime || event.start.date;
      const endDate = event.end.dateTime || event.end.date;
      let startMoment = moment(startDate);
      let endMoment = moment(endDate);
      moments.push({
        start: startMoment,
        end: endMoment,
        eventId: event.eventId,
        resourceId: event.resourceId
      });
    });
    return moments;
  }
}

class Schedulables extends Collection {}

Schedulables.prototype.model = Schedulable;
Schedulables.prototype.sortField = 'created_at';
Schedulables.prototype.sortOrder = 'DESC';

Schedulable.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'reservations',
    relatedModel: 'FW.Models.SchedulableReservation',
    collectionType: 'FW.Models.SchedulableReservations',
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'group',
    relatedModel: 'FW.Models.SchedulableGroup'
  }
];

export {Schedulable, Schedulables};
