import React from 'react';
import './CookiePrompt.scss';

export default function CookiePrompt({close, accept}) {

  const cookiePromptClass = window.location.href.includes('/forms/') && window.location.href.includes('/embed') ? 'cookie-prompt at-top' : 'cookie-prompt';

  return <div className={cookiePromptClass}>
    <div className="cookie-body">
      <span className="cookie-text">We use cookies to improve your experience and to collect analytical data about how you use our website. This helps us understand user behavior and make necessary improvements. By continuing to use this website, you consent to our use of cookies. Essential cookies are always active. Read our website's <a href="/cookie-policy">Cookie Policy</a></span>
      <div className="cookie-buttons">
        <span className="btn btn-primary" onClick={accept} style={{marginRight: 20}}>Accept Cookies</span>
        <span className="btn btn-default" style={{fontWeight: 'bold'}} onClick={close}>REJECT</span>
      </div>
    </div>
  </div>

}
