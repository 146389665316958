import React from 'react';
import Download from '@material-ui/icons/CloudUpload';
import Fab from '@material-ui/core/Fab';

class AdminImportButton extends React.Component {
  render() {
    return (
      <Fab
        title="Import"
        color="primary"
        aria-label="Add"
        style={{position: 'absolute', right: 90, bottom: 20}}
        {...this.props}
      >
        <Download />
      </Fab>
    );
  }
}

export default AdminImportButton;
