import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import {MediaSeriesCollection} from './MediaSeries';

class FileCategory extends Model {
  get modelUrl() {
    return '/file-categories';
  }

  get defaults() {
    return {
      type: 'file_category'
    };
  }


  static searchKeys = [
    'title'
  ];

}
FileCategory.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  }
];

class FileCategories extends Collection {
  getExtraModelArgs() {
    return {site_id: FW.store.get('site').get('id')};
  }
}
FileCategories.prototype.model = FileCategory;
FileCategories.prototype.sortField = 'title';
FileCategories.prototype.sortOrder = 'ASC';

export {
  /**
   * The blog model
   */
  FileCategory,
  /**
   * Collection of Blog models
   */
  FileCategories
};
