import React from 'react';
import GroupBackground from './GroupBackground';

const OVERLAP_MIN_WIDTH = 650;

class OverlapGroupView extends React.Component{

  static getSideMargins(value){
    if (!value)
      value = 0;
    let complement = 100 - parseInt(value) * 2;
    value = value + '%';
    let width = complement + '%';
    const windowWidth = window.innerWidth;
    if (windowWidth >= OVERLAP_MIN_WIDTH) {
      return {
        width: width,
        marginLeft: value
      };
    } else {
      return {
        width: '100%',
        marginLeft: 0
      };
    }
  }

  static getTopMargin(value) {
    if (!value)
      value = 0;
    value = value + 'px';
    const width = window.innerWidth;
    if (width >= OVERLAP_MIN_WIDTH) {
      return {
        marginTop: value
      };
    } else {
      return {
        marginTop: 0
      };
    }
  }

  static getDefaultStyles(group){
    return {
      overflow: 'visible',
      left: 0,
      zIndex: 100,
      padding: 0,
      minHeight: group.ls('min_height'),
      paddingTop: group.getPadding(),
      ...OverlapGroupView.getSideMargins(group.ls('side_margins')),
      ...OverlapGroupView.getTopMargin(group.ls('margin_top'))
    };
  }

  render(){
    let group = this.props.group;
    return <div
      className="container-fluid overlap-group fus-section"
      style={OverlapGroupView.getDefaultStyles(group)}
    >
      <GroupBackground group={group} />
      <div className="row group-section-holder overlap-section-holder">
        {this.props.children}
      </div>
    </div>;
  }

}

export default OverlapGroupView;
