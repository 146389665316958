import React from 'react';
import './fullOverlay.scss';
import InlineEditableElement from '../../../Components/InlineEditableElement';
import {File as FWFile} from '../../../FW/Models/File';

class FullOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineEditingEnabled: props.inlineEditingEnabled,
      content: props.content
    };
  }

  close = () => {
    this.props.onClose();
  };

  contentChanged = html => {
    const overlay = this.props.section.prop('overlay');
    overlay.content = html;
    this.props.section.prop('overlay', overlay);
    this.setState({
      content: html
    });
  };

  setInlineEditing = value => {
    this.setState({
      inlineEditingEnabled: value
    });
  };

  setContent = content => {
    this.setState({
      content
    });
  };

  render() {
    const section = this.props.section;
    const image = section.prop('image');

    const imageTitle = image.title;
    const imageAlt = image.alt;
    const url = image.src
      ? FWFile.findOrCreate({id: image.src}).helper.thumbnailUrl('optimized')
      : null;
    const imageUrl = url ? url : image.src;
    const content = section.prop('content');

    return (
      <div id={'fullOverlayContainer'}>
        <img
          id={'featuredImage'}
          src={imageUrl}
          alt={imageAlt}
          title={imageTitle}
        />
        <h1 id={'title'} dangerouslySetInnerHTML={{__html: this.props.title}} />
        <p
          id={'featureBoxContent'}
          dangerouslySetInnerHTML={{__html: content}}
        />
        <div id={'content'}>
          <InlineEditableElement
            onChange={this.contentChanged}
            {...this.props.inlineEditorProps}
            inlineEditingEnabled={this.state.inlineEditingEnabled}
            fontFamilyChanged={this.props.fontFamilyChanged.bind(this)}
            html={this.state.content}
          />
        </div>
        <a id={'closeButton'} onClick={this.close}>
          <i className={'fa fa-window-close'} />
        </a>
      </div>
    );
  }
}

export default FullOverlay;
