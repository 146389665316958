import React from 'react';
import PayPalDisplay from './PayPalDispay';
import ContinueToGive from './ContinueToGive';
import Cornerstone from './Cornerstone';
import SKDonationButton from '../ServantKeeper/DonationButton';

class Donate extends React.Component {

  constructor(props) {
    super(...arguments);
  }

  render() {
    const value = this.props.section.get('properties').options;
    return <div>
      {value.donation_type === 'paypal' || (typeof value.donation_type === 'undefined' && !FW.isSkCust()) ? <PayPalDisplay value={value} /> : null}
      {value.donation_type === 'continueToGive' ? <ContinueToGive value={value} /> : null}
      {value.donation_type === 'cornerstone' ? <Cornerstone value={value} /> : null}
      {value.donation_type === 'servant-keeper' || (typeof value.donation_type === 'undefined' && FW.isSkCust()) ? <SKDonationButton {...this.props} /> : null}
    </div>;
  }

}

export default Donate;
