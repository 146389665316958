import {Model} from './Model';
import {Collection} from './Collection';

class AppPage extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/app-pages'; }

  /**
   */
  get defaults(){
    return {
      type: 'app_page'
    };
  }

}

class AppPages extends Collection{}
AppPages.prototype.model = AppPage;

AppPage.prototype.relations = [];

export {AppPage, AppPages};
