import React from 'react';

const ModalButtons = function({onCancel, hideSave, onSave, children, saveDisabled, saveText, cancelText, relativeButtons}) {
  let buttonsStyles = {
    position: relativeButtons ? 'relative' : 'absolute',
    bottom: '0px',
    paddingBottom: '10px',
    width: '100%',
    textAlign: 'center'
  };

  const buttons = <>
    <span className="btn btn-default" onClick={onCancel}>
      {cancelText || 'Cancel'}
    </span>
    {!hideSave ? <span className="btn btn-primary" onClick={onSave} disabled={saveDisabled}>
      {saveText || 'Save'}
    </span> : null}
  </>;

  return (
    <div className="row" style={{height: 80}}>
      <div style={buttonsStyles}>
        <div className="btn-group">{children || buttons}</div>
      </div>
    </div>
  );
}

export {ModalButtons};
