import FileHelper from './FileHelper';

export default class Image extends FileHelper {
  get webUrl() {
    if (this._file.get('processed') === 1 && this._file.get('extension') !== 'gif' && this._file.get('extension') !== 'svg' && this._file.get('location') !== 'remote') {
      return this.thumbnailUrl(2880);
    }
    return this._file.originalUrl();
  }
}
