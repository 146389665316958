import {Model} from './Model';
import _ from 'underscore';
import {Helper} from 'Helpers';

class TemplateSetting extends Model {
  get modelUrl() {
    return '/template-settings';
  }

  get defaults() {
    return {
      type: 'template_setting'
    };
  }

  get excludeFromJSON() {
    return ['template'];
  }

  setting(key, value) {
    let path = key.split('.');
    let settings = this.get('settings'),
      v = settings;
    for (let i = 0; i < path.length; i++) {
      v = v[path[i]];
    }

    if (typeof value !== 'undefined') {
      while (path.length > 1) settings = settings[path.shift()];
      settings[path.shift()] = value;

      //TODO: find a way to do this without setting the attribute twice.
      let newValue = _.clone(this.get('settings'));
      this.set('settings', null);
      return this.set('settings', newValue);
    }

    return v;
  }

  getContents() {
    return new Promise(resolve => {
      let $ =
        window.name === 'editFrame' ? window.$ : FW.editor.getIframeWindow().$;
      if (!this.contents) {
        let files = [];
        $('link[data-template-css="true"]').each((i, file) => {
          let href = $(file).attr('href');
          files.push(href);
        });

        this.contents = [];
        for (let i in files) {
          $.ajax({
            url: files[i],
            success: data => {
              this.contents.push(data);
              if (this.contents.length === files.length) {
                resolve();
              }
            }
          });
        }
      }
    });
  }

  renderColors() {
    let $ =
      window.name === 'editFrame' ? window.$ : FW.editor.getIframeWindow().$;
    if (!this.contents) {
      this.getContents().then(() => {
        this.renderColors();
      });
      return;
    }
    $('.theme-sheet').remove();
    for (let contents in this.contents) {
      contents = this.contents[contents];
      let colors = this.get('settings').colors;

      for (let i in colors) {
        let regex = new RegExp('---' + i + '---', 'g');
        let v = colors[i].value;
        if (typeof v === 'object') {
          v = Helper.rgbObjectToString(v);
        }
        contents = contents.replace(regex, v);
      }
      $('<style />')
        .addClass('theme-sheet')
        .html(contents)
        .appendTo('head');
      if (this.get('settings').css)
        $('<style />')
          .addClass('custom-css')
          .html(this.get('settings').css)
          .appendTo('head');
    }
  }

  rollback() {
    super.rollback();
    this.renderColors();
  }
}

export {TemplateSetting};
