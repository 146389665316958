import {Model} from './Model';
import {Collection} from './Collection';

class PageSectionTemplateLayout extends Model {
  get modelUrl() {
    return '/page-section-template-layouts';
  }

  get defaults() {
    return {
      type: 'page_section_template_layout'
    };
  }
}
class PageSectionTemplateLayouts extends Collection {}
PageSectionTemplateLayouts.prototype.model = PageSectionTemplateLayout;

export {PageSectionTemplateLayouts, PageSectionTemplateLayout};
