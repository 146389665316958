import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';

class SchedulableReservation extends Model {

  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/schedulable-reservations';
  }

  /**
   */
  get defaults() {
    return {
      type: 'schedulable_reservation'
    };
  }

}

class SchedulableReservations extends Collection {
}

SchedulableReservations.prototype.model = SchedulableReservation;
SchedulableReservations.prototype.sortField = 'start_time';
SchedulableReservations.prototype.sortOrder = 'ASC';

SchedulableReservation.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'schedulable',
    relatedModel: 'FW.Models.Schedulable',
    reverseRelation: {
      type: Backbone.Relational.HasMany,
      key: 'schedulable_groups'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'overlaps',
    relatedModel: 'FW.Models.SchedulableReservationOverlap',
    reverseRelation: {
      type: Backbone.Relational.HasOne,
      key: 'reservation_1'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'overlapped_by',
    relatedModel: 'FW.Models.SchedulableReservationOverlap',
    reverseRelation: {
      type: Backbone.Relational.HasOne,
      key: 'reservation_2'
    }
  }
];

export {SchedulableReservation, SchedulableReservations};
