import React from 'react';
import ContinueToGiveBadge from './ContinueToGive/ContinueToGiveBadge';
import ContinueToGiveBadgeWithDescription from './ContinueToGive/ContinueToGiveBadgeWithDescription';
import ContinueToGiveBadgeSmall from './ContinueToGive/ContinueToGiveBadgeSmall';
import ContinueToGiveBadgeWithComments from './ContinueToGive/ContinueToGiveBadgeWithComments';
import ContinueToGiveBadgeForm from './ContinueToGive/ContinueToGiveBadgeForm';
import ContinueToGivePopup from './ContinueToGive/ContinueToGivePopup';
import ContinueToGiveStickyButton from './ContinueToGive/ContinueToGiveStickyButton';

export default function ContinueToGive({value}) {
  if (value.display_type === 'badge') {
    return <ContinueToGiveBadge value={value} />
  } else if (value.display_type === 'badgeWithDescription') {
    return <ContinueToGiveBadgeWithDescription value={value} />
  } else if (value.display_type === 'smallBadge') {
    return <ContinueToGiveBadgeSmall value={value} />
  } else if (value.display_type === 'badgeWithComments') {
    return <ContinueToGiveBadgeWithComments value={value} />
  } else if (value.display_type === 'inlineForm') {
    return <ContinueToGiveBadgeForm value={value} />
  } else if (value.display_type === 'popupWidget') {
    return <ContinueToGivePopup value={value} />
  } else if (value.display_type === 'stickyButton') {
    return <ContinueToGiveStickyButton value={value} />
  }

  return <div />;
}
