import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';
import {FormResponse, FormResponses} from './FormResponse';
import {FormField, FormFields} from './FormField';
import {layoutParent} from './Decorators/Layoutable';
import prop from './Decorators/Prop';
import $ from 'jquery';
import config from '../../config';
import _ from 'underscore';

@layoutParent('fields')
@prop
class Form extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/forms';
  }

  get defaults() {
    return {
      type: 'form'
    };
  }

  static searchKeys = ['title', 'submission_email'];

  get includedRelations() {
    return [];
  }

  hasCurrencyFields() {
    let hasCurrency = false;
    this.get('fields').each(field => {
      if (field.hasCurrency()) {
        hasCurrency = true;
      }
    });
    return hasCurrency;
  }

  serializeAttributes() {
    let def = {};
    def.title = this.get('title');
    def.fields = this.get('fields').models.map(field => {
      return {
        field_type: field.get('field_type'),
        title: field.get('title'),
        properties: field.get('properties'),
        field_options: field.get('field_options').map(opt => {
          return {
            name: opt.get('name'),
            value: opt.get('value'),
            selected: opt.get('selected'),
            currency_value: opt.get('currency_value')
          };
        })
      };
    });
    return JSON.stringify(def);
  }

  copy(opts) {
    const id = this.get('id');
    if (parent) {
      parent.$u.loading();
    } else {
      $u.loading();
    }
    return new Promise(complete => {
      $.post(config.API_ROOT + '/forms/' + id + '/copy', opts).then(d => {
        const newForm = new Form(d.form);
        if (parent) {
          parent.$u.loading(true);
        } else {
          $u.loading(true);
        }
        complete(newForm);
      });
    });
  }

  static testUnserialize() {
    Form.unserialize({
      title: 'Contact',
      fields: [
        {
          field_type: 'Input',
          title: 'Name',
          properties: {
            hintText: '',
            grid_layouts: {
              lg: {
                w: 12,
                h: 2,
                x: 0,
                y: 0,
                i: 'field-a7e5f03a-be7c-11e7-ab7a-bc5436d1038c',
                minW: 1,
                minH: 1,
                moved: false,
                static: false
              },
              sm: {
                i: 'field-a7e5f03a-be7c-11e7-ab7a-bc5436d1038c',
                x: 0,
                y: 0,
                w: 6,
                h: 2,
                minH: 1,
                minW: 1
              },
              xs: {
                i: 'field-a7e5f03a-be7c-11e7-ab7a-bc5436d1038c',
                x: 0,
                y: 0,
                w: 2,
                h: 2,
                minH: 1,
                minW: 1
              }
            }
          },
          id: 'a7e5f03a-be7c-11e7-ab7a-bc5436d1038c',
          field_options: []
        },
        {
          field_type: 'Email',
          title: 'E-mail Address',
          properties: {
            hintText: 'example@domain.com',
            grid_layouts: {
              lg: {
                w: 12,
                h: 2,
                x: 0,
                y: 2,
                i: 'field-adf7f52c-be7c-11e7-9e50-bc5436d1038c',
                minW: 1,
                minH: 1,
                moved: false,
                static: false
              },
              sm: {
                i: 'field-adf7f52c-be7c-11e7-9e50-bc5436d1038c',
                x: 0,
                y: 1,
                w: 6,
                h: 2,
                minH: 1,
                minW: 1
              },
              xs: {
                i: 'field-adf7f52c-be7c-11e7-9e50-bc5436d1038c',
                x: 0,
                y: 1,
                w: 2,
                h: 2,
                minH: 1,
                minW: 1
              }
            }
          },
          id: 'adf7f52c-be7c-11e7-9e50-bc5436d1038c',
          field_options: []
        },
        {
          field_type: 'Input',
          title: 'Phone Number',
          properties: {
            hintText: 'XXX-XXX-XXXX',
            grid_layouts: {
              lg: {
                w: 12,
                h: 2,
                x: 0,
                y: 4,
                i: 'field-b07521e4-be7c-11e7-aaa1-bc5436d1038c',
                minW: 1,
                minH: 1,
                moved: false,
                static: false
              },
              sm: {
                i: 'field-b07521e4-be7c-11e7-aaa1-bc5436d1038c',
                x: 0,
                y: 2,
                w: 6,
                h: 2,
                minH: 1,
                minW: 1
              },
              xs: {
                i: 'field-b07521e4-be7c-11e7-aaa1-bc5436d1038c',
                x: 0,
                y: 2,
                w: 2,
                h: 2,
                minH: 1,
                minW: 1
              }
            }
          },
          id: 'b07521e4-be7c-11e7-aaa1-bc5436d1038c',
          field_options: []
        },
        {
          field_type: 'TextArea',
          title: 'Message',
          properties: {
            grid_layouts: {
              lg: {
                w: 12,
                h: 5,
                x: 0,
                y: 6,
                i: 'field-ba806004-be7c-11e7-990d-bc5436d1038c',
                minW: 1,
                minH: 1,
                moved: false,
                static: false
              },
              sm: {
                i: 'field-ba806004-be7c-11e7-990d-bc5436d1038c',
                x: 0,
                y: 3,
                w: 6,
                h: 7,
                minH: 1,
                minW: 1
              },
              xs: {
                i: 'field-ba806004-be7c-11e7-990d-bc5436d1038c',
                x: 0,
                y: 3,
                w: 2,
                h: 7,
                minH: 1,
                minW: 1
              }
            }
          },
          id: 'ba806004-be7c-11e7-990d-bc5436d1038c',
          field_options: []
        }
      ]
    });
  }

  static unserialize(attributes) {
    const form = new Form();
    form.save(
      {site_id: FW.store.get('site').get('id'), attributes: attributes},
      {
        success: () => {
          return form;
        }
      }
    );
  }

  getTaxValue() {
    if (this.get('tax_rate')) {
      const rate = this.get('tax_rate') / 100;
      return this.getSelectedValue() * rate;
    }
    return 0;
  }

  getTotalSurcharge() {
    let total = 0;
    _.each(this.getSurchargeValues(), (v) => {
      total += v;
    });
    return total;
  }

  getSurchargeFields() {
    return this.get('fields').where({
      field_type: 'Surcharge'
    });
  }

  chargeHandlingFee(){
    const handlingField = this.get('fields').where({
      field_type: 'Handling'
    });
    return handlingField[0] ? handlingField[0].isChecked() : false;
  }

  getSurchargeValues() {
    const fields = this.getSurchargeFields();
    let values = [];
    _.each(fields, (field) => {
      values.push(field.getSurchargeValue());
    });
    return values;
  }

  hasSurcharges() {
    return this.get('fields').where({
      field_type: 'Surcharge'
    }).length > 0
  }

  surchargeOnlineOnly(){
    const fields = this.get('fields').where({
      field_type: 'Surcharge'
    });
    let onlineOnly = false;
    fields.forEach((f) => {
      if (f.prop('pay_online_only')) {
        onlineOnly = true;
      }
    });
    return onlineOnly;
  }


  getSelectedValue() {
    let value = 0;
    this.get('fields').each(field => {
      value += field.getSelectedValue();
    });

    return value;
  }

  getTotalValue(taxed = false) {
    const tax = taxed ? this.getTaxValue() : 0;
    return this.getSelectedValue() + tax + this.getTotalSurcharge();
  }

}

class Forms extends Collection {}

Forms.prototype.model = Form;

Form.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'responses',
    relatedModel: FormResponse,
    collectionType: FormResponses,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'fields',
    relatedModel: FormField,
    collectionType: FormFields,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'form',
      includeInJSON: false
    }
  }
];

export {Form, Forms};
