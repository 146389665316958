import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import moment from 'moment';
import 'moment-timezone';

class EventInstance extends Model {
  get modelUrl() {
    return '/event_instances';
  }

  get defaults() {
    return {
      type: 'event_instance'
    };
  }

  get displayDate() {
    const e = this.get('event');
    const startTime = moment.tz(this.get('start_time'), e.get('timezone') ?? FW.store.get('customer').get('timezone'));
    const endTime = moment.tz(this.get('end_time'), e.get('timezone') ?? FW.store.get('customer').get('timezone'));
    if (this.get('end_time') === null) {
      return startTime.format('MMMM Do, yyyy h:mma');
    } else if (startTime.isSame(endTime, 'day')) {
      return startTime.format('MMMM Do, yyyy') + ' from ' + startTime.format('h:mma') + ' - ' + endTime.format('h:mma z');
    } else {
      return startTime.format('MMMM Do, yyyy h:mma') + ' through ' + endTime.format('MMMM Do, yyyy h:mma z');
    }
  }

  static searchKeys = ['description', 'title'];
}
EventInstance.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'event',
    relatedModel: 'FW.Models.Event',
    reverseRelation: {
      key: 'instances'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'featured_image',
    relatedModel: 'FW.Models.File'
  },
];

class EventInstances extends Collection {}

EventInstances.prototype.sortField = 'start_time';
EventInstances.prototype.sortOrder = 'ASC';
EventInstances.prototype.model = Event;

export {
     /**
     * The blog model
     */
     EventInstance,
     /**
     * Collection of Blog models
     */
     EventInstances
};
