import {Model} from './Model';
import {Collection} from './Collection';

class VolunteerEvent extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/volunteer-events';
  }

  /**
   */
  get defaults() {
    return {
      type: 'volunteer_event'
    };
  }

}

class VolunteerEvents extends Collection {}

VolunteerEvents.prototype.model = VolunteerEvent;
VolunteerEvents.prototype.sortField = 'created_at';
VolunteerEvents.prototype.sortOrder = 'DESC';

VolunteerEvent.prototype.relations = [];

export {VolunteerEvent, VolunteerEvents};
