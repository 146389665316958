import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {UserPermission, UserPermissions} from './UserPermission';
import hasPermissions from './Decorators/HasPermissions';

@hasPermissions
class UserGroup extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/user-groups';
  }

  get includedRelations() {
    return ['user_permission'];
  }

  get effectivePermissionDetails() {
    return this.get('permission_details') || {};
  }

  /**
   */
  get defaults() {
    return {
      type: 'user_group'
    };
  }
}
UserGroup.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'permissions',
    relatedModel: UserPermission,
    collectionType: UserPermissions,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'users',
    relatedModel: 'FW.Models.User',
    collectionType: 'FW.Models.Users',
    collectionOptions: Model.defaultCollectionOptions
  }
];
class UserGroups extends Collection {}
UserGroups.prototype.model = UserGroup;

export {UserGroup, UserGroups};
