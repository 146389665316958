import {Model} from './Model';
import {Collection} from './Collection';
import _ from 'underscore';
import config from '../../config';

class TpegUrl extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/tpeg-urls'; }

  /**
   */
  get defaults(){
    return {
      type: 'tpeg_url'
    };
  }

  static replaceString(str) {
    let tpegd = FW.store.get('tpegd');
    let tpeg = FW.store.get('tpeg');
    if (str && (tpeg || tpegd)) {
      tpeg = tpeg || {get:()=>{}}
      tpegd = tpegd || {get:()=>{}}

      const tpegFields = [
        'first_name',
        'last_name',
        'email',
        'title',
        'twitter',
        'facebook',
        'instagram',
        'linkedin',
        'youtube',
        'bustoolbox',
        'solar_id',
        'nwc',
        'tpegwv_title',
        'id',
        'agent_id',
        'ipc_referral',
        'coinzoom_referral',
        'amazon_affiliate_link',
        'ebay_affiliate_link',
        'walmart_affiliate_link',
        'best_buy_affiliate_link'
      ]

      _.each(tpegFields, (field) => {
        const newValue = tpeg.get(field) || tpegd.get(field) || '';
        str = str.replace("{{tpeg_" + field + "}}", newValue);
      });
      return str;
    }
    return str;
  }

  approve(){
    return $.post(config.API_ROOT + '/tpeg-urls/' + this.get('id') + '/approve').then((r) => {
      console.log('RESULT: ', r);
    });
  }

}

class TpegUrls extends Collection{}
TpegUrls.prototype.model = TpegUrl;
TpegUrls.prototype.sortField = 'created_at';
TpegUrls.prototype.sortOrder = 'DESC';

export {TpegUrl, TpegUrls};
