import React from 'react';
import ContentAdd from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

class AdminAddButton extends React.Component {
  render() {
    return (
      <Fab
        color="primary"
        aria-label="Add"
        style={{position: 'absolute', right: 20, bottom: 20}}
        {...this.props}
        data-cy="Add"
      >
        <ContentAdd />
      </Fab>
    );
  }
}

export default AdminAddButton;
