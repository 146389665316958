import React from 'react';
import Download from '@material-ui/icons/CloudDownload';
import Fab from '@material-ui/core/Fab';

class AdminExportButton extends React.Component {
  render() {
    return (
      <Fab
        title="Export"
        color="primary"
        aria-label="Export"
        style={{position: 'absolute', right: 90, bottom: 20}}
        {...this.props}
      >
        <Download />
      </Fab>
    );
  }
}

export default AdminExportButton;
