import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from 'FWBackbone';

class ChatMessage extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/chat-messages';
  }

  /**
   */
  get defaults() {
    return {
      type: 'chat_message'
    };
  }
}

class ChatMessages extends Collection {}
ChatMessages.prototype.model = ChatMessage;
ChatMessages.prototype.sortField = 'created_at';
ChatMessages.prototype.sortOrder = 'DESC';

ChatMessage.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'user',
    relatedModel: 'FW.Models.User',
    reverseRelation: {
      type: Backbone.Relational.HasMany,
      key: 'messages'
    }
  }
];

export {ChatMessage, ChatMessages};
