import React, {useEffect, useState} from 'react';
import ContinueToGivePopup from './ContinueToGivePopup';

export default function ContinueToGiveStickyButton({value}) {
  const position = value.display_position || 'top_left';
  const positions = position.split('_');

  let options = {
    alignment: positions[1],
    position: positions[0],
    backgroundBottom: value.secondary_color || '#a0c844',
    backgroundTop: value.primary_color || '#ffffff',
    fontColor: '#000000',
    buttonText: 'Donate Now',
    fontSize: value.font_size || '14',
    fontFamily: value.font_family || 'arial',
    hPadding: '15',
    vPadding: '10',
    borderRadius: '15',
    borderSize: '0',
    borderColor: '#000000',
  };
  return <ContinueToGivePopup
    value={value}
    stickyButton={options}
  />;
}
