import _ from 'underscore';
import {Helper} from 'Helpers';
import {File as FWFile} from 'FW/Models/File';
import {Backbone} from '../../../FWBackbone';
import moment from 'moment';

class FileHelper {
  constructor(file) {
    _.extend(this, Backbone.Events);
    this._file = file;
    this.featuredImage = null;
    this.thumbnailLoaded = true;
    if (file.get('featured_image_file_id')) {
      this.featuredImage = FWFile.findOrCreate({
        id: file.get('featured_image_file_id')
      });
      if (!this.featuredImage.isLoaded()){
        this.thumbnailLoaded = false;
        this.featuredImage.ensureLoaded({
          success: () => {
            this.thumbnailFinishedLoading();
          }
        });
      }
    }
    //this._file.ensureLoaded();
  }

  get webUrl() {
    return this._file.originalUrl();
  }

  loadThumbnail(file) {
    if (!file && this.thumbnailLoaded) {
      this.thumbnailFinishedLoading();
      return;
    }
    if (this._file.get('featured_image_file_id')) {
      this.thumbnailLoaded = false;
      if (file && file.get('created_at')) {
        this.featuredImage = file;
        this.featuredImage.fetch({
          success: () => {
            this.thumbnailFinishedLoading();
          }
        });
      } else
        this.featuredImage = FWFile.findOrCreate({
          id: this._file.get('featured_image_file_id')
        });
      if (!this.featuredImage.get('created_at'))
        this.featuredImage.fetch({
          success: () => {
            this.thumbnailFinishedLoading();
          }
        });
    }
  }

  hasThumbnail() {
    return !!this._file.get('featured_image_file_id');
  }

  thumbnailFinishedLoading() {
    this.thumbnailLoaded = true;
    this.trigger('thumbnailLoaded');
  }

  featuredUrl(size) {
    size = size || 150;
    let file = this._file;
    if (!file.get('featured_image_file_id')) {
      if (file.get('location') !== 'remote' && !file.get('uploaded'))
        return null;

      if (this._file.fileType === 'video' && file.get('processed')) {
        return this.thumbnailUrl(size);
      } else if (this._file.fileType === 'image') {
        return this.thumbnailUrl(size);
      } else {
        if (file.get('media_series') && file.get('media_series').length > 0) {
          const firstSeries = file.get('media_series').first();
          const cover = firstSeries.get('cover');
          // protect against recursion
          if (cover && file && cover.get('id') === file.get('id')) {
            console.log('Recursive cover file. Cancelling...', file);
            return null;
          }
          return cover ? cover.helper.featuredUrl(size) : null;
        } else if (
          file.get('media_libraries') &&
          file.get('media_libraries').length > 0
        ) {
          const firstLibrary = file.get('media_libraries').first();
          const cover = firstLibrary.get('cover');
          // protect against recursion
          if (cover && file && cover.get('id') === file.get('id')) {
            console.log('Recursive cover file. Cancelling...', file);
            return null;
          }
          return cover ? cover.helper.featuredUrl(size) : null;
        } else {
          return null;
        }
      }
    }

    if (!this.featuredImage || !this.featuredImage.helper) return null;
    return this.thumbnailLoaded
      ? this.featuredImage.helper.thumbnailUrl(size)
      : null;
  }

  thumbnailUrl(size) {
    if (typeof size === 'undefined') {
      size = 'optimized';
    }
    if (!this._file.get('processed') || this._file.get('extension') === 'gif' || this._file.get('extension') === 'svg' || this._file.get('location') === 'remote') {
      return this.webUrl;
    }

    let sizes = [150, 300, 800, 900, 1280, 1800, 2880];
    const MAX_SIZE = sizes[sizes.length - 1];
    let screenVar = screen || {width: 2800, height: 2800};
    if (size === 'optimized') {
      let direction =
        screenVar.width > screenVar.height ? 'horizontal' : 'vertical';
      let metric;
      if (direction === 'horizontal') {
        metric = window.innerWidth > 0 ? window.innerWidth : screenVar.width;
      } else {
        metric = window.innerHeight > 0 ? window.innerHeight : screenVar.height;
      }
      if (Helper.isRetina()) {
        metric = metric * window.devicePixelRatio;
      }

      _.every(sizes, s => {
        if (metric < s && size === 'optimized') {
          size = s;
          return false;
        } else if (s > parseInt(size, 10)) {
          size = s;
        }
        return true;
      });
      if (size === 'optimized') {
        size = 2880;
      }
    } else {
      let found = false;
      if (Helper.isRetina()) {
        size = Math.min(MAX_SIZE, size * window.devicePixelRatio);
      }
      _.each(sizes, s => {
        if (s >= size && !found) {
          size = s;
          found = true;
        }
      });
    }

    if (isNaN(size)){
      size = 2800;
    }

    let ext =
      this._file.fileType === 'video' ? 'jpg' : this._file.get('extension');

    let originalExtension = '&original_extension=' + ext;
    ext = global.webp ? (this._file.get('high_quality_thumbnails') ? ext : 'webp') : ext;

    let suffix = '?t=' + moment.utc(this._file.get('updated_at')).format('X') + originalExtension;
    return this._file.getS3FolderUrl() + '/_thumbnails/' + size + '.' + ext + suffix;
  }
}

export default FileHelper;
