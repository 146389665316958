import React from 'react';
import {File as FWFile} from 'FW/Models/File';
import {Helper} from '../../../Helpers';
import 'jquery.appear';

class ImageView extends React.Component{

  constructor(props){
    super(...arguments);
    this.state = this.getStateFromProps(props);
    this.imageRef = React.createRef();
  }

  getStateFromProps(props){
    let section = props.section;
    let details = section.prop('file_details');
    let file;
    if (details.type === 'local')
      file = details.src ? FWFile.findOrCreate({id: details.src}) : null;
    let state = {
      section: props.section,
      src: details.src,
      file: file,
      url: file ? null : details.src
    };
    return state;
  }

  componentDidMount() {
    const section = this.props.section;
    const scrollAnimation = section.getAnimationClass('scroll');
    if (scrollAnimation) {
      const toBeAnimated = $(`#image-${section.get('id')}`);
      toBeAnimated.appear(() => {
        toBeAnimated.addClass(scrollAnimation);
        toBeAnimated.one('webkitAnimationEnd oanimationend msAnimationEnd animationend',
          () => {
            toBeAnimated.removeClass(scrollAnimation);
          });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps){
    this.setState(this.getStateFromProps(newProps));
  }

  onClick(e) {
    if (FW.inEditor()) {
      //e.preventDefault();
    }
  }

  componentDidUpdate(){
    if(!this.props.section.prop('do_not_snap_to_grid')) {
      clearTimeout(this.processTimeout);
      this.processTimeout = setTimeout(() => {
        this.imageRef.current.style.maxWidth = '100%';
        let ratio = this.imageRef.current ? this.props.size.width / this.imageRef.current.height : 1;
        let multiplier = Math.ceil(this.imageRef.current.height / 30);
        let targetHeight = (multiplier * 30) - 1;
        let targetWidth = Math.ceil(targetHeight * ratio);
        let adjustedWidth = Math.ceil(targetWidth - this.props.size.width);
        this.imageRef.current.style.maxWidth = 'calc(100% + ' + adjustedWidth + 'px)';
        this.imageRef.current.style.marginLeft = '-' + adjustedWidth / 2 + 'px';
      }, 100);
    }
  }

  render(){
    let section = this.state.section;
    let border = section.ls('border') || {};
    let details = section.prop('file_details');
    let url = this.state.url || this.state.file ? this.state.url : this.state.src;
    if (!url){
      if (this.props.editor){
        url = this.state.file ? this.state.file.helper.thumbnailUrl('optimized') : null;
      } else {
        url = this.state.file ? this.state.file.helper.thumbnailUrl(this.props.size.width) : null;
      }
    }
    let style = {
      border: border.border,
      borderRadius: border.borderRadius,
      maxWidth: '100%',
      margin: '0 auto',
      borderStyle: 'solid',
      padding: details.padding + 'px'
    };

    let TagName = this.props.section.prop('link') ? 'a' : 'div';
    let href = this.props.section.prop('link') ? Helper.fixHref(this.props.section.prop('link')) : null;
    let target = typeof this.props.section.prop('link_target') !== 'undefined' && this.props.section.prop('link_target')  !== '_self' ? {
      target: this.props.section.prop('link_target')
    } : {};

    return (
      <TagName id={`image-${this.props.section.get('id')}`} {...target} href={href} onClick={this.onClick.bind(this)} style={{textAlign: 'center', padding: '0px', display: 'block', overflow: 'hidden'}} className={this.props.section.getAnimationClass('load')}>
        <img
          ref={this.imageRef}
          style={style}
          src={url}
          title={details.title}
          alt={details.alt}
        />
      </TagName>
    );
  }

}

export default ImageView;
