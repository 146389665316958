// @flow
import config from './config';
import clone from 'clone';
import {TpegUrl} from './FW/Models/TpegUrl';
import browserizr, {isMobile, isIOS, isMacOS, isSafari, isIE, isEdge, isIPad} from '@wezom/browserizr';
import _ from 'underscore';

let $ = jQuery;

let trim = function(str) {
  return str.replace(/^\s+|\s+$/gm, '');
};

window.cookiesAllowed = function() {
  return (typeof FW.store.get('site') !== 'undefined' && FW.store.get('site').prop('cookiePromptDisabled')) || (Cookies && Cookies.get('cookies-accepted') === 'true');
}

/**
 * Helper
 * @namespace
 * @type {{overElement: Function, getElementOffset: Function}}
 */
class Helper {

  static inWebView(){
    var standalone = window.navigator.standalone,
      userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test( userAgent ),
      ios = /iphone|ipod|ipad/.test( userAgent );

    if( ios ) {
      if ( !standalone && safari ) {
        //browser
      } else if ( standalone && !safari ) {
        //standalone
      } else if ( !standalone && !safari ) {
        //uiwebview
        return true;
      };
    } else {
      //not iOS
    };
    return false;
  }

  static toUpperCase(str) {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
  }

  static getStatesArray() {
    return {'AL':'Alabama','AK':'Alaska','AS':'American Samoa','AZ':'Arizona','AR':'Arkansas','CA':'California','CO':'Colorado','CT':'Connecticut','DE':'Delaware','DC':'District Of Columbia','FM':'Federated States Of Micronesia','FL':'Florida','GA':'Georgia','GU':'Guam','HI':'Hawaii','ID':'Idaho','IL':'Illinois','IN':'Indiana','IA':'Iowa','KS':'Kansas','KY':'Kentucky','LA':'Louisiana','ME':'Maine','MH':'Marshall Islands','MD':'Maryland','MA':'Massachusetts','MI':'Michigan','MN':'Minnesota','MS':'Mississippi','MO':'Missouri','MT':'Montana','NE':'Nebraska','NV':'Nevada','NH':'New Hampshire','NJ':'New Jersey','NM':'New Mexico','NY':'New York','NC':'North Carolina','ND':'North Dakota','MP':'Northern Mariana Islands','OH':'Ohio','OK':'Oklahoma','OR':'Oregon','PW':'Palau','PA':'Pennsylvania','PR':'Puerto Rico','RI':'Rhode Island','SC':'South Carolina','SD':'South Dakota','TN':'Tennessee','TX':'Texas','UT':'Utah','VT':'Vermont','VI':'Virgin Islands','VA':'Virginia','WA':'Washington','WV':'West Virginia','WI':'Wisconsin','WY':'Wyoming'};
  }

  static getLastSixMonths() {
    const months = [];
    const today = new Date();

    for (let i = 0; i < 6; i++) {
      const date = new Date(today);
      date.setMonth(today.getMonth() - i);

      const month = date.getMonth() + 1; // JavaScript months are 0-indexed
      const year = date.getFullYear().toString();
      months.push(`${month}-${year}`);
    }

    return months;
  }

  static setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  static getWithExpiry(key) {
    let itemStr;
    try {
      itemStr = localStorage.getItem(key)
    } catch (e) {
      return null;
    }
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  static uploadFiles(files, options) {
    let fd = new FormData();
    _.each(files, (file, i) => {
      fd.append('file_data_' + i, file);
    });
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      if (xhr.status >= 200 && xhr.status < 300) {
        if (options.onLoad)
          options.onLoad.call(this);
      } else {
        console.log('ERROR! ', xhr);
      }
    };
    _.each(options.fields, (value, field) => {
      fd.append(field, value);
    });

    // path to server would be where you'd normally post the form to
    xhr.open('POST', options.url, true);
    xhr.setRequestHeader('X-CSRF-TOKEN' , $('meta[name="csrf-token"]').attr('content'));
    xhr.send(fd);
  }

  static array_move(list: any[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  static supportsWebP(){
    return !(browserizr.detect(isIE) || browserizr.detect(isSafari) || browserizr.detect(isEdge) || Helper.isIOS() || browserizr.detect(isIPad));
  }

  static isIOS(){
    return browserizr.detect(isIOS) || (browserizr.detect(isMacOS) && window.navigator.maxTouchPoints > 2);
  }

  static isMobile(){
    return browserizr.detect(isMobile) || Helper.isIOS();
  }

  static fixHref(href) {
    let pageMatcher = /^\/page\/.*/;
    let blogMatcher = /^\/blog\/.*/;
    let tpegMatcher = /.*\/tpeg-urls.*/;
    let uuid = null;
    if (pageMatcher.test(href)) {
      uuid = href.substring(6, href.length);
      let page = FW.store.get('pages').findWhere({uuid: uuid});
      if (page) return page.getLink();
    } else if (blogMatcher.test(href)) {
      uuid = href.substring(6, href.length);
      let blog = FW.store.get('blogs').findWhere({id: uuid});
      if (blog) return '/blog/' + blog.get('slug');
    } else if (tpegMatcher.test(href)) {
      const user = FW.store.get('user');
      if (user && user.mayOnly(['edit_tpeg_url', 'edit_user'])) {
        const urls = user.getPermittedModelIds('edit_tpeg_url');
        return '/admin/tpeg-urls/' + urls[0];
      } else if (user.isEditor()) {
        return '/admin/tpeg-urls';
      } else if (user) {
        return '/edit-user';
      }
      return '#';
    }

    if (FW.store.get('site').prop('is_tpeg') && !FW.inEditor()) {
      href = TpegUrl.replaceString(href);
    }

    return href;
  }

  static convertHexToRGBA(hex, opacity) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  }

  static strContains(string, search, ignoreCase) {
    return (
      (ignoreCase ? string.toUpperCase() : string).indexOf(
        ignoreCase ? search.toUpperCase() : search
      ) >= 0
    );
  }

  static titleCase(string) {
    string = string.replace(/_/g, ' ');
    var splitStr = string.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  static formatFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    var units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  }

  static getPath(original, path) {
    path = path.split('.');
    let obj = clone(original) || {},
      v = obj;
    for (let i = 0; i < path.length; i++) {
      if (typeof v[path[i]] === 'undefined')
        return null;
      v = v[path[i]];
    }
    return v;
  }

  static setPath(original, path, value) {
    path = path.split('.');
    let obj = clone(original) || {},
      v = obj;
    for (let i = 0; i < path.length; i++) {
      v = v[path[i]];
    }

    if (typeof value !== 'undefined') {
      while (path.length > 1) obj = obj[path.shift()];
      obj[path.shift()] = value;
    }

    return obj;
  }

  static getGreatestCommonDivisor(a, b) {
    if (isNaN(a) || isNaN(b)) {
      return 1;
    }
    return b === 0 ? a : this.getGreatestCommonDivisor(b, a % b);
  }

  static getAspectRatio(width, height) {
    let gcd = this.getGreatestCommonDivisor(width, height);
    return {
      width: width / gcd,
      height: height / gcd
    };
  }

  static getWideOrHigh(size) {
    let ratio;
    if (size && size.height) {
      ratio = size.height / size.width;
    }
    return ratio;
  }

  static rgbObjectToString(v) {
    let a = typeof v.a !== 'undefined' ? v.a : '1';
    return 'rgba(' + v.r + ', ' + v.g + ', ' + v.b + ', ' + a + ')';
  }

  static rgbaToHex(rgba) {
    var parts = rgba.substring(rgba.indexOf('(')).split(','),
      r = parseInt(trim(parts[0].substring(1)), 10),
      g = parseInt(trim(parts[1]), 10),
      b = parseInt(trim(parts[2]), 10),
      a = parseFloat(trim(parts[3].substring(0, parts[3].length - 1))).toFixed(
        2
      );

    return '#' + r.toString(16) + g.toString(16) + b.toString(16);
  }

  static hexToRgb(hex) {
    var result;
    if (hex.length === 4) {
      result = /^#?([a-f\d]{1})([a-f\d]{1})([a-f\d]{1})$/i.exec(hex);
      result[1] = result[1] + result[1];
      result[2] = result[2] + result[2];
      result[3] = result[3] + result[3];
    } else {
      result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    }
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  }

  static isRetina() {
    return window.devicePixelRatio > 1;
  }

  static dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    let byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});
  }

  static blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  /**
   * See if the mouse is over an element
   * @param event - jQuery event (such as mousemove)
   * @param element - The jQuery element you want to inspect
   * @returns {boolean}
   */
  static overElement(event, element) {
    if (!element.offset()) return false;
    let offset = this.getElementOffset(element);
    let mouseX = event.clientX,
      mouseY = event.clientY;

    return (
      offset.left < mouseX &&
      offset.right > mouseX &&
      offset.top < mouseY &&
      offset.bottom > mouseY
    );
  }

  static randomString(length) {
    var result = '';
    var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  /**
   * Gets the offset for the four corners of the specified element
   * @param element - jQuery Element
   * @returns {{left: *, top: number, right: *, bottom: *}}
   */
  static getElementOffset(element) {
    let top = element.offset().top - $(window).scrollTop(),
      left = element.offset().left,
      right = element.offset().left + element.outerWidth(),
      bottom =
        element.offset().top - $(window).scrollTop() + element.outerHeight();

    return {
      left: left,
      top: top,
      right: right,
      bottom: bottom
    };
  }
}

(function() {
  $.fn.menu = function() {
    /**
     * Get the first selected jquery element
     */
    let el = this.first();

    /**
     * If this is a Menu, return the menu
     * from the store
     */
    if (el.data('fw-model') === config.MENU_MODEL) {
      return FW.Models.Menu.findOrCreate({id: el.data('id')}, {create: false});
    }

    /**
     * If this is not a Menu, return null
     */
    return null;
  };

  $.fn.logo = function() {
    /**
     * Get the first selected jquery element
     */
    let el = this.first();

    /**
     * If this is a SiteLogo, return the logo
     * from the store
     */
    if (el.data('fw-model') === config.LOGO_MODEL) {
      return FW.store.get('site').get('logo');
    }

    /**
     * If this is not a SiteLogo, return null
     */
    return null;
  };

  $.fn.section = function() {
    /**
     * Get the first selected jquery element
     */
    let el = this.first();

    /**
     * If this is a PageSection, return the page section
     * from the Page.sections collection
     */
    if (el.data('fw-model') === config.PAGE_SECTION_MODEL) {
      return (
        FW.store.get('sections').get(el.data('id')) ||
        FW.Models.PageSection.findOrCreate({id: el.data('id')})
      );
    }

    /**
     * If this is not a PageSection, return null
     */
    return null;
  };

  $.fn.group = function() {
    /**
     * Get the first selected jquery element
     */
    let el = this.first();

    /**
     * If this is a PageSection, return the page section
     * from the Page.sections collection
     */
    if (el.data('fw-model') === config.PAGE_GROUP_MODEL) {
      return (
        Page.groups.get(el.data('id')) ||
        FW.Models.PageGroup.findOrCreate({id: el.data('id')})
      );
    }

    /**
     * If this is not a PageSection, return null
     */
    return null;
  };
})();

(function() {
  $.fn.realWidth = function() {
    /**
     * Get the first selected jquery element
     */
    let el = this.first();

    let display = el.css('display');
    let rowWidth = el.css({display: 'block'}).width();
    el.css({display: display});

    return rowWidth;
  };

  $.fn.realHeight = function() {
    /**
     * Get the first selected jquery element
     */
    let el = this.first();

    let display = el.css('display');
    let rowWidth = el.css({display: 'block'}).outerHeight();
    el.css({display: display});

    return rowWidth;
  };
})();

(function() {
  $.fn.inViewport = function(): boolean {
    let topWin = $(window).scrollTop(),
      winHeight = $(window).height(),
      bottomWin = topWin + winHeight,
      visible = false;

    let offset = $(this).offset();
    if (offset) {
      let bottom = $(this).outerHeight() + offset.top;
      //console.log('offset: ', offset, ' topWin: ', topWin, ' bottomWin: ', bottomWin);
      if (
        (offset.top >= topWin && offset.top <= bottomWin) ||
        (bottom >= topWin && bottom <= bottomWin) ||
        (bottom >= bottomWin && offset.top <= topWin)
      ) {
        visible = true;
      }
    }
    return visible;
  };
})();

(function() {
  $.fn.blink = function() {
    let self = this;
    window.blink = setInterval(function() {
      self.toggleClass('fw-outline-dashed');
    }, 500);
    window.blinkTimer = setTimeout(function() {
      clearInterval(window.blink);
      self.removeClass('fw-outline-dashed');
    }, 3000);
    return this;
  };
})();

$.fn.extend({
  animateCss: function(animationName) {
    let self = $(this);
    return new Promise(function(resolve) {
      let animationEnd =
        'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
      self.addClass('animated ' + animationName).one(animationEnd, function() {
        self.removeClass('animated ' + animationName);
        resolve(self);
      });
    });
  }
});

$.fn.extend({
  blurElement: function() {
    $(this).css({
      filter: 'blur(10px)',
      '-webkit-filter': 'blur(10px)',
      '-moz-filter': 'blur(10px)',
      '-o-filter': 'blur(10px)',
      '-ms-filter': 'blur(10px) '
    });
  },

  unblurElement: function() {
    $(this).css({
      filter: 'none',
      '-webkit-filter': 'none',
      '-moz-filter': 'none',
      '-o-filter': 'none',
      '-ms-filter': 'none'
    });
  }
});

$.fn.extend({
  highlight: function() {
    let group = $(this).closest('*[data-fw-model="PageGroup"]');
    group.css({
      zIndex: 1000,
      position: 'relative'
    });
    $('<div id="highlightdiv" />')
      .css({
        height: '100%',
        width: '100%',
        position: 'fixed',
        zIndex: 999,
        background: 'rgba(0, 0, 0, .5)',
        top: '0px',
        left: '0px'
      })
      .prependTo('body');
    let navBar = $('.navbar');
    let style = navBar.attr('style') || '';
    style = style + ' z-index: 998 !important;';
    navBar.attr('style', style);
    $(this).addClass('highlight');
    $('body').addClass('highlight-is-active');
    return this;
  },

  unhighlight: function() {
    let group = $(this).closest('*[data-fw-model="PageGroup"]');
    group.css({
      zIndex: ''
    });
    $('#highlightdiv').remove();
    $('.navbar').css({zIndex: ''});
    $(this).removeClass('highlight');
    $('body').removeClass('highlight-is-active');
    return this;
  },

  center: function(bottom) {
    if (typeof bottom === 'undefined') {
      bottom = 0;
    }

    $('html,body').animate(
      {
        scrollTop:
          $(this).offset().top -
          ($(window).height() - $(this).outerHeight(true)) / 2 +
          bottom / 2
      },
      650
    );
    return this;
  },

  blink: function() {
    window.blink = setInterval(function() {
      $(this).toggleClass('fw-outline-dashed');
    }, 500);
    window.blinkTimer = setTimeout(function() {
      clearInterval(window.blink);
      $(this).removeClass('fw-outline-dashed');
    }, 3000);
    return this;
  }
});

export {Helper};
