import React from 'react';
import {File} from "../FW/Models/File";
import './siteLogo.scss';
import MenuItem from '../FW/Models/MenuItem';
import { RefTagger } from 'react-reftagger';
import {TpegUrl} from '../FW/Models/TpegUrl';

class SiteLogo extends React.Component {
  constructor(props){
    super(props);
    const fontProps = FW.store.get('site').get('logo').get('font_props') || {};
    const pageId = FW.store.get('page') ? FW.store.get('page').get('uuid') : null;
    this.state = {
      logo: FW.store.get('site').get('logo'),
      file: null,
      showTagger: false,
      visible: fontProps.hideLogoScroll && window.scrollY < 60 && FW.store.get('site').get('homepage_page_id') === pageId ? false : true,
      hideLogoScroll: fontProps.hideLogoScroll && FW.store.get('site').get('homepage_page_id') === pageId
    };
    this.state.logo.on('change', () => {
      this.forceUpdate();
    }, this);

    this.logoContainer = React.createRef();

    this.loadFile = this.loadFile.bind(this);
  }

  componentDidMount(){
    this.loadFile();
    $(window).resize(()=>{
      this.forceUpdate();
    });
    if (FW.inEditor()) {
      this.logoContainer.current.addEventListener('touchstart', (e) => {
        this.logoContainer.current.touching = true;
      });
    }
    if (FW.inEditor() && this.logoContainer.current.touching) {
      this.logoContainer.current.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    }
    let stateCheck = setInterval(() => {
      console.log('READY STATE: ', document.readyState);
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setTimeout(() => {
          if (cookiesAllowed()) {
            console.log('STARTING REF TAGGER');
            this.setState({
              showTagger: true
            });
          }
        }, 3000);
        // document ready
      }
    }, 100);
    document.addEventListener('scroll', (e) => {
      if (window.scrollY > 60 && !this.state.visble && this.state.hideLogoScroll) {
        this.setState({
          visible: true
        });
      } else if (window.scrollY < 60 && this.state.visible && this.state.hideLogoScroll) {
        this.setState({
          visible: false
        });
      }
    }, {passive: true});
  }

  componentWillUnmount(){
    this.state.logo.off(null, null, this);
  }

  loadFile(){
    if (this.state.logo.get('image_file_id') && this.state.logo.get('logo_type') === 'image') {
      if (!this.state.file || this.state.file.get('id') !== this.state.logo.get('image_file_id')) {
        let file = File.findOrCreate({id: this.state.logo.get('image_file_id')});
        this.setState({
          file: file
        });
        file.ensureLoaded().then(() => {
          this.forceUpdate();
        });
      }
    }
  }

  UNSAFE_componentWillUpdate(){
    this.loadFile();
  }

  render(){
    const logo = this.state.logo;
    const type = logo.get('logo_type');
    const file = this.state.file;
    const props = logo.get('font_props') || {};
    let text = logo.get('text');

    if (FW.store.get('site').prop('is_tpeg') && !FW.inEditor()) {
      text = TpegUrl.replaceString(text);
    }

    let $container = $('#logoContainer');
    let $emptyContainer = $('#noLogoContainer');

    if (type === 'blank'){
      $container.hide();
      if (FW.inEditor())
        $emptyContainer.show();
    } else {
      $container.show();
      $emptyContainer.hide();
    }

    let textStyle = {};
    if (props){
      if (props.family && props.family !== 'default') {
        textStyle.fontFamily = props.family;
      }
      if (props.size && !props.useDefaultSize) {
        textStyle.fontSize = props.size;
      }
    }

    //console.log('URL: ', file.originalUrl());

    let content = <div id={'logoText'} style={textStyle}>{text}</div>;
    if (logo.get('image_file_id') && type === 'image') {
      if (file && file.originalUrl()) {
        let style = {
          position: 'relative'
        };
        if (!props.useDefaultImageSize){
          style.maxHeight = props.imageHeight || 55;
          style.height = props.imageHeight || 55;
        }
        content = <div style={{height: '100%', width: '100%'}}>
          <img alt='Site Logo' id={'logoImage'} src={Helper.isMobile() ? file.helper.thumbnailUrl('optimized') : file.originalUrl()}
            style={style}/>
        </div>;
      } else {
        content = <span/>;
      }
    }

    let item = MenuItem.getStaticItem({
      type: logo.get('linkable_type') === 'site' || logo.get('linkable_type') === 'page' ? logo.get('linkable_type') : null,
      pageUuid: logo.get('linkable_type') === 'page' ? logo.get('linkable_id') : null,
      site_id: logo.get('linkable_type') === 'site' ? logo.get('linkable_id') : null
    }, logo.get('custom_link'));

    return <a href={item.getLink() || '/'} style={{transitionDuration: '500ms', transitionProperty: 'opacity', opacity: this.state.visible ? 1 : 0}} target={item.getTarget()} ref={this.logoContainer}>
      {content}
      {this.state.showTagger && FW.store.get('site').prop('reftagger_version') ? <RefTagger bibleVersion={FW.store.get('site').prop('reftagger_version')} /> : null}
    </a>;
  }
}

export default SiteLogo;
