import React from 'react';
import _ from 'underscore';
import MenuItem from 'FW/Models/MenuItem';
import 'froala-editor/css/froala_style.css';
import 'fr-styles.scss';
import browserizr, {isIEVersion, EQUAL} from '@wezom/browserizr';
import {TpegUrl} from '../FW/Models/TpegUrl';
import cloneDeep from 'lodash/cloneDeep';

let isIe11 = browserizr.detect(isIEVersion(EQUAL, 11));

let FroalaEditorComponent, froalaConfig, comp;

let bootFroala = function(functions) {
  functions.addFontSizeCssButton();
  functions.addVideoExistingButton();
  functions.addButtonMaker();
  functions.addFileLink();
  functions.addEditLinkButton();
  functions.addInsertLinkButton();
  functions.addTextSectionify();
  functions.addImageSectionify();
  functions.addButtonSize();
  functions.addPlainTextButton();
  functions.addFontUnitIconButton((cmd, val) => {
    comp.setState({
      fontSizeUnit: val
    });
  });
};

class InlineEditableElement extends React.Component {
  constructor(props) {
    super(...arguments);
    this.modelChanged = this.modelChanged.bind(this);
    let uniqueFamilies = [];
    let googleFonts = {
      'Roboto, sans-serif': 'Roboto',
      'Open Sans, sans-serif': 'Open Sans',
      'Lato, sans-serif': 'Lato',
      'Montserrat, sans-serif': 'Montserrat',
      'Roboto Condensed, sans-serif': 'Roboto Condensed',
      'Source Sans Pro, sans-serif': 'Source Sans Pro',
      'Oswald, sans-serif': 'Oswald',
      'Raleway, sans-serif': 'Raleway',
      "'Slabo 27px', serif": 'Slabo 27px',
      'PT Sans, sans-serif': 'PT',
      'Merriweather, serif': 'Merriweather',
      'Roboto Slab, serif': 'Roboto Slab',
      'Open Sans Condensed, sans-serif': 'Open Sans Condensed',
      'Ubuntu, sans-serif': 'Ubuntu',
      'Poppins, sans-serif': 'Poppins',
      'Noto Sans, sans-serif': 'Noto Sans',
      'Playfair Display, serif': 'Playfair Display',
      'Roboto Mono, monospace': 'Roboto Mono',
      'Lora, serif': 'Lora',
      'PT Serif, serif': 'PT Serif',
      Pacifico: 'Pacifico',
      'Baloo Tammudu': 'Baloo Tammudu',
      Lobster: 'Lobster',
      'Shadows Into Light': 'Shadows Into Light'
    };
    let fontFamilies = {
      Arial: 'Arial',
      'Times New Roman': 'Times New Roman',
      Verdana: 'Verdana',
      Impact: 'Impact',
      Georgia: 'Georgia',
      Tahoma: 'Tahoma',
      'Trebuchet MS': 'Trebuchet',
      Helvetica: 'Helvetica'
    };

    fontFamilies = Object.assign({}, fontFamilies, googleFonts);

    const id = 'froala-' + Math.round(Math.random() * 100000);

    this.state = {
      html: props.html,
      fontFamilies,
      uniqueFamilies,
      googleFonts,
      fontSizeUnit: 'px',
      id,
      froalaLoaded: false
    };
  }

  componentDidUpdate() {
    this.processButtonsAndImages(this._div);
    if (FW.inEditor()) {
      // make image links not clickable
      $(this._div).on('click', 'a', function(e){
        if ($(e.currentTarget).find('img').length) {
          e.preventDefault();
        }
      });
    }
  }

  componentDidMount() {
    this.processButtonsAndImages(this._div);
    this.getFontFamilies();
    let fontFamilies = _.clone(this.state.fontFamilies);
    let site = FW.store.get('site') || FW.getParent().FW.store.get('site');
    if (site.get('properties') && site.get('properties').googleFonts)
      site
        .get('properties')
        .googleFonts.split('\n')
        .forEach(fontName => {
          fontFamilies[fontName] = fontName;
        });
    this.setState(
      {
        fontFamilies: fontFamilies
      },
      function() {
        if (window.name === 'editFrame') {
          if (isIe11) {
            require(/* webpackPrefetch: true */ ['fw-froala-ie11', './FroalaFunctionsIE11'], (froala, functions) => {
              this.froalaConfig = froala.config;
              FroalaEditorComponent = froala.default;
              bootFroala(functions);
            });
          } else {
            require(/* webpackPrefetch: true */ ['fw-froala', './FroalaFunctions'], (froala, functions) => {
              let froalaConfig = cloneDeep(froala.config);
              if (this.props.customFontSizes && FW.isGlobalAdmin()) {
                if (froalaConfig.toolbarButtons.moreText.buttons.indexOf('fontSizeCss') < 0) {
                  froalaConfig.toolbarButtons.moreText.buttons.splice(froalaConfig.toolbarButtons.moreText.buttons.indexOf('fontSize'), 0, 'fontSizeCss');
                }
                if (froalaConfig.linkEditButtons.indexOf('fontSizeCss') < 0) {
                  froalaConfig.linkEditButtons.splice(froalaConfig.linkEditButtons.indexOf('fontSize'), 0, 'fontSizeCss');
                }
                froalaConfig.linkEditButtons.splice(froalaConfig.linkEditButtons.indexOf('fontSize'), 1);
                froalaConfig.toolbarButtons.moreText.buttons.splice(froalaConfig.toolbarButtons.moreText.buttons.indexOf('fontSize'), 1);
              }
              this.froalaConfig = froalaConfig;
              FroalaEditorComponent = froala.default;
              bootFroala(functions);
              this.setState({
                froalaLoaded: true
              });
            });
          }
        }
        this.forceUpdate();
      }
    );

    if (FW.inEditor()) {
      if (!window.allEditorFontsLoaded) {
        window.allEditorFontsLoaded = true;
        let fontsToLoad = Object.values(this.state.googleFonts);
        let googleFontNames = {
          'Open Sans Condensed': 'Open Sans Condensed:300'
        };
        _.each(fontsToLoad, (v, i) => {
          fontsToLoad[i] = googleFontNames[v] || fontsToLoad[i];
        });
        if (window.WebFont)
          window.WebFont.load({
            google: {
              families: fontsToLoad
            }
          });
      }
    }
  }

  processButtonsAndImages(div) {
    // this fixes an issue with images copied from wordpress that have a srcset attribute.
    $(div)
      .find('img')
      .attr('srcset', null);
    if (!FW.inEditor()) {
      $(div)
        .find('*[contenteditable]')
        .attr('contenteditable', null);
    } else {
      $(div)
        .find('.fr-view')
        .find('*[contenteditable]')
        .attr('contenteditable', null);
    }

    //get any buttons that were created on a different template and update the styles for them.
    let template = FW.store.get('template');
    let templateId = FW.store.get('template').get('id');
    let buttons = $(div).find(
      '*[data-fw-element="button"][data-fw-template-id!="' + templateId + '"]'
    );
    buttons.each((i, button) => {
      let b = $(button);
      let def = _.findWhere(template._buttonClasses, {
        type: b.data('fw-button-type')
      });
      if (!def) def = template._buttonClasses[0];
      if (def) {
        b.removeAttr('class');
        b.attr('data-fw-template-id', templateId);
        _.each(def.classes, c => {
          b.addClass(c);
        });
        b.addClass(b.data('fw-button-size'));
      }
    });
  }

  static cleanHREF(href) {
    const domain = document.domain;
    const pattern = new RegExp(
      '(?:https?://)(?:edit.)?((?:' + domain + '\/)|(?:finalweb2.finalweb.net))',
      'g'
    );
    return href.replace(pattern, '').replace(/\+/g, '%20');
  }

  shouldComponentUpdate(newProps, nextState, nextContext) {
    if (this.props.inlineEditingEnabled
      && newProps.html === this.state.html
      && nextState.fontSizeUnit === this.state.fontSizeUnit
      && this.state.froalaLoaded === nextState.froalaLoaded){
      return false;
    }
    return true;
  }

  UNSAFE_componentWillReceiveProps(newProps, nextContext) {
    this.setState({
      html: newProps.html
    });
  }

  modelChanged(value) {
    if (value !== this.state.html) {
      this.setState({
        html: value
      });
      this.props.onChange(value);
    }
  }

  optimizeImages(div) {
    let parsedHtml = $('<div>' + div + '</div>');
    // get all the images
    let files = parsedHtml.find('[data-file_id][data-file_type_id="1"]');

    // if there are files, replace the urls on them with the optimized url.
    if (files.length > 0) {
      _.each(files, file => {
        let f = FW.Models.File.findOrCreate({
          id: file.getAttribute('data-file_id')
        });
        let img = parsedHtml.find('img[data-file_id="' + f.get('id') + '"]');
        let caption = img.closest('.fr-img-caption');
        let widthHolder = caption.length > 0 ? caption : img;
        if (img && f.get('title')) {
          img.attr("alt", f.get('title'));
        }

        // if the image is wider that the section width, resize it (makes responsiveness work)
        if (this.props.size) {
          let curWidth = widthHolder.css('width') ? widthHolder.css('width').replace('px', '') : 0;
          if (curWidth > this.props.size.width) {
            widthHolder.css('height', '');
            widthHolder.css('width', this.props.size.width);
          }
        }
        let width =
          widthHolder.css('width') && !FW.inEditor()
            ? widthHolder.css('width').replace('px', '')
            : 'optimized';
        if ((width === '0' || width === 0) && widthHolder.attr('width')) {
          width = widthHolder.attr('width');
          if (width.indexOf('\'') >= 0) {
            width = width.replace('\'', '');
            width = width.replace('\'', '');
          }
        }
        img.attr('src', f.helper.thumbnailUrl(width));
      });
      return parsedHtml.html();
    }
    return div;
  }

  fixLinks(data) {
    let parsedHtml = $('<div>' + data + '</div>');
    let pageLinks = parsedHtml.find('a[data-page-id], a[data-link-type]');
    if (pageLinks.length > 0) {
      _.each(pageLinks, link => {
        let item = MenuItem.getStaticItem(link.dataset);
        $(link).attr(item.getAttributes());
      });
    }

    let alinks = parsedHtml.find('a');
    _.each(alinks, link => {
      let h = $(link).attr('href');
      $(link).attr('aria-label', link.textContent);
      if ($(link).attr('class') && $(link).attr('class').indexOf('btn') >= 0) {
        //add button role
        $(link).attr('role', 'button');
      }
      if (h && h.indexOf('+')) {
        $(link).attr('href', h.replace(/\+/g, '%20'));
      }
    });

    if (pageLinks.length > 0 || alinks.length > 0) {
      return parsedHtml.html();
    }

    return data;
  }

  getFontFamilies() {
    const html = this.props.html;
    const familyMatcher = /font-family:[ "']*([^;"',]+)/g;
    let families = [];
    let match = familyMatcher.exec(html);
    while (match) {
      if (match[1]) families.push(match[1]);
      match = familyMatcher.exec(html);
    }
    let uniqueFamilies = [];
    $.each(families, function(i, el) {
      if ($.inArray(el, uniqueFamilies) === -1) uniqueFamilies.push(el);
    });
    if (
      this.props.fontFamilyChanged &&
      uniqueFamilies
        .concat()
        .sort()
        .join(',') !==
        this.state.uniqueFamilies
          .concat()
          .sort()
          .join(',')
    ) {
      this.props.fontFamilyChanged(uniqueFamilies);
      this.setState({
        uniqueFamilies: uniqueFamilies
      });
    }
    return uniqueFamilies;
  }

  parseTpeg(html) {
    return FW.store.get('site').prop('is_tpeg') ? TpegUrl.replaceString(html) : html;
  }

  render() {
    let {html, onChange, el, inlineEditingEnabled, ...props} = this.props; //eslint-disable-line no-unused-vars
    let Element = el || 'div';
    let elClass = this.props.className ? this.props.className : '';
    elClass = elClass + ' clearfix fr-view';

    let parsedHtml = this.state.html ? this.state.html.toString() : '';
    //This was causing issues with the caret position in the editor.
    if (!FW.inEditor()) {
      parsedHtml = this.optimizeImages(this.state.html);
      parsedHtml = this.fixLinks(parsedHtml);
      parsedHtml = this.parseTpeg(parsedHtml);
      parsedHtml = parsedHtml && parsedHtml.replace ? parsedHtml.replace(/&nbsp;/g, ' ') : parsedHtml;
    }

    const fontSizeUnit = this.state.fontSizeUnit || 'px';
    let fontSizeOptions =
      fontSizeUnit === 'px'
        ? [
            '8',
            '9',
            '10',
            '11',
            '12',
            '14',
            '16',
            '18',
            '20',
            '22',
            '24',
            '30',
            '36',
            '48',
            '54',
            '60',
            '72',
            '96'
          ]
        : ['.5', '1', '1.5', '2', '3', '4', '5', '6', '8', '10', '12'];
    fontSizeOptions = fontSizeOptions.map(size => {
      return size + fontSizeUnit;
    });

    const key = this.state.id + '_' + this.state.fontSizeUnit || '';
    return this.props.inlineEditingEnabled && this.state.froalaLoaded ? (
      <FroalaEditorComponent
        key={key}
        ref={com => {
          this._div = com ? com.$element : null;
        }}
        onModelChange={this.modelChanged}
        tag={Element}
        model={parsedHtml}
        config={{
          ...this.froalaConfig,
          toolbarInline: true,
          toolbarVisibleWithoutSelection: Helper.isMobile(),
          toolbarButtons: this.props.toolbarButtons
            ? this.props.toolbarButtons
            : this.froalaConfig.toolbarButtons,
          toolbarButtonsMD: this.props.toolbarButtons
            ? this.props.toolbarButtons
            : null,
          toolbarButtonsSM: this.props.toolbarButtons
            ? this.props.toolbarButtons
            : null,
          toolbarButtonsXS: this.props.toolbarButtons
            ? this.props.toolbarButtons
            : null,
          quickInsertButtons: this.props.quickInsertButtons || this.froalaConfig.quickInsertButtons,
          quickInsertTags: this.props.quickInsertTags,
          fontSize: fontSizeOptions,
          fontFamily: this.state.fontFamilies,
          enter: typeof this.props.enter !== 'undefined' ? this.props.enter : FroalaEditor.ENTER_BR,
          editorClass: this.props.className,
          events: {
            'commands.before': (cmd, val) => {
              if (cmd === 'fontUnit') {
                comp = this;
              }
            }
          },
          ignoreIframe: true
        }}
      />
    ) : (
      <Element
        ref={com => {
          this._div = com;
        }}
        dangerouslySetInnerHTML={{__html: parsedHtml}}
        className={elClass}
      />
    );
  }
}

export default InlineEditableElement;
