import React from 'react';
import './RssFeed.scss';

class RssFeed extends React.Component {
  constructor(props) {
    super(...arguments);
    this.state = this.getStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState(this.getStateFromProps(newProps));
  }

  getStateFromProps(props) {
    let feed = FW.store.get('feed-' + props.section.get('id'));
    const title = props.section.prop('custom_title') || feed.title;
    return {
      items: feed.items,
      title: title
    };
  }

  render() {
    let items = this.state.items.map((item, i) => {
      return (
        <div key={`feed-item-${i}`} className="well feed-item">
          <h4>
            <a href={item.href}>{item.title}</a> <small>{item.date}</small>
          </h4>
          <span dangerouslySetInnerHTML={{__html: item.description}}></span>
          <br style={{width: '100%', clear: 'both'}} />
        </div>
      );
    });

    return (
      <div className="fw-rss-feed-section">
        <h3>{this.state.title}</h3>
        {items}
      </div>
    );
  }
}

export default RssFeed;
