import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {PageSectionTemplateLayout, PageSectionTemplateLayouts} from './PageSectionTemplateLayout';

class PageSectionLayout extends Model{

  get modelUrl(){ return '/page-section-layouts'; }

  get defaults(){
    return {
      type: 'page_section_layout'
    };
  }

  get currentTemplateLayout(){
    if (!this._currentTemplateLayout){
      this._currentTemplateLayout = this.get('template_layouts').findWhere({
        template_id: FW.store.get('site').get('template_id')
      });
    }
    return this._currentTemplateLayout;
  }

}
PageSectionLayout.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'template_layouts',
    relatedModel: PageSectionTemplateLayout,
    collectionType: PageSectionTemplateLayouts,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'layout'
    }
  }
];


class PageSectionLayouts extends Collection{}
PageSectionLayouts.prototype.model = PageSectionLayout;

export {PageSectionLayout, PageSectionLayouts};
