import React from 'react';
import ReactDOM from 'react-dom';
import InlineEditableElement from 'Components/InlineEditableElement';
import {File as FWFile} from 'FW/Models/File';
import {Helper} from '../../../Helpers';
import 'jquery.appear';
import FullOverlay from "./FullOverlay";


function featureBoxHoc(Component) {

  return class FeatureBoxHoc extends React.Component {

    constructor(props) {
      super(props);
      this.mouseOver = this.mouseOver.bind(this);
      this.mouseLeave = this.mouseLeave.bind(this);
      this.state = {
        showOverlay: false
      };
      this.fullScreenOverlay = React.createRef();
    }

    titleChanged(value) {
      this.props.section.prop('title', value);
    }

    contentChanged(value) {
      this.props.section.prop('content', value);
    }

    mouseOver() {
      let toBeAnimated = $('#animationContainer-' + this.props.section.get('id')).find('.hoverAnimated');
      toBeAnimated.addClass(this.props.section.getAnimationClass('hover'));
      this.setState({
        showOverlay: true
      });
    }

    mouseLeave() {
      let toBeAnimated = $('#animationContainer-' + this.props.section.get('id')).find('.hoverAnimated');
      toBeAnimated.removeClass(this.props.section.getAnimationClass('hover'));
      this.setState({
        showOverlay: false
      });
    }

    componentDidMount() {
      const section = this.props.section;
      const scrollAnimation = section.getAnimationClass('scroll');
      if (scrollAnimation) {
        const toBeAnimated = $(`#animationContainer-${section.get('id')}`).find('.scrollAnimated');
        toBeAnimated.appear(() => {
          toBeAnimated.addClass(scrollAnimation);
          toBeAnimated.one('webkitAnimationEnd oanimationend msAnimationEnd animationend',
            () => {
              toBeAnimated.removeClass(scrollAnimation);
            });
        });
      }
    }

    fullOverlay = (e) => {
      $('body').append('<div id="featureBoxOverlay"></div>');
      const $div = $('#featureBoxOverlay');
      ReactDOM.render(<FullOverlay ref={this.fullScreenOverlay}
                                   section={this.props.section} {...this.props.inlineEditorProps}
                                   fontFamilyChanged={this.fontFamilyChanged.bind(this)}
                                   inlineEditingEnabled={this.props.inlineEditingEnabled}
                                   onClose={this.fullOverlayClosed} title={this.props.section.prop('title')}
                                   content={this.props.section.prop('overlay').content}/>, $div[0]);
    };

    fullOverlayClosed = () => {
      $('#featureBoxOverlay').remove();
    };

    UNSAFE_componentWillUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any) {
      if (this.fullScreenOverlay && this.fullScreenOverlay.current && this.fullScreenOverlay.current.setInlineEditing) {
        this.fullScreenOverlay.current.setInlineEditing(nextProps.inlineEditingEnabled);
        this.fullScreenOverlay.current.setContent(nextProps.section.prop('overlay').content);
      }
    }

    fontFamilyChanged(fonts) {
      let extantFonts = this.props.section.prop('fonts') || [];
      extantFonts.forEach((font) => {
        fonts.push(font);
      });
      let uniqueFamilies = [];
      $.each(fonts, function(i, el) {
        if ($.inArray(el, uniqueFamilies) === -1) uniqueFamilies.push(el);
      });
      uniqueFamilies.sort((a, b) => {
        return a > b
      });
      if (uniqueFamilies.join(',') !== extantFonts.join(',')) {
        this.props.section.prop('fonts', uniqueFamilies);
        FW.store.get('page').getFonts();
      }
    }

    linkClicked(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    getAnimationClass = (target) => {
      const section = this.props.section;
      const scrollTarget = section.getAnimation('scroll') ? section.getAnimation('scroll').target : '' || '';
      const hoverTarget = section.getAnimation('hover') ? section.getAnimation('hover').target : '' || '';
      const animateOnHover = ' ' + 'hoverAnimated';
      const animateOnScroll = ' ' + 'scrollAnimated';
      return (hoverTarget === target ? animateOnHover : '') + ' ' + (scrollTarget === target ? animateOnScroll : '')
    };

    getBodyScale(): number {
      const regExp = /scale\(([^)]+)\)/;
      let doc = window.name !== 'editFrame' ? (FW.editor ? FW.editor.getIframeWindow().document : document) : document;
      const bodyTransform = doc && doc.body ? doc.body.style.transform : '';
      const matches = regExp.exec(bodyTransform);
      return matches ? matches[1] : 1;
    }

    render() {
      let scale = FW.inEditor() ? this.getBodyScale() : 1;
      const size = this.props.size ? (this.props.size.width / scale) : 'optimized';
      return <div id={'animationContainer-' + this.props.section.get('id')}
                  className={this.props.section.getAnimationClass('load')} onMouseOver={this.mouseOver}
                  onMouseLeave={this.mouseLeave}>
        <Component {...this.props} imageWidth={size} convertHexToRGBA={Helper.convertHexToRGBA} fullOverlay={this.fullOverlay}
                   getAnimationClass={this.getAnimationClass} fontFamilyChanged={this.fontFamilyChanged}
                   titleChanged={this.titleChanged}
                   contentChanged={this.contentChanged} linkclicked={this.linkClicked} inlineEditorProps={{enter: 2}}
                   hoverTarget={this.props.section.getAnimationTarget('hover')}
                   animateOnHover={' hoverAnimated '} showOverlay={this.state.showOverlay}/>
      </div>;
    }
  };
}

class FeatureBox extends React.Component {

  render() {
    const section = this.props.section;
    const image = section.prop('image');
    const link = section.prop('link');

    const imageTitle = image.title;
    const imageAlt = image.alt;
    const url = image.src ? FWFile.findOrCreate({id: image.src}).helper.thumbnailUrl(this.props.imageWidth) : null;
    const imageUrl = url ? url : image.src;

    const linkHref = typeof link.href !== 'undefined' ? link.href : link;

    const title = section.prop('title');
    const content = section.prop('content');

    const overlay = section.prop('overlay') || {};
    const showOverlay = this.props.showOverlay && overlay.visible && !overlay.popupOnly;
    const overlayContent = overlay.content;

    const overlayBackgroundColor = overlay.overlayBackgroundColor || '#000000';
    const overlayOpacity = typeof overlay.overlayOpacity !== 'undefined' ? overlay.overlayOpacity : 80;
    const overlayBackground = this.props.convertHexToRGBA(overlayBackgroundColor, overlayOpacity);
    const overlayTextColor = overlay.overlayTextColor || '#ffffff';
    const overlayPadding = overlay.padding || 0;
    const seeMoreText = overlay.seeMoreText || 'See More';
    const height = section.prop('image_height') || 'auto';
    let imageComponent;
    if (height === 'auto') {
      imageComponent = <img className={'img-responsive' + this.props.getAnimationClass('image')}
                                  style={{
                                    paddingLeft: section.prop('image.padding'),
                                    paddingRight: section.prop('image.padding'),
                                    margin: '0 auto',
                                    height: section.prop('image_height') || 'auto',
                                    maxWidth: section.prop('image_maxWidth')
                                  }}
                                  src={imageUrl}
                                  title={imageTitle}
                                  alt={imageAlt}/>;
    } else {
      imageComponent = <div style={{
        paddingLeft: section.prop('image.padding'),
        paddingRight: section.prop('image.padding'),
        margin: '0 auto',
        maxWidth: section.prop('image_maxWidth'),
        backgroundImage: 'url(' + imageUrl + ')',
        backgroundSize: 'cover', height: height,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }} />;
    }


    const overlayComponent = <div onClick={this.props.fullOverlay} className={'featureBoxOverlay animated fadeIn fast'}
                                  style={{
                                    position: 'absolute',
                                    background: overlayBackground,
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                    color: overlayTextColor,
                                    overflow: 'hidden'
                                  }}>
      <div dangerouslySetInnerHTML={{__html: overlayContent}} style={{
        bottom: 0, top: 0, position: 'absolute', overflow: 'hidden',
        padding: overlayPadding
      }}/>
    </div>;

    return (

      <div className={this.props.getAnimationClass('whole')} style={{textAlign: 'center'}}>
        {!FW.inEditor() && linkHref && !showOverlay ? <a className="portfolio-link" href={Helper.fixHref(linkHref)}
                                                         target={section.prop('target') || '_self'} aria-label={section.prop('ada_title')}>
          {showOverlay ? <div style={{position: 'relative'}}>{imageComponent}</div> :
            <div>{imageComponent}</div>}
        </a> : showOverlay ? <div style={{position: 'relative'}}>{imageComponent}{overlayComponent}</div> :
          <div>{imageComponent}</div>}
        <div className={this.props.getAnimationClass('text')}>
          <InlineEditableElement className='content' el="h3" html={title} {...this.props.inlineEditorProps}
                                 onChange={this.props.titleChanged.bind(this)}
                                 fontFamilyChanged={this.props.fontFamilyChanged.bind(this)}
                                 inlineEditingEnabled={this.props.inlineEditingEnabled}/>
          <InlineEditableElement className='caption' html={content} {...this.props.inlineEditorProps}
                                 onChange={this.props.contentChanged.bind(this)}
                                 fontFamilyChanged={this.props.fontFamilyChanged.bind(this)}
                                 inlineEditingEnabled={this.props.inlineEditingEnabled}/>
          {overlay.visible ? <span onClick={this.props.fullOverlay} style={{
            display: 'block',
            textDecoration: 'underline',
            margin: '0 5px 0 0',
            textAlign: 'right'
          }}>{seeMoreText}</span> : null}
        </div>
      </div>

    );
  }

}

export {featureBoxHoc as hoc};
export default featureBoxHoc(FeatureBox);
