import React from 'react';
import ReactDOM from 'react-dom';
import LoadingWheel from '../Page/Sections/MediaLibrary/LoadingWheel';
import LinearProgress from '@material-ui/core/LinearProgress';
import 'CommonElements/Loading.scss';

class Loading extends React.Component{

  constructor(props){
    super(...arguments);
    this.state = {
      opacity: 0,
      textIndex: 0,
      text: props.text
    };
  }

  componentDidMount(){
    this.timeout = setTimeout(() => {
      this.setState({
        opacity: this.props.opacity || 1
      });
    }, 10);
    if (typeof this.state.text === 'object') {
      setInterval(() => {
        const nextIndex = this.state.textIndex === (this.state.text.length - 1) ? 0 : this.state.textIndex + 1;
        this.setState({
          textIndex: nextIndex
        });
      }, 3000)
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if ((prevProps.unmount !== this.props.unmount && this.props.unmount && this.props.fullScreen) ||
      (prevProps.unmount === this.props.unmount && prevProps.unmount && this.state.opacity === 1)) {
      this.setState({
        opacity: 0
      });
      setTimeout(() => {
        ReactDOM.unmountComponentAtNode(document.getElementById('loading-overlay-wrapper'));
      }, 1000);
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timeout);
  }

  render(){
    let wheelOrFW = FW.store.get('customer').get('reseller_logo') || FW.store.get('reseller') && FW.store.get('reseller').get('reseller_logo') || this.props.wheel ? 'wheel' : 'fw';

    const displayText = (typeof this.state.text === 'object' ? this.state.text[this.state.textIndex] : (this.props.text ? this.props.text : 'Loading...'))
    let loader = <div style={{width: '100%', height: '100%', display: 'flex', backdropFilter: 'blur(10px)', WebkitBackdropFilter: 'blur(10px)'}}>
      <div className="finalweb-loader-wrapper">
        {wheelOrFW === 'fw' ? <div className="finalweb-loader">
          <div className="block block-1"></div>
          <div className="block block-2"></div>
          <div className="block block-3"></div>
          <div className="block block-4"></div>
          <div className="block block-5"></div>
          <div className="block block-6"></div>
          <div className="block block-7"></div>
          <div className="block block-8"></div>
          <div className="block block-9"></div>
          <div className="block block-10"></div>
          <div className="block block-11"></div>
        </div> : <LoadingWheel white={this.props.white} />}
        <div style={{width: '300%', textAlign: 'center', marginLeft: '-100%', marginTop: 20}}>
          <span className="loading-text" style={{color: this.props.fontColor || null}}>{displayText}</span>
          {this.props.progress ? <div style={{width: '200%', marginLeft: '-50%', marginTop: 10}}>
            <LinearProgress variant="determinate" value={this.props.progress} />
          </div>: null}
        </div>
      </div>
    </div>;

    return this.props.fullScreen ? <div className="full-screen" style={{zIndex: 9999, background: this.props.backgroundColor || 'rgba(0, 0, 0, 0.9)', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, color: this.props.fontColor || '#FFF', transition: 'opacity 1s', opacity: this.state.opacity}}>
      {loader}
    </div> : loader;
  }

}

export default Loading;
