import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';

class AppHomepageTile extends Model {
  get includedRelations() {
    return [];
  }

  get modelUrl() {
    return '/app-homepage-tiles';
  }

  get defaults() {
    return {
      type: 'app_homepage_tile'
    };
  }
}
AppHomepageTile.prototype.relations = [
  {
    key: 'background',
    type: Backbone.Relational.HasMany,
    relatedModel: 'FW.Models.File'
  }
];
AppHomepageTile.prototype.sortField = 'tile_order';

class AppHomepageTiles extends Collection {
  get comparator() {
    return this._comparator ? this._comparator : 'tile_order';
  }
  set comparator(value) {
    this._comparator = value;
  }
}
AppHomepageTiles.prototype.model = AppHomepageTile;

export {AppHomepageTile, AppHomepageTiles};
