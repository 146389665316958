import _ from 'underscore';
import React from 'react';
import ReactDOM from 'react-dom';
import Loading from './CommonElements/Loading';

class $u {
  constructor(params) {
    return new Utils(params);
  }

  //Capitalize first letter (for scheduling groups)
  static cfl(string) {
    return string && string.length > 1
      ? string.charAt(0).toUpperCase() + string.slice(1)
      : string;
  }

  static zoomOut(opts) {
    window.zooming = true;
    opts = _.extend(
      {
        level: '0.5',
        direction: 'center'
      },
      opts
    );

    $('body').css({
      background: 'transparent',
      transitionDuration: '0ms',
      transformOrigin: 'top ' + opts.direction,
      height: 'calc(100% / ' + opts.level + ')'
    });

    let scrollPosition = $(window).scrollTop(),
      newScrollPosition = scrollPosition * opts.level;
    $('html, body').animate(
      {
        scrollTop: newScrollPosition
      },
      250
    );

    setTimeout(function() {
      $('body').css({
        transitionDuration: '800ms',
        transform: 'translate3d(0, 0, 0) scale(' + opts.level + ')'
      });
    });

    $('html').css({
      height: 'calc(100% / ' + opts.level + ')',
      background: '#444'
    });
    setTimeout(function() {
      window.zooming = false;
      window.zoomScale = opts.level;
      if (opts.complete) {
        opts.complete();
      }
    }, 800);
  }

  static zoomIn(opts) {
    window.zooming = true;
    opts = _.extend(
      {
        level: 0.5,
        direction: 'center',
        noScroll: false
      },
      opts
    );
    if (!opts.noScroll) {
      let scrollPosition = $(window).scrollTop(),
        newScrollPosition = scrollPosition / opts.level;
      $('html, body').animate(
        {
          scrollTop: newScrollPosition
        },
        800
      );
    }
    $('body').css({
      transform: ''
    });
    setTimeout(function() {
      window.zooming = false;
      window.zoomScale = 1;
      $('body').css({
        height: ''
      });
      $('html').css({
        height: 'auto',
        background: ''
      });
      if (opts.complete) {
        opts.complete();
      }
    }, 900);
  }

  /**
   * @deprecated Use $u.zoomOut and $u.zoomIn instead
   * @returns {{zoomOut: zoomOut, zoomIn: zoomIn}}
   */
  static get body() {
    return {
      zoomOut: function(opts) {
        console.warn('DEPRECATED: use $u.zoomOut instead');
        $u.zoomOut(opts);
      },
      zoomIn: function() {
        console.warn('DEPRECATED: use $u.zoomIn instead');
        $u.zoomIn(...arguments);
      }
    };
  }

  static loading(hide = false, text, progress) {
    let mountEl = document.getElementById("loading-overlay-wrapper");
    if (!mountEl) {
      mountEl = document.createElement('div');
      mountEl.id = 'loading-overlay-wrapper';
      document.body.append(mountEl);
    }

    if (hide) {
      $('.editor-wrapper').css({
        filter: 'none',
        '-webkit-filter': 'none',
        '-moz-filter': 'none',
        '-o-filter': 'none',
        '-ms-filter': 'none '
      });
      $('#overlay').animate({opacity: 0}, 300, function() {
          $('#overlay').remove();
      });
    }

    ReactDOM.render(<Loading fullScreen unmount={hide} text={text} progress={progress} />, mountEl);
  }
}

class Utils {
  constructor() {
    return this;
  }
}

export {$u};
