import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {ApplicationFormPage, ApplicationFormPages} from './ApplicationFormPage';
import {ApplicationFormField, ApplicationFormFields} from './ApplicationFormField';
import Validator from 'validatorjs';
import {File} from './File';

class ApplicationForm extends Model {

  get modelUrl() {
    return '/application-forms';
  }

  get defaults() {
    return {
      type: 'application_form'
    };
  }

  isComplete(application, language) {
    const answers = application.get('answers');
    const fields = this.get('application_form_fields');
    const rules = Object.fromEntries(fields.map((field) => {
      if (field.get('required')) {
        return [field.get('id'), 'required'];
      }
    }).filter((f) => {
      return typeof f !== 'undefined';
    }));
    const validation = new Validator(answers, rules, {
      required: language === 'french' ? 'Ce champ est requis.' : 'This field is required.'
    });
    return [validation.passes(), validation.errors.all()];
  }

  loadNecessaryInfo({application}){
    return new Promise((resolve, reject) => {
      this.ensureLoaded().then(() => {
        this.get('application_form_pages')._includedRelations = ['application_form_field'];
        this.get('application_form_pages').getAll().then((pages) => {
          let promises = [];
          pages.each((p) => {
            p.get('application_form_fields').each((f) => {
              if (f.hasOptions()) {
                promises.push(f.get('application_form_field_options').getAll());
              } else if (f.get('field_type') === 'Document') {
                const answer = application.get('answers')[f.get('id')];
                if (answer) {
                  const file = File.findOrCreate({id: answer});
                  promises.push(file.fetch({includeHidden: true}));
                }
              }
            })
          });
          Promise.all(promises).then(() => {
            resolve();
          });
        });
      }).catch((e) => {
        console.log('FAILED: ', e);
      });
    });
  }

}

ApplicationForm.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'application_form_pages',
    relatedModel: ApplicationFormPage,
    collectionType: ApplicationFormPages,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'application_form'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'application_form_fields',
    relatedModel: ApplicationFormField,
    collectionType: ApplicationFormFields,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'application_form'
    }
  }
];

class ApplicationForms extends Collection {}
ApplicationForms.prototype.model = ApplicationForm;

export {ApplicationForms, ApplicationForm}
