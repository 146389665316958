import React from 'react';
import _ from 'underscore';
import CarouselSlide from './CarouselSlide';
import {Helper} from '../../../Helpers';

class JumbotronCarousel extends React.Component {
  constructor() {
    super(...arguments);
    this.slideActionClicked = this.slideActionClicked.bind(this);
    this.fontFamilyChanged = this.fontFamilyChanged.bind(this);
    this.slideWidth = 0;
    this.slideHeight = 0;
    this.maxAspectRatio = 0;
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.nextSlideClicked = this.nextSlideClicked.bind(this);
    this.previousSlideClicked = this.previousSlideClicked.bind(this);
    this.slideChanged = this.slideChanged.bind(this);
    this.setSlideSize = this.setSlideSize.bind(this);
    this.state = {
      slideIndex: 0,
      allAnimations: []
    };
    this.nextSlideInterval = -1;
  }

  slideActionClicked(e) {
    if (e) {
      //If it is a real click, not just the interval
      e.preventDefault();
      e.stopPropagation();
      this.resetInterval();
    }
  }

  nextSlideClicked(e) {
    this.slideActionClicked(e);
    this.setState(
      {
        slideIndex: this.state.slideIndex + 1
      },
      this.nextSlide
    );
  }

  previousSlideClicked(e) {
    this.slideActionClicked(e);
    let slideIndex = this.state.slideIndex;
    if (slideIndex === 0) {
      slideIndex = this.getFilteredSlides().length - 1;
    } else {
      slideIndex -= 1;
    }
    this.setState(
      {
        slideIndex: slideIndex
      },
      this.previousSlide
    );
  }

  fontFamilyChanged(fonts) {
    let extantFonts = this.props.section.prop('fonts') || [];
    extantFonts.forEach(font => {
      fonts.push(font);
    });
    let uniqueFamilies = [];
    $.each(fonts, function(i, el) {
      if ($.inArray(el, uniqueFamilies) === -1) uniqueFamilies.push(el);
    });
    uniqueFamilies.sort((a, b) => {
      return a > b;
    });
    if (uniqueFamilies.join(',') !== extantFonts.join(',')) {
      this.props.section.prop('fonts', uniqueFamilies);
      FW.store.get('page').getFonts();
    }
  }

  checkNumberOfSlides = () => {
    clearTimeout(this.slideTimeout);
    this.slideTimeout = setTimeout(() => {
      if (this.getFilteredSlides().length === 1) {
        $('.carousel-control, .carousel-indicators').addClass('hide');
      } else {
        $('.carousel-control, .carousel-indicators').removeClass('hide');
      }
    }, 500);
  };

  componentDidMount() {
    this.checkNumberOfSlides();
    this.props.section.on(
      'change',
      () => {
        this.forceUpdate();
      },
      this
    );
    this.resetInterval();
  }

  componentDidUpdate() {
    let section = this.props.section;
    if (this.props.size && this.props.size.height && this.props.size.width) {
      let size = {
        height: this.props.size.height / (window.zoomScale || 1),
        width: this.props.size.width / (window.zoomScale || 1)
      };
      if (FW.inEditor()) {
        section.prop('apparent_size', size);
        if (typeof section.prop('aspect_ratio') === 'undefined') {
          section.prop(
            'aspect_ratio',
            Helper.getAspectRatio(size.width, size.height)
          );
        }
      }
      let ratio = this.props.section.prop('aspect_ratio') || {};
      let real = Helper.getAspectRatio(size.width, size.height);
      let correctHeight = (size.width / ratio.width) * ratio.height;
      if (
        ratio.height !== real.height &&
        this.state.displayHeight !== correctHeight &&
        section.prop('lock_aspect_ratio')
      ) {
        this.setState({
          displayHeight: correctHeight
        });
      }
    }

    this.checkNumberOfSlides();
  }

  getFilteredSlides = () => {
    return _.filter(this.props.section.prop('slides'), function(slide){
      return FW.inEditor() || slide.hide_slide !== true;
    });
  };

  resetInterval() {
    if (this.nextSlideInterval) {
      window.clearInterval(this.nextSlideInterval);
    }
    let intervalSeconds = this.props.section.prop('interval') || 5;
    if (
      !FW.inEditor() &&
      intervalSeconds !== -1 &&
      this.getFilteredSlides().length !== 1 &&
      !this.state.slidesPaused
    ) {
      this.nextSlideInterval = window.setInterval(
        this.nextSlideClicked,
        intervalSeconds * 1000
      );
    }
  }

  nextSlide() {
    let numSlides = this.getFilteredSlides().length;
    let slideIndex = this.state.slideIndex % numSlides;
    let previousIndex = (this.state.slideIndex - 1) % numSlides;
    let toBeAnimatedSlide = $(
      '#animationContainer-' + this.props.section.get('id') + '-' + slideIndex
    );
    let stopAnimatingSlide = $(
      '#animationContainer-' +
        this.props.section.get('id') +
        '-' +
        previousIndex
    );
    this.slideTransition(
      toBeAnimatedSlide,
      stopAnimatingSlide,
      'nextSlideEntrance',
      'nextSlideExit'
    );
  }

  previousSlide() {
    let numSlides = this.getFilteredSlides().length;
    let slideIndex = this.state.slideIndex % numSlides;
    let previousIndex = (this.state.slideIndex + 1) % numSlides;
    let toBeAnimatedSlide = $(
      '#animationContainer-' + this.props.section.get('id') + '-' + slideIndex
    );
    let stopAnimatingSlide = $(
      '#animationContainer-' +
        this.props.section.get('id') +
        '-' +
        previousIndex
    );
    this.slideTransition(
      toBeAnimatedSlide,
      stopAnimatingSlide,
      'previousSlideEntrance',
      'previousSlideExit'
    );
  }

  slideTransition(
    toBeAnimatedSlide,
    stopAnimatingSlide,
    animationType,
    exitAnimationType
  ) {
    let section = this.props.section;
    let toBeAnimated = toBeAnimatedSlide.find('.slideAnimated');
    let stopAnimating = stopAnimatingSlide.find('.slideAnimated');
    toBeAnimatedSlide.css('display', 'inline-block');
    toBeAnimated.off(
      'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd'
    );
    this.clearAnimations(toBeAnimated);
    this.addAnimation(
      toBeAnimated,
      this.getAnimationClass('slide', animationType)
    );
    this.clearAnimations(stopAnimating);
    this.addAnimation(
      stopAnimating,
      this.getAnimationClass('slide', exitAnimationType)
    );
    stopAnimating.one(
      'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
      function() {
        stopAnimatingSlide.css('display', 'none');
      }
    );
    if (
      !section.getAnimationType(
        section.getAnimation('slide'),
        exitAnimationType
      ) &&
      section.hasAnimations()
    ) {
      stopAnimatingSlide.css('display', 'none');
    }
  }

  getAnimationClass(trigger, type) {
    const section = this.props.section;
    if (section.hasAnimations()) {
      return section.getAnimationClass(trigger, type);
    } else {
      if (trigger === 'slide') {
        let animationType = '';
        switch (type) {
          case 'nextSlideEntrance':
            animationType = 'slideInRight';
            break;
          case 'nextSlideExit':
            animationType = 'slideOutLeft';
            break;
          case 'previousSlideEntrance':
            animationType = 'slideInLeft';
            break;
          case 'previousSlideExit':
            animationType = 'slideOutRight';
            break;
        }
        return 'animated ' + animationType;
      }
      return '';
    }
  }

  addAnimation($element, animationClass) {
    if (!this.state.allAnimations.includes(animationClass)) {
      this.state.allAnimations.push(animationClass);
    }
    $element.addClass(animationClass);
  }

  clearAnimations($element) {
    this.state.allAnimations.forEach(animationClass => {
      $element.removeClass(animationClass);
    });
  }

  setSlideSize(width, height) {
    this.slideWidth = width;
    this.slideHeight = height;
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.props.section.off(null, null, this);
  }

  slideChanged(slideIndex, value) {
    let slides = _.clone(this.props.section.prop('slides'));
    slides[slideIndex].content = value;
    this.props.section.prop('slides', slides);
  }

  jumbotronHover = () => {
    if (this.props.section.prop('pause_on_hover')) {
      this.setState({
        slidesPaused: true
      }, () => {
        this.resetInterval()
      });
    }
  }

  jumbotronLeave = () => {
    if (this.props.section.prop('pause_on_hover')) {
      this.setState({
        slidesPaused: false
      }, () => {
        this.resetInterval()
      });
    }
  }

  render() {
    let section = this.props.section;
    const slides = this.getFilteredSlides();

    const id = `#carousel-${section.get('id')}`;
    if (FW.inEditor()) $(id).off('keydown');
    let slideIndicators = slides.map((slide, i) => {
      let className =
        i === this.state.slideIndex % slides.length
          ? 'active'
          : null;
      return (
        <li
          key={`slide-indicator-${i}`}
          onClick={this.slideActionClicked}
          className={className}
          data-target={`#carousel-${section.get('id')}`}
          data-slide-to={i}
        />
      );
    });

    let elementHeight = section.prop('height');
    let elementWidth = '100%';
    let outerEl = $('#PageSection_' + section.get('id'));
    if (
      section.prop('contain') &&
      this.slideWidth > 0 &&
      this.slideHeight > 0
    ) {
      elementHeight = Math.round(
        (outerEl.outerWidth() * this.slideHeight) / this.slideWidth
      );
    }

    if (this.state.displayHeight && section.prop('lock_aspect_ratio')) {
      elementHeight = this.state.displayHeight;
    }
    elementHeight = Math.round(elementHeight / 30) * 30;

    let displaySlides = slides.map((slide, i) => {
      return (
        <CarouselSlide
          constrainDirection={this.state.constrainDirection}
          constrain={section.prop('constrain')}
          fontFamilyChanged={this.fontFamilyChanged}
          key={`slide-${i}`}
          slider={this}
          height={elementHeight}
          slide={slide}
          slideIndex={i}
          visible={this.state.slideIndex % slides.length === i}
          {...this.props}
          slideChanged={this.slideChanged}
        />
      );
    });

    elementHeight = elementHeight === 0 ? 100 : elementHeight;

    return (
      <div
        style={{height: elementHeight}}
        onMouseOver={this.jumbotronHover}
        onMouseLeave={this.jumbotronLeave}
      >
        <div
          className="fwCarousel"
          id={`carousel-${section.get('id')}`}
          style={{
            height: elementHeight,
            maxHeight: elementHeight,
            width: elementWidth,
            margin: '0 auto',
            maxWidth: '100%'
          }}
        >
          <ol className="carousel-indicators">{slideIndicators}</ol>
          <div className="fwCarousel-inner" style={{height: '100%'}}>
            {displaySlides}
          </div>
          <span
            className="left carousel-control"
            onClick={this.previousSlideClicked}
            role="button"
            data-slide="prev"
          >
            <span
              className="glyphicon glyphicon-chevron-left"
              aria-hidden="true"
            />
            <span className="sr-only">Previous</span>
          </span>
          <span
            className="right carousel-control"
            onClick={this.nextSlideClicked}
            role="button"
            data-slide="next"
          >
            <span
              className="glyphicon glyphicon-chevron-right"
              aria-hidden="true"
            />
            <span className="sr-only">Next</span>
          </span>
        </div>
      </div>
    );
  }
}

export default JumbotronCarousel;
