import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';

class EventCategory extends Model {
  get modelUrl() {
    return '/event-categories';
  }

  get defaults() {
    return {
      type: 'event_category'
    };
  }

  static searchKeys = ['title'];
}
EventCategory.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  }
];

class EventCategories extends Collection {}

EventCategories.prototype.sortField = 'title';
EventCategories.prototype.sortOrder = 'ASC';
EventCategories.prototype.model = EventCategory;

export {
  /**
   * The blog model
   */
    EventCategory,
  /**
   * Collection of Blog models
   */
    EventCategories
};
