import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {ApplicationReference, ApplicationReferences} from './ApplicationReference';
import {ApplicationForm, ApplicationForms} from './ApplicationForm';
import {ApplicationStatus} from './ApplicationStatus';

class Application extends Model {

  get modelUrl() {
    return '/applications';
  }

  get defaults() {
    return {
      type: 'application'
    };
  }

  getStatus() {
    const langString = this.get('language') === 'french' ? 'title_french' : 'title';
    return this.get('application_status') ? this.get('application_status').get(langString) : 'Unsubmitted';
  }

  isSubmitted(){
    return this.get('submitted_at') !== null && typeof this.get('submitted_at') !== 'undefined' && (this.get('additional_info_requested_at') === null || typeof this.get('additional_info_requested_at') === 'undefined');
  }

}

Application.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'application_references',
    relatedModel: ApplicationReference,
    collectionType: ApplicationReferences,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'application'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'application_status',
    relatedModel: ApplicationStatus
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'application_form',
    relatedModel: ApplicationForm
  }
];

class Applications extends Collection {}
Applications.prototype.model = Application;

export {Applications, Application}
