import {Model} from './Model';
import {Collection} from './Collection';

class FileType extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/file-types';
  }

  /**
   */
  get defaults() {
    return {
      type: 'file_type'
    };
  }
}

class FileTypes extends Collection {}
FileTypes.prototype.model = FileType;

export {FileType, FileTypes};
