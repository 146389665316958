import {Model} from './Model';
import {Collection} from './Collection';

class FormFieldOption extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/form-field-options';
  }

  /**
   */
  get defaults() {
    return {
      type: 'form_field_option'
    };
  }

  getCurrencyValue() {
    return this.get('currency_value') === null ||
      typeof this.get('currency_value') === 'undefined'
      ? 0
      : this.get('currency_value');
  }
}

class FormFieldOptions extends Collection {}
FormFieldOptions.prototype.model = FormFieldOption;
FormFieldOptions.prototype.sortField = 'order';
FormFieldOptions.prototype.sortOrder = 'ASC';

FormFieldOption.prototype.relations = [];

export {FormFieldOption, FormFieldOptions};
