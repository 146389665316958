import {Model} from './Model';
import {Collection} from './Collection';

class SiteBlueprint extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/site-blueprints';
  }

  /**
   */
  get defaults() {
    return {
      type: 'site_blueprint'
    };
  }
}

class SiteBlueprints extends Collection {}
SiteBlueprints.prototype.model = SiteBlueprint;
SiteBlueprints.prototype.sortField = 'order_id';
SiteBlueprints.prototype.sortOrder = 'ASC';

SiteBlueprint.prototype.relations = [];

export {SiteBlueprint, SiteBlueprints};
