import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import config from 'config';
// import {PayPalGroups, PayPalGroup} from "./PayPalGroup";

class PayPalItem extends Model{

  get modelUrl(){
    return '/paypal-items';
  }

  get defaults() {
    return {
      type: 'paypal_item'
    };
  }

}
PayPalItem.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'paypal_groups',
    relatedModel: 'FW.Models.PayPalGroup',
    collectionType: 'FW.Models.PayPalGroups',
    collectionOptions: Model.defaultCollectionOptions,
    includeInJSON: false
  }
];

class PayPalItems extends Collection{}
PayPalItems.prototype.model = PayPalItem;
PayPalItems.prototype.sortField = 'sort_order';
PayPalItems.prototype.sortOrder = 'ASC';

PayPalItems.prototype.saveOrder = function(){
  const groupId = this.parent.get('id');
  const order = this.map((i) => {
    return {id: i.get('id'), sort_order: i.get('sort_order')};
  });
  $.post(config.API_ROOT + '/paypal-groups/' + groupId + '/save-group-order', {items: order}).then((r) => {});
};

export {
  /**
   * The blog model
   */
  PayPalItem,

  /**
   * Collection of Blog models
   */
  PayPalItems
};
