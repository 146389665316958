import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import Customer from './Customer';
import {LiveStreamChannelStream, LiveStreamChannelStreams} from './LiveStreamChannelStream';
import config from '../../config';

class LiveStreamChannel extends Model{

  get modelUrl(){ return '/live-stream-channels'; }

  get defaults(){
    return {
      type: 'live_stream_channel'
    };
  }

  static searchKeys = [
    'title'
  ];

  get archiveWowzaPath(){
    return 'https://5947e8815ddf6.streamlock.net/' + this.appId + '_archive/';
  }

  get archiveWowzaRtmpPath(){
    return 'http://live.finalweb.net:1935/' + this.appId + '_archive/';
  }

  get liveWowzaPath(){
    return 'https://5947e8815ddf6.streamlock.net/' + this.appId + '/';
  }

  get archiveWebRoot(){
    return 'https://livestreamarchive.finalweb.net:4433/gdr43a78HUDHG5638dyytvava654FRTftrtrFyu/' + this.appId + '_archive/';
  }

  get liveWowzaSources(){
    // websockets is h264 and acc, so we don't need to transcode it live.
    const append = this.get('wowza_transcoding') === 1 || this.get('use_websockets') ? '' : '_final';
    const firstSource = !this.get('fastly') ? {file: 'https://5947e8815ddf6.streamlock.net/' + this.appId + append + '/' + 'smil:myStream.smil/playlist.m3u8'} : {file: (this.get('custom_fastly_target') ? this.get('cdn_url') : 'https://cdn3.wowza.com/5/bHJBN2lvTkpYOVFL/') + this.appId + append + '/' + 'smil:myStream.smil/playlist.m3u8'};
    let sources = [
      firstSource,
      {file: 'rtmp://live.finalweb.net:1935/' + this.appId + append + '/mp4:myStream'}
    ];
    if (this.get('cdn_url') !== null && !this.get('fastly')) {
      sources.unshift({file: this.get('cdn_url')});
    }
    return sources;
  }

  get appId(){
    return this.get('cust_stream_id') + this.get('stream_path');
  }

  isStreaming() {
    return $.get(config.API_ROOT + '/live-stream-channels/' + this.get('id') + '/is-streaming', (response) => {
      return response;
    });
  }

  openPublisher(){
    if (!this.get('use_websockets')) {
       let openPublisher = async () => {
        this.set({
          wowza_transcoding: 0,
          use_webrtc: 0,
          use_websockets: 1,
          publish_method: 2
        });
        await this.save();
        window.open('/admin/live-streams/' + this.get('id') + '/publish-websockets');
      };
      openPublisher();
    } else {
      window.open('/admin/live-streams/' + this.get('id') + '/publish-websockets');
    }
  }

}
LiveStreamChannel.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'customer',
    relatedModel: Customer
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'streams',
    relatedModel: LiveStreamChannelStream,
    collectionType: LiveStreamChannelStreams,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'channel'
    }
  }
];

class LiveStreamChannels extends Collection{}
LiveStreamChannels.prototype.model = LiveStreamChannel;

export {LiveStreamChannel, LiveStreamChannels};
