import {Backbone} from 'FWBackbone';
import {TemplateSetting} from './TemplateSetting';
import {Template} from './Template';
import {Model} from './Model';
import {Collection} from './Collection';
import {Menu, Menus} from './Menu';
import Customer from './Customer';
import config from 'config';
import prop from './Decorators/Prop';
import hasBackground from './Decorators/HasBackground';
import {DomainName, DomainNames} from './DomainName';

@prop
@hasBackground
class Site extends Model{

  get modelUrl(){ return '/sites'; }

  get defaults(){
    return {
      type: 'site'
    };
  }

  get awsPath(){
    return '/' + (this.get('aws_s3_root') ?
      this.get('aws_s3_root') : 'customers/' + this.get('customer_id') + '/sites/' + this.get('id') + '/')
  }

  get awsUrl(){
    return config.CLOUDFRONT_URL + this.awsPath;
  }

  get realAwsUrl(){
    return 'https://' + config.AWS_BUCKET + '.' + config.AWS_ROOT + this.awsPath;
  }

  get activeDomain(){
    let currentDomain = document.domain;
    let domains = this.get('domain_names');
    let d = null;
    domains.each((domain) => {
      if (domain.get('url') === currentDomain){
        d = domain;
      }
    });
    return d;
  }

  s3Url(path, direct){
    let base = direct ? this.realAwsUrl : this.awsUrl;
    return base + path;
  }

  ls(key, value){
    return this.prop('layoutSettings.' + key, value);
  }

  showBackground(){
    let ls = this.prop('layoutSettings');
    return typeof ls !== 'undefined';
  }

}
Site.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'template_settings',
    relatedModel: TemplateSetting,
    reverseRelation: {
      key: 'site',
      type: Backbone.Relational.HasOne,
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'template',
    relatedModel: Template,
    reverseRelation: {
      key: 'site',
      type: Backbone.Relational.HasOne,
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'customer',
    relatedModel: Customer
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'logo',
    relatedModel: 'FW.Models.SiteLogo',
    reverseRelation: {
      key: 'site',
      type: Backbone.Relational.HasOne,
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'menus',
    relatedModel: Menu,
    collectionType: Menus,
    collectionOptions: Model.defaultCollectionOption,
    reverseRelation: {
      key: 'site',
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'files',
    relatedModel: 'FW.Models.File',
    collectionType: 'FW.Models.Files',
    collectionOptions: Model.defaultCollectionOption,
    reverseRelation: {
      key: 'site',
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'domain_names',
    relatedModel: DomainName,
    collectionType: DomainNames,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'site'
    }
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'footer_group',
    relatedModel: 'FW.Models.PageGroup',
    reverseRelation: {
      type: Backbone.Relational.HasOne,
      key: 'site',
      includeInJSON: false
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'app_homepage_tiles',
    relatedModel: 'FW.Models.AppHomepageTile',
    collectionType: 'FW.Models.AppHomepageTiles',
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'site'
    }
  }
];

class Sites extends Collection{}
Sites.prototype.model = Site;

export {Site, Sites};
