import React from 'react';
import {hoc} from './BlogPreview';
import {File as FWFile} from 'FW/Models/File';
import _ from 'underscore';

class BlogPreviewList extends React.Component{

  render(){
    const advancedOptions = this.props.section.get('properties').advanced_options || {};
    const opts = this.props.section.prop('basic_options') || {};

    let posts = this.props.posts.map((post) => {
      let backgroundUrl;
      let imageId = post.get('thumbnail_image') ? post.get('thumbnail_image') : post.get('featured_image');
      if (imageId){
        let background = FWFile.findOrCreate({id: imageId});
        backgroundUrl = background.helper.thumbnailUrl(800);
      } else {
        backgroundUrl = null;
      }

      let styleDefaults = {
        height: opts.height || '230px',
        minWidth: '230px',
        display: 'block',
        backgroundSize: opts.background_size || 'cover',
        backgroundPosition: opts.background_position || 'center',
        backgroundRepeat: 'no-repeat'
      };
      let style = backgroundUrl ? _.defaults(styleDefaults, {backgroundImage: 'url(' + backgroundUrl + ')'}) : styleDefaults;

      return <div key={'post-' + post.get('id')} className="col-md-12">
        <div className="thumbnail col-md-12" style={{padding: 9}}>
          <a href={post.getUrl()} style={style} className="col-md-4" aria-label={post.get('title')} />
          <div className="col-md-8">
            <h3 style={{marginTop: 0}}><a href={post.getUrl()} aria-label={post.get('title')}>{post.get('title')}</a></h3>
            <p dangerouslySetInnerHTML={{__html: post.getPreview(advancedOptions.characters_to_display, opts.show_all_line_breaks)}}></p>
            <a href={post.getUrl()} className="btn btn-default" role="button" aria-label={"Read More"}>Read More</a>
          </div>
        </div>
      </div>;
    });

    return (
      <span className="col-md-12">
        {posts}
      </span>
    );
  }

}

export default hoc(BlogPreviewList);
