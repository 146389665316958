// TODO: NEEDS TO BE MOVED SOMEWHERE MORE APPROPRIATE
let insertGroupIntoPage = function(data) {
  return new Promise(function(complete) {
    if (data.page && data.page.get('id') !== Page.get('id')) {
      //If we are cloning a page
      complete(data.group);
      return;
    }
    let group = data.group,
      page = Page;
    const groupLayoutID = group.get('layout').get('id');
    if (typeof window.$p === 'undefined') window.$p = $;
    $p('.group-hover').removeClass('group-hover');
    $p('.highlight').removeClass('highlight');
    const groups = page.groups || page.get('groups');
    let last = $p('*[data-fw-model="PageGroup"]').last();
    if (groupLayoutID === 3 && groups.length > 2) {
      //For overlap groups. If there is more than one group besides the overlap group, place the overlap group second-to-last instead of last
      if (
        groups
          .last()
          .prev()
          .prev()
      ) {
        last = groups
          .last()
          .prev()
          .prev()
          .element();
        // groups.last().set('order_id', groups.last().get('order_id')+1);
        // group.set('order_id', group.get('order_id')-1);
        group.save({order_id: group.get('order_id') - 1});
        groups
          .last()
          .prev()
          .save({
            order_id:
              groups
                .last()
                .prev()
                .get('order_id') + 1
          });
      }
    }
    let groupEl = group.element();

    groupEl.css({display: 'block'});
    groupEl.addClass('group-hover');
    let height = groupEl.outerHeight();
    last.css({display: 'block'});
    let oldTransition = $p('body').css('transition');
    $p('body').css({
      height: 'auto',
      paddingBottom: 500,
      transition: 'padding-bottom 1s'
    });
    $p(last).center(last.outerHeight() + $p(window).height() / 2);
    last.css({display: ''});
    groupEl.css({height: '0px', overflow: 'hidden'});
    setTimeout(function() {
      //$p(groupEl).highlight();
      groupEl.css({
        width: '100%',
        transition: 'height 1s, box-shadow 1.2s'
      });
      groupEl.css({
        height: height
      });
      $p('body').css({
        paddingBottom: 0
      });
      setTimeout(function() {
        $p(groupEl).center();
        setTimeout(function() {
          $p('body').css({
            height: '',
            transition: oldTransition
          });
        }, 1000);
        complete(group);
        groupEl.css({
          display: '',
          height: '',
          transition: '',
          overflow: '',
          width: ''
        });
      }, 1000);
    }, 1);
  });
};

export {insertGroupIntoPage}
