import _ from 'underscore';

let updatePage = function(){
  if (window.parent.Page) {
    window.parent.Page.set('editing_started', true);
  }
  if (global.Page) {
    global.Page.set('editing_started', true);
  }
};

function updatesPage(target) {

  return class extends target{

    constructor(){
      super(...arguments);
      if (FW.inEditor()) {
        this.on('add remove', function(){
          updatePage();
        });
        this.on('sync', function(model, evt) {
          if ((_.keys(this.justSaved).length > 0) && Page) {
            updatePage();
          }
        });
      }
    }
  };
}

export default updatesPage;
