import NoWrapperGroupView from './NoWrapperGroupView';
import StandardGroupView from './StandardGroupView';
import OverlapGroupView from './OverlapGroupView';
import FooterGroupView from './FooterGroupView';

export default {
  'no-wrapper': NoWrapperGroupView,
  'standard': StandardGroupView,
  'overlap': OverlapGroupView,
  'footer': FooterGroupView
};
