import {Model} from './Model';
import {Collection} from './Collection';
import {BlogPost, BlogPosts} from './BlogPost';
import {Backbone} from '../../FWBackbone';
import {Site} from './Site';
import {BlogSubscriber, BlogSubscribers} from './BlogSubscriber';
import {Blog} from './Blog';
import {Comment, Comments} from './Comment';

class CommentWall extends Model{

  get modelUrl() {
    return '/comment-walls';
  }

  get defaults() {
    return {
      type: 'comment_wall'
    };
  }

  get commentsAllowed(){
    let user = FW.store.get('user');
    let site = FW.store.get('site');
    return (
      ((!this.get('allow_anonymous_comments') &&
        user &&
        user.get('customer_id') === site.get('customer_id')) ||
        this.get('allow_anonymous_comments'))
    );
  }

}
CommentWall.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'comments',
    relatedModel: Comment,
    collectionType: Comments,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'comment_wall'
    }
  }
];

class CommentWalls extends Collection{}
CommentWalls.prototype.model = CommentWall;

export {CommentWall, CommentWalls}

