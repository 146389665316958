import {Model} from './Model';
import {Collection} from './Collection';
import {FormFieldOption, FormFieldOptions} from './FormFieldOption';
import prop from './Decorators/Prop';
import layoutable from './Decorators/Layoutable';
import _ from 'underscore';
import {withSize as sizeMe} from 'react-sizeme';
import {Backbone} from 'FWBackbone';
import $ from 'jquery';
import config from '../../config';
import React, {useEffect, useState, useRef} from 'react';
let FormFieldComponents = {};

import('Page/Sections/Form/FormFields').then((mod) => {
  FormFieldComponents = mod.default;
});

const getBodyScale = function() {
  const regExp = /scale\(([^)]+)\)/;
  let doc = window.name !== 'editFrame' ? (FW.editor ? FW.editor.getIframeWindow().document : document) : document;
  const bodyTransform = doc && doc.body ? doc.body.style.transform : '';
  const matches = regExp.exec(bodyTransform);
  return matches ? matches[1] : 1;
}

const SizeMeWrapper = (props) => {
  const sectionWrapper = useRef();
  var [size, setSize] = useState({width: sectionWrapper.current ? sectionWrapper.current.offsetWidth : 0, height: sectionWrapper.current ? sectionWrapper.current.offsetHeight : 0});
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const scale = getBodyScale();
      setSize({height: entries[0].target.offsetHeight * scale, width: entries[0].target.offsetWidth * scale});
    })
    // start observing a DOM node
    resizeObserver.observe(sectionWrapper.current)
  }, []);
  useEffect(() => {
    props.finishedRendering(props.field, size);
  }, [size.height, size.width]);

  return <div ref={sectionWrapper}>
    <props.comp {...props} size={size} />
  </div>;
};

@prop
@layoutable('field', 'form')
class FormField extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/form-fields';
  }

  /**
   */
  get defaults() {
    return {
      type: 'form_field'
    };
  }

  applyLayoutDefaults(layoutDefaults = {}) {
    let gridLayouts = _.clone(this.prop('grid_layouts'));
    _.each(layoutDefaults, (layout, breakpoint) => {
      gridLayouts[breakpoint] = _.extend(gridLayouts[breakpoint], layout);
    });
    this.prop('grid_layouts', gridLayouts);
    return true;
  }

  getReactComponent() {
    if (!this._reactClass) {
      if (typeof FormFieldComponents[this.get('field_type')] !== 'undefined') {
        this._reactClass = (props) => {
          return <SizeMeWrapper {...props} comp={FormFieldComponents[this.get('field_type')]} />
        };
      } else {
        return function(){return <div></div>};
      }
    }
    return this._reactClass;
  }

  element() {
    let j = window.$p ? window.$p : $;
    return j('*[data-field-id="' + this.get('id') + '"]');
  }

  get rules() {
    let rules = [];
    if (this.prop('required')) {
      rules.push('required');
    }
    if (this.get('field_type') === 'Email') {
      rules.push('email');
    }
    return rules.join('|') || null;
  }

  hasCurrency() {
    let options = this.prop('options');
    let hasCurrency = false;
    if (options) {
      _.each(options, option => {
        if (parseFloat(option.currency_value) > 0) {
          hasCurrency = true;
        }
      });
    }
    return hasCurrency;
  }

  get(value) {
    if (value === 'options') {
      return this.get('field_options');
    }
    return super.get(...arguments);
  }

  duplicate(): Promise<void> {
    const id = this.get('id');
    const form = this.get('form');
    if (parent) {
      parent.$u.loading();
    } else {
      $u.loading();
    }
    return new Promise(complete => {
      $.post(config.API_ROOT + '/form-fields/' + id + '/copy', {}).then(d => {
        const field = new FormField(d.field);
        form.get('fields').add(field);
        if (parent) parent.$u.loading(true);
        else $u.loading(true);
        complete();
      });
    });
  }

  getDefaultValue() {
    let value = 0;
    _.each(this.get('options').where({selected: 1}), option => {
      let val =
        option.get('currency_value') === null
          ? 0
          : parseFloat(option.get('currency_value'));
      value += val;
    });
    return value;
  }

  getSurchargeValue(){
    if (typeof this.prop('surcharge_value') === 'undefined') {
      return 0;
    } else if (this.prop('surcharge_type') === 'percentage') {
      return this.get('form').getSelectedValue() * (parseFloat(this.prop('surcharge_value')) / 100);
    } else {
      return parseFloat(this.prop('surcharge_value'));
    }
  }

  getSelectedValue() {
    return typeof this._selectedValue !== 'undefined'
      ? this._selectedValue
      : this.getDefaultValue();
  }

  setSelectedValue(value) {
    return (this._selectedValue = value);
  }

  setChecked(value) {
    this._checked = value;
  }

  isChecked() {
    return this._checked;
  }

}

class FormFields extends Collection {
  comparator(one, two){
    let mainBreakpoint = one.getMainBreakpoint() || 'lg';
    let aLayout = one.getLayout(mainBreakpoint);
    let bLayout = two.getLayout(mainBreakpoint);
    if (aLayout.y === bLayout.y) {
      return Math.sign(aLayout.x - bLayout.x);
    }
    return Math.sign(aLayout.y - bLayout.y);
  }
}
FormFields.prototype.model = FormField;

FormField.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'field_options',
    relatedModel: FormFieldOption,
    collectionType: FormFieldOptions,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'field'
    }
  }
];

export {FormField, FormFields};
