import {Model} from './Model';
import _ from 'underscore';
import {Collection} from './Collection';
import {MediaSeries, MediaSeriesCollection} from './MediaSeries';
import {Tag, Tags} from './Tag';
import {FileCategory, FileCategories} from './FileCategory';
import {FileType} from './FileType';
import {Backbone} from 'FWBackbone';
import FileTypes from 'FW/Models/File/index';
import React from 'react';
import {LiveStreamChannel} from './LiveStreamChannel';
import config from 'config';
import moment from 'moment';
import Video from './File/Video';

const fileTypes = {
  audio: {id: 3, types: ['mp3', 'aac', 'wav', 'm4a', 'f4a', 'ogg']},
  video: {id: 2, types: ['mov', 'mp4', 'wma', 'mkv', 'flv', 'vob', 'avi', 'qt', 'm4v', '3gp', 'f4v', 'webm']},
  image: {id: 1, types: ['jpg', 'png', 'jpeg', 'tiff', 'tif', 'gif', 'svg']},
  document: {id: 4, types: ['pdf', 'doc', 'docx']},
  archive: {id: 5, types: ['zip', 'tar', 'gz', 'rar']},
  other: {id: 6, types: []}
};

/**
 * Represents a file
 */
class File extends Model {

  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/files';
  }

  get attrs(){
    return {
      id: 'string'
    };
  }

  /**
   */
  get defaults() {
    return {
      type: 'file'
    };
  }

  hasPermissionsThrough = ['media_libraries'];

  static searchKeys = [
    'description',
    'title',
    'author',
    'display_date',
    'key'
  ];

  static getRemoteExtension(url){
    let regex = /\.([a-zA-Z0-9]*)(?:[?#]|$)/;
    let ret = regex.exec(url);
    return ret ? ret[1] : null;
  }

  get fileType(){
    let ext = null;
    if (['facebook', 'youtube', 'vimeo', 'rumble', 'faithlife_tv'].includes(this.get('location'))) {
      return 'video';
    } else if (this.get('location') === 'remote') {
      let remoteFile = this.get('remote_file');
      ext = remoteFile ? remoteFile.split('.').pop() : '';
      ext = ext ? ext.split('?').shift() : null;
    } else {
      ext = this.get('extension');
    }
    return this.getFileTypeFromExtension(ext);
  }

  get downloadLink(){
    if (this.get('location') === 's3') {
      return `/api/v1/files/${this.get('id')}/download` + (this.get('hidden') ? '?includeHidden' : '');
    } else if (this.originalUrl() && this.originalUrl().indexOf('tulix.tv') >= 0) {
      return `/api/v1/files/${this.get('id')}/download`;
    }
    return this.originalUrl();
  }

  getFileTypeFromExtension(ext){
    return this.getFileTypeObject(ext).type;
  }

  getFileTypeIDFromExtension(ext){
    return this.getFileTypeObject(ext).id;
  }

  getFileTypeObjectForNullExtension(){
    if (this.get('location') === 'youtube' || this.get('location') === 'vimeo'){
      return {
        type: 'video',
        id: fileTypes.video.id
      };
    }
    return {
      type: 'other',
      id: 6
    };
  }

  getFileTypeObject(ext){
    ext = ext ? ext.toLowerCase() : ext;
    let fileType = 'other';
    let id = 6;

    if (ext === null){
      return this.getFileTypeObjectForNullExtension();
    }

    _.each(fileTypes, (type, key) => {
      if (_.indexOf(type.types, ext) >= 0){
        fileType = key;
        id = type.id;
      }
    });
    return {type: fileType, id: id};
  }

  get icon(){
    switch (this.fileType){
      case 'video':
        return 'file-video-o';
      case 'audio':
        return 'file-audio-o';
      case 'archive':
        return 'file-archive-o';
      case 'document':
        return 'file-text';
      case 'image':
        return 'file-picture-o';
      default:
        return 'file';
    }
  }

  static create(attr, options){
    attr = _.extend({
      site_id: FW.store.get('site').get('id')
    }, attr);
    let file = super.create(attr);
    return file;
  }

  get helper(){
    if (this.fileType !== this._helperFileType){
      this._helperFileType = this.fileType;
      let HelperClass = FileTypes[this.fileType];
      this._helper = HelperClass ? new HelperClass(this) : new FileTypes.FileHelper(this);
    }
    return this._helper;
  }

  get backgroundUrl(){
    if (this.get('location') === 's3' && this.get('details').processed_for_background) {
      let s3Folder = this.getS3FolderUrl();
      return s3Folder + '/original/file-background.mp4';
    }
    return this.originalUrl();
  }

  originalUrl(direct){
    if (this.get('location') === 's3') {
      let suffix = '?t=' + moment.utc(this.get('updated_at')).format('X');
      let s3Folder = this.getS3FolderUrl(direct);
      let path = (this.get('details') && this.get('details').video_transcoded) ? '/transcoded/file.mp4' : '/original/file.' + this.get('extension');

      if (this.get('visibility') === 'private') {
        return this.get('presigned_url');
      }
      return s3Folder && this.get('extension') ? s3Folder + path + suffix : 'https://source.unsplash.com/category/nature/1000x1000';
    } else if (this.get('location') === 'remote' || this.get('location') === 'youtube' || this.get('location') === 'vimeo') {
      return this.get('remote_file');
    } else if (this.get('location') === 'wowza') {
      return this.get('live_stream_channel').archiveWebRoot + this.get('remote_file');
    }
  }

  isDownloadable(){
    const details = this.get('details') || {};
    const isFaithlifeTV = typeof details.faithlife_tv_info !== 'undefined';
    return this.get('location') !== 'youtube' && this.get('location') !== 'vimeo' && !(!!FW.store.get('site').prop('disable_file_downloads')) && !isFaithlifeTV;
  }

  getS3FolderUrl(direct){
    //this.ensureLoaded({required: 'site'});
    if (this.get('sample_file_id') && !this.get('original_id')) {
      return config.AWS_SAMPLE_URL + '/sample-data/files/' + this.get('sample_file_id');
    }
    let site = this.get('site') ? this.get('site') : FW.store.get('site');
    return site ? site.s3Url(this.get('folder_prefix') + this.get('id'), direct) : null;
  }

  getS3Folder(){
    if (this.get('sample_file_id') && !this.get('original_id')) {
      return 'sample-data/files/' + this.get('id');
    }
    let site = this.get('site') ? this.get('site') : FW.store.get('site');
    return site ? site.get('aws_s3_root') + this.get('folder_prefix') + this.get('id') : null;
  }

  getOriginalFolder(){
    return this.getS3Folder() + '/original/';
  }

  thumbnailOrIcon(forceIcon){
    let imageOrIcon = 'image';
    switch (this.get('file_type').get('slug')){
      case 'image':
        imageOrIcon = 'image';
        break;
      case 'video':
        let showIcon = !this.get('processed') && (this.get('location') !== 'youtube' && this.get('location') !== 'vimeo');
        if (showIcon){
          imageOrIcon = 'icon';
        } else {
          imageOrIcon = 'image';
        }
        break;
      default:
        imageOrIcon = 'icon';
        break;
    }
    if (forceIcon) {
      imageOrIcon = 'icon';
    }
    let iconClass = 'file-icon fa fa-' + this.icon;
    return imageOrIcon === 'image' ? <img className="file-thumbnail" src={this.helper.thumbnailUrl(300)} /> : <span className={iconClass} />;
  }

  save(){
    if (!this.get('title')){
      let details = this.get('details');
      this.set('title', details ? details.original_filename : '');
    }
    return super.save(...arguments);
  }

}

class Files extends Collection{}
Files.prototype.model = File;
Files.prototype.sortField = 'display_date';
Files.prototype.sortOrder = 'DESC';

File.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'file_type',
    relatedModel: FileType,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'live_stream_channel',
    relatedModel: LiveStreamChannel,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'media_series',
    relatedModel: MediaSeries,
    collectionType: MediaSeriesCollection,
    collectionOptions: Model.defaultCollectionOptions,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'related_files',
    relatedModel: File,
    collectionType: Files,
    collectionOptions: Model.defaultCollectionOptions,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'tags',
    relatedModel: Tag,
    collectionType: Tags,
    collectionOptions: Model.defaultCollectionOptions,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'categories',
    relatedModel: FileCategory,
    collectionType: FileCategories,
    collectionOptions: Model.defaultCollectionOptions,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'media_libraries',
    relatedModel: 'FW.Models.MediaLibrary',
    collectionType: 'FW.Models.MediaLibraries',
    collectionOptions: Model.defaultCollectionOptions,
    includeInJSON: false
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'featured_image',
    relatedModel: 'FW.Models.File',
    includeInJSON: false
  }
];

export {File, Files};
