import {Model} from './Model';
import {Collection} from './Collection';
import {FormField} from './FormField';
import {Backbone} from 'FWBackbone';

class FormResponseAnswer extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/form-response-answers'; }

  /**
   */
  get defaults(){
    return {
      type: 'form_response_answer'
    };
  }

}

class FormResponseAnswers extends Collection{}
FormResponseAnswers.prototype.model = FormResponseAnswer;

FormResponseAnswer.prototype.relations = [
  {
    key: 'field',
    type: Backbone.Relational.HasOne,
    relatedModel: FormField
  }
];

export {FormResponseAnswer, FormResponseAnswers};
