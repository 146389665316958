import React from 'react';
import {File as FWFile} from 'FW/Models/File';
import InlineEditableElement from 'Components/InlineEditableElement';
import _ from 'underscore';
import 'jquery.appear';

function menuHoc(Component) {
  return class MenuHOC extends React.Component {
    constructor() {
      super(...arguments);
      this.filesFetched = [];
      this.state = {
        hovered: -1
      };
    }
    componentDidMount() {
      const section = this.props.section;
      section.on(
        'change',
        () => {
          this.forceUpdate();
        },
        this
      );
    }

    componentWillUnmount() {
      this.props.section.off(null, null, this);
    }

    mouseOver = i => {
      this.setState({hovered: i});
    };

    mouseLeave = i => {
      this.setState({hovered: -1});
    };

    hexToRgb = hex => {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
      });

      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    };

    rgbaFromHexAndOpacity = (hex, opacity) => {
      let rgba = this.hexToRgb(hex);
      rgba.a = opacity;
      return `rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
    };

    render() {
      let props = _.clone(this.props);
      props.hovered = this.state.hovered;
      props.mouseLeave = this.mouseLeave;
      props.mouseOver = this.mouseOver;
      props.rgbaFromHexAndOpacity = this.rgbaFromHexAndOpacity;
      return <Component {...props} />;
    }
  };
}

class Menu extends React.Component {
  render() {
    let section = this.props.section;
    const options = section.prop('menu_display_options');

    let itemStyle = {
      border:
        options.button_style === 'no_border'
          ? 'none'
          : `${options.border_width}px solid ${options.button_border_color}`,
      color: options.text_color,
      fontSize: options.font_size,
      margin: options.margins,
      padding: options.padding,
      fontFamily: options.font === 'default' ? 'inherit' : options.font,
      backgroundColor: this.props.rgbaFromHexAndOpacity(
        options.button_color,
        options.button_opacity
      ),
      textAlign: 'center',
      display: 'inline-block',
      position: 'relative',
      borderRadius: options.border_radius
    };
    const hoverStyle = {
      color: options.hover_color,
      backgroundColor: this.props.rgbaFromHexAndOpacity(
        options.button_hover_color,
        options.button_opacity
      )
    };

    let items = section.prop('items').map((item, i) => {
      let href = Helper.fixHref(item.href);

      const currentStyle =
        this.props.hovered === i
          ? Object.assign({}, itemStyle, hoverStyle)
          : itemStyle;

      let html = (
        <a
          key={`item-${i}`}
          id={`item-${i}`}
          style={{...currentStyle}}
          onMouseLeave={this.props.mouseLeave.bind(this, i)}
          onMouseOver={this.props.mouseOver.bind(this, i)}
          href={href}
          target={item.target}
        >
          {item.title}
        </a>
      );
      if (options.orientation === 'vertical') {
        html = (
          <div key={i} style={{display: 'block'}}>
            {html}
          </div>
        );
      }
      return html;
    });
    return (
      <div
        id={'menuSection-' + section.get('id')}
        className="menuSection col-md-12"
        style={{
          textAlign: options.text_align,
          display: 'inline-block',
          width: '100%'
        }}
      >
        {items}
      </div>
    );
  }
}

export {menuHoc as hoc};
export default menuHoc(Menu);
