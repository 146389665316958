import React, {useState} from 'react';
import {ModalWrapper} from '../../Editor/Modal/ModalWrapper';
import DateField from '../../Editor/Fields/DateField';
import moment from 'moment';
import {ModalButtons} from '../../Editor/Modal/ModalButtons';
import config from '../../config';

export default function ExportVolunteerModal(props) {
  const [startDate, setStartDate] = useState(moment().startOf('year'));
  const [endDate, setEndDate] = useState(moment().endOf('year'));

  return <ModalWrapper title="Export Volunteers">
    <DateField title="Start Date" onChange={setStartDate} value={startDate} />
    <DateField title="End Date" onChange={setEndDate} value={endDate} />
    <ModalButtons onSave={() => {
      props.onSave(startDate, endDate);
    }} onCancel={props.onCancel} />
  </ModalWrapper>

}
