import React from 'react';

export default function AdminContent({style, height, noPadding, noBottomPadding, left, noBottomButtons, children}){
  height = noBottomButtons ? '100%' : (height || 'calc(100% - 57px)');
  return <div style={{
    height,
    padding: noPadding ? 0 : (left ? 10 : 8 * 3),
    overflowY: 'auto',
    paddingTop: noPadding ? 0 : 10,
    paddingBottom:noBottomPadding ? 0 : null,
    ...style
  }}>
    {children}
  </div>;
}
