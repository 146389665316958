import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

class CheckboxField extends React.Component {
  constructor(props) {
    super(...arguments);
    this.state = {
      checked: props.value === true || props.value === 1
    };
    this.checked = this.checked.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      checked: newProps.value === true || newProps.value === 1
    });
  }

  checked(e, v) {
    if (this.props.onChange) this.props.onChange(v);
    this.setState({
      checked: v
    });
  }

  render() {
    let component = <div style={this.props.style}>
      <FormControlLabel
        className={
          this.props.classes ? this.props.classes.FormControlLabel : null
        }
        control={
          <Checkbox
            className={
              this.props.classes ? this.props.classes.MuiCheckbox : null
            }
            onChange={this.checked}
            checked={this.state.checked}
            indeterminate={this.props.indeterminate}
            style={{padding: 6}}
            disabled={this.props.disabled}
          />
        }
        label={this.props.title}
      />
      {this.props.helperText ? <FormHelperText>{this.props.helperText}</FormHelperText> : null}
      {this.props.children}
    </div>;

    return this.props.isAutoField || this.props.fullWidth ? (
      <div style={{width: '100%', marginBottom: -5}}>{component}</div>
    ) : (
      component
    );
  }
}

export default CheckboxField;
