import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {UserRole} from './UserRole';
import {UserGroup, UserGroups} from './UserGroup';
import _ from 'underscore';
import merge from 'deepmerge';
import clone from 'clone';
import hasPermissions from './Decorators/HasPermissions';
import prop from './Decorators/Prop';
import moment from 'moment';
import config from 'config';
import {Application, Applications} from './Application';

function mergePreferTrue(one, two) {
  let effective = one;
  _.each(two, (value, key) => {
    let oneVal = one[key];
    if (value === true || oneVal === true) {
      effective[key] = true;
    } else if (typeof oneVal === 'object' || typeof value === 'object') {
      effective[key] = merge(oneVal, value);
    }
  });
  return effective;
}

@hasPermissions
@prop
class User extends Model {
  get effectivePermissionDetails() {
    let roleDetails = this.get('role')
      ? this.get('role').get('permission_details')
      : {};
    let myDetails = this.get('permission_details') || {};
    let effective = clone(myDetails);
    this.get('groups').each(group => {
      _.each(group.get('permission_details'), (permissions, siteKey) => {
        effective[siteKey] = mergePreferTrue(
          clone(permissions),
          effective[siteKey] || {}
        );
      });
    });
    _.each(roleDetails, (permission, siteKey) => {
      effective[siteKey] = mergePreferTrue(
        clone(permission),
        effective[siteKey] || {}
      );
    });
    return effective;
  }

  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/users';
  }

  get includedRelations() {
    return ['user_role', 'user_group'];
  }

  get defaults() {
    return {
      type: 'user'
    };
  }

  static searchKeys = ['name', 'username', 'email'];

  hiddenAttributes = ['password', 'password_confirmation'];

  markAsPromptedToSave() {
    this.prop('prompted_to_save_at', moment().toString());
    $.post(config.API_ROOT + '/prompted-to-save');
  }
}
User.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'role',
    relatedModel: UserRole,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'groups',
    relatedModel: UserGroup,
    collectionType: UserGroups,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'applications',
    relatedModel: Application,
    collectionType: Applications,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'user'
    }
  }
];

class Users extends Collection {}
Users.prototype.model = User;

export {User, Users};
