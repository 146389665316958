import React from 'react';
import MomentUtils from '@date-io/moment';
import CheckboxField from './CheckboxField';
import moment from 'moment';

let MuiPickersUtilsProvider, DatePicker;

class DateField extends React.Component{

  constructor(){
    super(...arguments);
    this.state = {
      value: this.props.value,
      noYear: moment(this.props.value).format('YYYY') === '1004'
    };
    import(/* webpackPrefetch: true */ '@material-ui/pickers').then((Pickers) => {
      MuiPickersUtilsProvider = Pickers.MuiPickersUtilsProvider;
      DatePicker = this.props.withTime ? Pickers.DateTimePicker : (this.props.withKeyboard ? Pickers.KeyboardDatePicker : Pickers.DatePicker);
      this.forceUpdate();
    });
    this.dateChanged = this.dateChanged.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props){
    if (props.value !== this.state.value) {
      this.setState({
        value: props.value
      });
    }
  }

  dateChanged(value){
    let v = value ? (this.props.withTime ? value.format('YYYY-MM-DD HH:mm:ss') : value.format('YYYY-MM-DD')) : value;
    this.setState({
      value: v,
      noYear: value && value.format('YYYY') === '1004'
    });
    this.props.onChange(v);
  }

  noYearChanged = (v) => {
    const curValue = moment(this.state.value);
    let newDate;
    if (v) {
      newDate = moment('1004-' + curValue.format('MM') + '-' + curValue.format('DD'));
    } else if (curValue.format('YYYY') === '1004') {
      newDate = moment((new Date().getFullYear()) + '-' + curValue.format('MM') + '-' + curValue.format('DD'));
    }
    this.dateChanged(newDate);
  }

  render(){
    let {description, errors, idOnly, ...args} = arguments; //eslint-disable-line no-unused-vars
    let value;
    if (this.props.displayStoredTime) {
      value = this.state.value === '' ? null : moment.utc(this.state.value);
    } else {
      value = this.state.value === '' ? null : this.state.value;
    }
    const format = (value && (typeof value === 'string') ? value.startsWith('1004') : null) ? 'MMMM Do' : (this.props.withTime ? 'MMMM Do YYYY h:mma' : (this.props.withKeyboard ? 'YYYY-MM-DD' : 'MMMM Do YYYY'));

    const keyboardProps = this.props.withKeyboard ? {
      variant: "inline",
      InputAdornmentProps: { position: "end" },
      autoOk: true
    } : {};
    return <div className={this.props.showNoYear ? "row" : null}>
      <div className={this.props.showNoYear ? "col-md-10" : null}>
        {MuiPickersUtilsProvider ? <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              {...args}
              style={{width: '100%'}}
              name={"date-field-" + this.props.title}
              clearable={this.props.allowClear}
              onChange={this.dateChanged}
              label={this.props.title || 'Post Date'}
              value={value}
              format={format}
              minDate="1004-01-01"
              {...keyboardProps}
            />
        </MuiPickersUtilsProvider> : null}
        {this.props.showNoYear ? <div className="col-md-2" style={{height: 72, position: 'relative'}}>
          <CheckboxField style={{bottom: 0, position: 'absolute'}} title="No Year" value={this.state.noYear} onChange={this.noYearChanged} />
        </div> : null}
      </div>
    </div>;
  }

}

export default DateField;
