import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import {BlogPost, BlogPosts} from './BlogPost';
import {BlogSubscriber, BlogSubscribers} from './BlogSubscriber';

class Blog extends Model {
  get modelUrl() {
    return '/blogs';
  }

  get defaults() {
    return {
      type: 'blog'
    };
  }

  get includedRelations() {
    return ['blog_post'];
  }

  getLink() {
    return '/blog/' + this.get('slug');
  }
}
Blog.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'posts',
    relatedModel: BlogPost,
    collectionType: BlogPosts,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'blog'
    }
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'subscribers',
    relatedModel: BlogSubscriber,
    collectionType: BlogSubscribers,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'blog'
    }
  }
];

class Blogs extends Collection {}
Blogs.prototype.model = Blog;

export {
  /**
   * The blog model
   */
  Blog,
  /**
   * Collection of Blog models
   */
  Blogs
};
