import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {UserPermission, UserPermissions} from './UserPermission';

class UserRole extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/user-roles'; }

  get includedRelations(){
    return ['user_permission'];
  }

  /**
   */
  get defaults(){
    return {
      type: 'user_role'
    };
  }

}

UserRole.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'permissions',
    relatedModel: UserPermission,
    collectionType: UserPermissions,
    collectionOptions: Model.defaultCollectionOptions
  }
];

class UserRoles extends Collection{}
UserRoles.prototype.model = UserRole;

export {UserRole, UserRoles};
