import {Model} from './Model';
import {Collection} from './Collection';
import {Site} from './Site';
import moment from 'moment';
import 'moment-timezone';

class Event extends Model {
  get modelUrl() {
    return '/events';
  }

  get defaults() {
    return {
      type: 'event'
    };
  }

  get displayDate() {
    const startTime = moment.tz(this.get('start_time'), this.get('timezone') ?? FW.store.get('customer').get('timezone'));
    const endTime = moment.tz(this.get('end_time'), this.get('timezone') ?? FW.store.get('customer').get('timezone'));
    if (this.get('end_time') === null) {
      return startTime.format('MMMM Do, yyyy h:mma');
    } else if (startTime.isSame(endTime, 'day')) {
      return startTime.format('MMMM Do, yyyy') + ' from ' + startTime.format('h:mma') + ' - ' + endTime.format('h:mma z');
    } else {
      return startTime.format('MMMM Do, yyyy h:mma') + ' through ' + endTime.format('MMMM Do, yyyy h:mma z');
    }
  }

  static searchKeys = ['description', 'title'];
}
Event.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'site',
    relatedModel: Site
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'featured_image',
    relatedModel: 'FW.Models.File'
  }
];

class Events extends Collection {}

Events.prototype.sortField = 'start_time';
Events.prototype.sortOrder = 'ASC';
Events.prototype.model = Event;

export {
     /**
     * The blog model
     */
    Event,
     /**
     * Collection of Blog models
     */
    Events
};
