import {Model} from './Model';
import {Collection} from './Collection';

class ApplicationFormFieldOption extends Model {

  get modelUrl() {
    return '/application-form-field-options';
  }

  get defaults() {
    return {
      type: 'application_form_field_option'
    };
  }

  prop = function() {}

  getCurrencyValue(){return 0;}

}

ApplicationFormFieldOption.prototype.relations = [
];

class ApplicationFormFieldOptions extends Collection {}
ApplicationFormFieldOptions.prototype.model = ApplicationFormFieldOption;

export {ApplicationFormFieldOptions, ApplicationFormFieldOption}
