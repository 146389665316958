import React from 'react';

export default function DonationButton(props) {

  const id = typeof props.section.prop('servant_keeper_id') !== 'undefined' && props.section.prop('servant_keeper_id') !== '' && props.section.prop('servant_keeper_id') !== null ? props.section.prop('servant_keeper_id') : (typeof FW.store.get('site').prop('sk_web_id') !== 'undefined' ? FW.store.get('site').prop('sk_web_id') : FW.store.get('customer').get('sk_web_id'));

  const urlPrefix = FW.store.get('site').prop('sk_dev_giving') ? 'giving-dev.servantkeeper.com' : 'giving.servantkeeper.com';

  return <div style={{textAlign: 'center'}}>
    <a href={'https://' + urlPrefix + '/' + id} target="_blank" className="btn btn-primary btn-lg">Donate</a>
  </div>;
}
