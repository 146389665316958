import {Model} from './Model';
import {Collection} from './Collection';

class ApplicationStatus extends Model {

  get modelUrl() {
    return '/application-statuses';
  }

  get defaults() {
    return {
      type: 'application_status'
    };
  }

}

class ApplicationStatuses extends Collection {}
ApplicationStatuses.prototype.model = ApplicationStatus;

export {ApplicationStatuses, ApplicationStatus}
