import React from 'react';
import GroupBackground from './GroupBackground';

class FooterGroupView extends React.Component{

  render(){
    let group = this.props.group;
    return <div
      style={{
        overflow: 'hidden',
        minHeight: group.ls('min_height'),
        paddingTop: group.getPadding()
      }}
      className="noWrapperGroup"
    >
      <GroupBackground group={group} />
      <div>
        <div className="row group-section-holder">
          {this.props.children}
        </div>
      </div>
    </div>;
  }

}

export default FooterGroupView;
