import {Model} from './Model';
import {Collection} from './Collection';
import {File as FWFile} from './File';
import {Backbone} from '../../FWBackbone';
import {MembershipCategories, MembershipCategory} from './MembershipCategory';
import {MembershipStatus} from './MembershipStatus';

class MembershipDirectoryMember extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/membership-directory-members';
  }

  /**
   */
  get defaults() {
    return {
      type: 'membership_directory_member'
    };
  }

  static searchKeys = ['last_name', 'first_name'];
}

class MembershipDirectoryMembers extends Collection {}
MembershipDirectoryMembers.prototype.model = MembershipDirectoryMember;
MembershipDirectoryMembers.prototype.sortField = 'last_name';
MembershipDirectoryMembers.prototype.sortOrder = 'ASC';

MembershipDirectoryMember.prototype.relations = [
  {
    type: Backbone.Relational.HasOne,
    key: 'featured_image',
    relatedModel: FWFile
  },
  {
    type: Backbone.Relational.HasMany,
    key: 'membership_categories',
    relatedModel: MembershipCategory,
    collectionType: MembershipCategories,
    collectionOptions: Model.defaultCollectionOptions
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'membership_status',
    relatedModel: MembershipStatus
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'membership_directory',
    relatedModel: 'FW.Models.MembershipDirectory'
  },
  {
    type: Backbone.Relational.HasOne,
    key: 'family',
    relatedModel: 'FW.Models.MembershipDirectoryFamily'
  }
];

export {MembershipDirectoryMember, MembershipDirectoryMembers};
