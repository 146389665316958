import {Backbone} from 'FWBackbone';
import {Model} from './Model';
import {Collection} from './Collection';
import {ContentDefinition, ContentDefinitions} from './ContentDefinition';

class ContentDefinitionGroup extends Model{
  get includedRelations(){
    return ['content_definition'];
  }

  get modelUrl(){
    return '/content-definition-groups';
  }

  get defaults(){
    return {
      type: 'content_definition_group'
    };
  }

  availableDefinitions(){
    return new ContentDefinitions(this.get('definitions').availableDefinitions());
  }

}
ContentDefinitionGroup.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'definitions',
    relatedModel: ContentDefinition,
    collectionType: ContentDefinitions,
    collectionOptions: {
      parentPath: '/content-definition-groups',
      parentRelation: 'definition_group'
    },
    reverseRelation: {
      key: 'definition_group'
    }
  }
];

class ContentDefinitionGroups extends Collection{}
ContentDefinitionGroups.prototype.sortField = 'order_id';
ContentDefinitionGroups.prototype.sortOrder = 'ASC';
ContentDefinitionGroups.prototype.model = ContentDefinitionGroup;

export {ContentDefinitionGroup};
export {ContentDefinitionGroups};
