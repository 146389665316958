import React from 'react';
import {File as FWFile} from 'FW/Models/File';
import {Blog} from 'FW/Models/Blog';
import _ from 'underscore';
import './BlogPreview.scss';
import '../../../../_lib/dotdotdot/dist/jquery.dotdotdot';

function blogPreviewHoc(Component) {
  return class BlogPreviewHoc extends React.Component {
    constructor(props) {
      super(...arguments);
      this.wrapperEl = React.createRef();
      let blog = Blog.findOrCreate({
        id: props.section.prop('basic_options').blog_id
      });
      let posts = blog.get('posts');
      this.state = {
        blog: blog,
        posts: posts || []
      };

      this.state.posts.setPageSize(
        props.section.prop('basic_options')
          ? props.section.prop('basic_options').number_of_posts
          : 5,
        () => {
          this.forceUpdate();
        }
      );
    }

    componentDidUpdate(prevProps, prevState) {
      // better text truncation
      if (this.props.size.width !== prevProps.size.width || this.props.size.height !== prevProps.size.height) {
        clearTimeout(this.dotdotTimeout);
        this.dotdotTimeout = setTimeout(() => {
          $(this.wrapperEl.current).find('.post-content').dotdotdot();
        }, 250);
      }
    }

    componentDidMount() {
      // better text truncation
      $(this.wrapperEl.current).find('.post-content').dotdotdot();
      if (FW.inEditor())
        window.parent.FW.editor.addType('blog', this.state.blog);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      let posts = FW.store.get(
        'blog-' + newProps.section.prop('basic_options').blog_id + '-posts'
      );
      this.setState({
        posts: posts ? posts : []
      });
    }

    render() {
      return <Component posts={this.state.posts} {...this.props} wrapperEl={this.wrapperEl} />;
    }
  };
}

class BlogPreview extends React.Component {
  render() {
    let posts = this.props.posts.map(post => {
      let backgroundUrl;
      let imageId = post.get('thumbnail_image')
        ? post.get('thumbnail_image')
        : post.get('featured_image');
      if (imageId) {
        let background = FWFile.findOrCreate({id: imageId});
        backgroundUrl = background.helper.thumbnailUrl(800);
      } else {
        backgroundUrl = null;
      }

      const opts = this.props.section.prop('basic_options') || {};

      let styleDefaults = {
        height: opts.height || '230px',
        display: 'block',
        backgroundSize: opts.background_size || 'cover',
        backgroundPosition: opts.background_position || 'center',
        backgroundRepeat: 'no-repeat'
      };
      let style = backgroundUrl
        ? _.defaults(styleDefaults, {
            backgroundImage: 'url(' + backgroundUrl + ')'
          })
        : styleDefaults;

      let advancedOptions =
        this.props.section.get('properties').advanced_options || {};

      const classNames = opts.number_of_posts === 1 ? 'col-md-12' : 'col-md-6 col-md-4';

      return (
        <div key={'post-' + post.get('id')} className={classNames}>
          <div className="thumbnail post-preview">
            <a href={post.getUrl()} style={style} aria-label={post.get('title')} />
            <div className="caption">
              <h3>
                <a href={post.getUrl()} aria-label={post.get('title')}>{post.get('title')}</a>
              </h3>
              <div className="post-content" style={{maxHeight: 200}}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.getPreview(
                      advancedOptions.characters_to_display,
                      opts.show_all_line_breaks
                    )
                  }}
                ></p>
              </div>
              <a href={post.getUrl()} className="btn btn-default" role="button" aria-label="Read More">
                Read More
              </a>
            </div>
          </div>
        </div>
      );
    });

    return <span className="col-md-12 blog-preview-wrapper" ref={this.props.wrapperEl}>{posts}</span>;
  }
}

export {blogPreviewHoc as hoc};
export default blogPreviewHoc(BlogPreview);
