import React from 'react';
import _ from 'underscore';
import config from 'config';

const promiseSerial = funcs => {
  return funcs.reduce((promise, func) => {
    return promise.then(result => {
      return func();
    });
  }, Promise.resolve([]));
};

class Html extends React.Component {
  constructor(props) {
    super(...arguments);
    this.state = {};
    this.evalScriptTags = this.evalScriptTags.bind(this);
  }

  componentDidMount() {
    let setup = () => {
      $(this.iframeel)
        .find('iframe')
        .remove();
      this.setState({
        wrongType: false
      });

      let needsIframe =
        this.props.section
          .prop('content')
          .toLowerCase()
          .indexOf('<iframe ') >= 0 ||
        this.props.section.prop('type') === 'iframe';

      if (needsIframe) {
        this.appendIframe();
      } else {
        this.evalScriptTags(this.getTagsFromProps(this.props));
      }
    };
    this.props.section.on(
      'sync',
      () => {
        setup();
      },
      this
    );
    setup();
  }

  componentWillUnmount() {
    this.props.section.off(null, null, this);
  }

  getTagsFromProps(props) {
    let html = props.section.prop('content');
    let source = $('<div />').prepend(html);
    let t = source.find('script');
    let tags = [];
    t.each((i, e) => {
      tags.push({
        tag: e,
        src: e.src ? e.src : null,
        contents: e.innerHTML
      });
    });
    return tags;
  }

  appendIframe() {
    let s = document.createElement('iframe');
    s.src = '/api/v1/page-sections/' + this.props.section.get('id') + '/iframe' + window.location.hash;
    s.id = this.props.section.get('id') + '-iframe';
    s.style.width = '100%';
    s.ariaLabel = this.props.section.prop('ada_title');
    if (this.props.section.prop('fixed_height')) {
      s.style.height = this.props.section.prop('height') + 'px';
    }
    s.style.border = 'none';
    this.iframeel.appendChild(s);
  }

  evalScriptTags(tags) {
    let funcs = tags.map(t => {
      if (t.src) {
        return () => {
          return new Promise((resolve, reject) => {
            $.getScript(t.src, () => {
              console.log('SCRIPT LOADED');
              resolve();
            });
          });
        };
      } else if (t.contents) {
        return () => {
          new Promise((resolve, reject) => {
            try {
              if (t.contents.indexOf('document.write') < 0) {
                resolve(eval(t.contents));
              } else {
                this.setState({
                  wrongType: true
                });
              }
            } catch (e) {
              reject(e);
            }
          });
        };
      } else {
        return () => new Promise((r, j) => r());
      }
    });
    promiseSerial(funcs);
  }

  migrateGiving = () => {
    swal.fire({
      'icon': 'question',
      'title': 'Migrate Giving',
      'text': 'This will update all giving embeds on your site to the new Servant Keeper giving button. Are you sure you want to continue?',
      showCancelButton: true
    }).then((r) => {
      if (r.isConfirmed) {
        const url = config.API_ROOT + '/faithlife/migrate-giving';
        $.post(url, {},(r) => {
          if (r.success) {
            window.location.reload();
          }
        });
      }
    })

  }

  render() {
    let height = this.props.section.prop('height') > 0 ? this.props.section.prop('height') : 'auto';
    const content = this.props.section.prop('content');
    const faithlifeMigrationButton = FW.store.get('site').prop('faithlife_giving_migration_ready') && content.indexOf('faithlife') >= 0 && content.indexOf('give') >= 0 && content.indexOf('iframe') >= 0 && FW.inEditor();

    let needsIframe =
      this.props.section
        .prop('content')
        .toLowerCase()
        .indexOf('<iframe ') >= 0 ||
      this.props.section.prop('type') === 'iframe';
    return (
      <div
        ref={e => (this.el = e)}
        style={{height: height}}
      >
        <div ref={e => (this.iframeel = e)}></div>
        {this.state.wrongType ? (
          <div style={{textAlign: 'center', padding: 60, fontSize: 20}}>
            This type of script tag must be set to the &quot;iFrame&quot; embed
            type in the section settings.
          </div>
        ) : null}
        {!needsIframe ? (
          <div
            className={
              !this.props.section.prop('content') ? 'html-section' : ''
            }
            dangerouslySetInnerHTML={{
              __html: this.props.section.prop('content')
            }}
          />
        ) : null}
        {faithlifeMigrationButton ? <div style={{textAlign: 'center', marginTop: 20}}>
          <span className="btn btn-primary btn-lg" onClick={this.migrateGiving}>Activate Servant Keeper Giving</span>
        </div> : null}
      </div>
    );
  }
}

export default Html;
