import React from 'react';
import {hoc} from './BlogPreview';
import {File as FWFile} from 'FW/Models/File';
import _ from 'underscore';

class BlogPreviewNews extends React.Component{

  render(){
    const advancedOptions = this.props.section.get('properties').advanced_options || {};
    const opts = this.props.section.prop('basic_options') || {};

    let posts = this.props.posts.map((post) => {
      let backgroundUrl;
      let imageId = post.get('thumbnail_image') ? post.get('thumbnail_image') : post.get('featured_image');
      if (imageId){
        let background = FWFile.findOrCreate({id: imageId});
        backgroundUrl = background.helper.thumbnailUrl(800);
      } else {
        backgroundUrl = null;
      }

      let styleDefaults = {
        height: opts.height || '120px',
        minWidth: '120px',
        display: 'block',
        backgroundSize: opts.background_size || 'cover',
        backgroundPosition: opts.background_position || 'center',
        backgroundRepeat: 'no-repeat'
      };
      let style = backgroundUrl ? _.defaults(styleDefaults, {backgroundImage: 'url(' + backgroundUrl + ')'}) : styleDefaults;

      return <div key={'post-' + post.get('id')} className="col-md-12">
        <div className="thumbnail col-md-12" style={{padding: 9}}>
          <a href={post.getUrl()} style={style} className="col-md-3" aria-label={post.get('title')} />
          <div className="col-md-9">
            <h3 style={{marginTop: 0}}><a href={post.getUrl()} aria-label={post.get('title')}>{post.get('title')}</a></h3>
            {this.props.section.ls('hide_author') ? null : <h4 style={{marginTop: 0}}>{post.get('author')}</h4>}
            <p dangerouslySetInnerHTML={{__html: post.getPreview(150, opts.show_all_line_breaks)}}></p>
            <a href={post.getUrl()} className="btn btn-default" role="button" aria-label={"Read More"}>Read More</a>
          </div>
        </div>
      </div>;
    });

    return (
      <span className="col-md-12">
        {posts}
      </span>
    );
  }

}

export default hoc(BlogPreviewNews);
