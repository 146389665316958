import React from 'react';
import './Table.scss';

class FWTable extends React.Component{

  render(){
    let style = this.props.loading ? {opacity: 0.4} : {};
    return (
        <table className="table table-striped FWTable" style={style}>
          <thead>
            {this.props.headers}
          </thead>
          <tbody>
            {this.props.children}
          </tbody>
        </table>
    );
  }

}

export default FWTable;
