import {Model} from './Model';
import {Collection} from './Collection';
import {FormResponseAnswer, FormResponseAnswers} from './FormResponseAnswer';
import {Backbone} from 'FWBackbone';

class FormResponse extends Model{

  /**
   * The subUrl for this model
   */
  get modelUrl(){ return '/form-responses'; }

  /**
   */
  get defaults(){
    return {
      type: 'form_response'
    };
  }

  static searchKeys = [];

}

class FormResponses extends Collection{}
FormResponses.prototype.model = FormResponse;
FormResponses.prototype.sortField = 'created_at';
FormResponses.prototype.sortOrder = 'DESC';

FormResponse.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'answers',
    relatedModel: FormResponseAnswer,
    collectionType: FormResponseAnswers,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'response'
    }
  }
];

export {FormResponse, FormResponses};
