import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'underscore';
import FWTable from 'Administrator/Elements/Table';
import TBS from 'Administrator/Elements/TableButtons';
import TB from 'Administrator/Elements/TableButton';
import Loading from 'CommonElements/Loading';
import {withSize} from 'react-sizeme';
import './SearchTableHoc.scss';
import arrayMove from 'array-move';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
const SortableItem = sortableElement((props) => {return props.children;});
const SortableHandle = sortableHandle((props) => <span className="fa fa-navicon" style={{cursor: 'crosshair', marginRight: 5}}></span>);
const SortableContainer = sortableContainer(({children}) => {
  return <div className="">
    {children}
  </div>;
});

function searchTableHoc(Component) {
  const SearchComponent = class SearchTableHoc extends React.Component {
    constructor(props) {
      super(...arguments);

      this.searchTable = React.createRef();

      let collection = props.collection;

      let pagination = collection.pagination || {};
      pagination.size = (props.allowOrdering) ? 1000 : props.pageSize || 20;
      this.state = {
        collection: collection,
        activePage: pagination.number || 1,
        searchBoxText: collection._searchQuery || '',
        tableLoading: true,
        initialLoad: true
      };
    }

    onSortEnd = ({oldIndex, newIndex, collection, isKeySorting}) => {
      const newOrder = arrayMove(this.state.ordering, oldIndex, newIndex);
      _.each(newOrder, (item, index) => {
        const i = this.state.collection.get(item);
        i.set('sort_order', index);
      });
      this.state.collection.sort();
      this.setState({
        ordering: newOrder
      });
      this.state.collection.save();
    }

    componentDidMount() {
      this.state.collection.on(
        'sync page_change',
        () => {
          this.state.collection.sort();
          this.setState({
            tableLoading: false,
            activePage: this.state.collection.pagination.number
          });
        },
        this
      );

      if (this.state.collection._searchQuery) {
        this.state.collection.once('sync', () => {
          this.setState({
            ready: true,
            initialLoad: false
          });
        });
        this.state.collection.search(this.state.collection._searchQuery);
      } else {
        var fetch;
        if (this.props.loadAll) {
          fetch = this.state.collection.getAll({
            excludeRelations: this.props.excludeRelations
          });
        } else {
          fetch = this.state.collection.fetch();
        };

        fetch.then(() => {
          const ordering = this.state.collection.pluck('id');
          this.setState({
            initialLoad: false,
            tableLoading: false,
            ready: true,
            ordering
          });
        }).catch((e) => {
          console.log('OOPS', this.state.collection._listenId);
        });
      }
    }

    componentWillUnmount() {
      this.state.collection.off(null, null, this);
    }

    onSearch = (e) => {
      const value = e.target.value;
      this.setState({
        tableLoading: true,
        searchBoxText: value
      });
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        this.state.collection.search(value);
        this.setState({
          activePage: 1
        });
      }, 250);
    };

    handlePageChange(page) {
      this.setState({
        activePage: page,
        tableLoading: true
      }, () => {
        $(this.searchTable.current).animate({
          scrollTop: 0
        }, 500);
      });
    }

    deleteModel(item, e) {
      e.stopPropagation();
      swal
        .fire({
          title: 'Confirm',
          text: this.props.deleteMessage,
          icon: 'warning',
          showCancelButton: true
        })
        .then(d => {
          if (d.value) {
            item
              .destroy({
                wait: true,
                error: (e, a, b) => {
                  swal.fire(
                    a.responseJSON.errors[0].title,
                    a.responseJSON.errors[0].detail,
                    'error'
                  );
                }
              })
              .then(d => {
                this.forceUpdate();
              });
          }
        });
    }

    rowClick(item) {
      if (this.props.onRowClick) {
        this.props.onRowClick(item);
      }
    }

    render() {
      let cols = _.map(this.props.columns, (c, title) => {
        return <th key={`header-${title}`}>{title}</th>;
      });

      let actionColumnNeeded =
        this.props.customButtons ||
        this.props.onEdit ||
        this.props.onCopy ||
        this.props.onDelete ||
        this.props.deleteMessage;

      if (actionColumnNeeded) {
        cols.push(<th key="table-actions">Actions</th>);
      }
      if (this.props.allowOrdering) {
        cols.unshift(<th key="orderong-header"></th>);
      }
      let headers = <tr key="table-headers">{cols}</tr>;

      let page = this.props.collection.getPage(this.state.activePage) || [];
      let modelType = (new this.props.collection.model).get('type');
      let rows = page.map((item, i) => {
        if (this.props.onlyEditable && !FW.store.get('user').may('edit_' + modelType, item)) {
          return;
        }

        let deleteFunction = this.props.onDelete
          ? this.props.onDelete.bind(this, item)
          : this.deleteModel.bind(this, item);
        let customButtons = (this.props.customButtons || []).filter(button => {
          if (typeof button.hideIfTrue === 'function') {
            return !button.hideIfTrue(item);
          }
          return !(button.hideIfTrue && item.get(button.hideIfTrue)) && !(button.hideIfFalse && item.get(button.hideIfFalse) === '');
        });
        customButtons = customButtons.map((button, i) => {
          const className = typeof button.className === 'function' ? button.className(item) : button.className;
          return (
            <span
              key={`cb-${i}`}
              onClick={button.onClick.bind(this, item)}
              className={className}
            >
              {typeof button.element === 'function' ? <button.element item={item} /> : button.element}
            </span>
          );
        });

        //columns
        let columns = _.map(this.props.columns, (c, title) => {
          let value = typeof c === 'function' ? c(item) : item.get(c);
          return <td key={`column-${item.get('id')}-${title}`}>{value}</td>;
        });
        if (actionColumnNeeded) {
          columns.push(
            <td key={`actions-${item.get('id')}`}>
              <TBS>
                {customButtons}
                {this.props.onEdit ? (
                  <TB
                    key={1}
                    icon="pencil"
                    type="default"
                    onClick={this.props.onEdit.bind(this, item)}
                  />
                ) : null}
                {this.props.onCopy ? (
                  <TB
                    key={2}
                    icon="copy"
                    type="default"
                    onClick={this.props.onCopy.bind(this, item)}
                  />
                ) : null}
                {(this.props.onDelete || this.props.deleteMessage) && (FW.store.get('user').may('delete_' + modelType, item) || this.props.forceAllowDelete) ? (
                  <TB key={3} icon="trash" type="danger" onClick={deleteFunction} />
                ) : null}
              </TBS>
            </td>
          );
        }
        if (this.props.allowOrdering) {
          columns.unshift(<td style={{width: 75}} key="order-buttons">
            <div style={{width: '100%', textAlign: 'center'}}>
              <SortableHandle />
            </div>
          </td>)
        }

        return (
          <SortableItem index={item.get('sort_order') || i} key={`row-${item.get('id')}`}>
            <tr
              onClick={this.rowClick.bind(this, item)}
            >
              {columns}
            </tr>
          </SortableItem>
        );
      });

      let hideHeaders = false;
      if (rows.length === 0) {
        hideHeaders = true;
        rows.push(
          <tr key="none-found">
            <td
              colSpan={cols.length}
              style={{
                textAlign: 'center',
                padding: 50,
                fontSize: 30,
                fontWeight: 100,
                borderTop: 0
              }}
            >
              No items found
            </td>
          </tr>
        );
      } else {
      }

      let table = <div>
        {this.state.tableLoading ? <div style={{position: 'absolute', height: '100%', width: '100%'}}>
          <Loading style={{opacity: 0.5}} />
        </div>: null}
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle helperClass="test-helper" helperContainer={() => {
          return $(".FWTable tbody")[0];
        }}>
          <FWTable
            headers={!hideHeaders ? headers : null}
            loading={this.state.tableLoading}
          >
              {rows}
          </FWTable>
        </SortableContainer>
      </div>;
      const user = FW.store.get('user');

      return (
        <Component
          type={modelType}
          items={this.state.collection}
          rows={rows}
          table={table}
          {...this.props}
          ready={this.state.ready}
          onSearch={this.onSearch}
          activePage={this.state.activePage}
          handlePageChange={this.handlePageChange.bind(this)}
          searchTable={this.searchTable}
          searchBoxText={this.state.searchBoxText}
        />
      );
    }
  };
  const withSizeHOC = withSize({
    monitorHeight: true,
    refreshMode: 'throttle'
  });
  return withSizeHOC(SearchComponent);
}


export default searchTableHoc;
