import React from 'react';
import GroupView from './GroupView';
import DefaultGroupLayouts from './index';

class GroupMaster extends React.Component {

  constructor(){
    super(...arguments);
    this.anchor = React.createRef();
  }

  componentDidMount() {
    this.props.group.getGroupHeight().then(height => {
      this.setState({
        height: height
      });
    });

    this.props.group.on(
      'change:layout_settings change:properties',
      (a, b, c) => {
        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
          this.props.group.getGroupHeight().then(height => {
            this.setState({
              height: height
            });
          });
        }, 100);
      },
      this
    );

    const group = this.props.group;
    if (group.prop('anchor_name')) {
      if ('#' + group.prop('anchor_name') === window.location.hash) {
        this.scrollInterval = setInterval(() => {
          if (global.prerenderReady) {
            setTimeout(() => {
              this.anchor.current.scrollIntoView();
            }, 1000);
            clearInterval(this.scrollInterval);
          }
        }, 30);
      }
    }
  }

  componentWillUnmount() {
    this.props.group.off(null, null, this);
  }

  render() {
    let template = FW.store.get('template');
    let group = this.props.group;
    let layout = group.get('layout').get('path');
    let GroupWrapper =
      template.GroupViews[layout] || DefaultGroupLayouts[layout];
    let style = {
      display: this.props.group.prop('hide_group') && !FW.inEditor() ? 'none' : null
    };

    const groupClass = "fw-group " + (this.props.group.prop('hide_group') && !FW.inEditor() ? "fw-group-hidden" : '');

    return (
      <div
        data-fw-model="PageGroup"
        data-id={group.get('id')}
        id={`PageGroup_${group.get('id')}`}
        className={groupClass}
        data-page-group-layout-id={group.get('page_group_layout_id')}
      >
        {FW.inEditor() ? <div className="moving-section-overlay">
          <div>Move to Here</div>
        </div> : null}
        {group.prop('anchor_name') ? (
          <div id={group.prop('anchor_name')} ref={this.anchor}></div>
        ) : null}
        <GroupWrapper group={group}>
          <GroupView group={group} editor={FW.getParent().FW.editor} />
        </GroupWrapper>
      </div>
    );
  }
}

export default GroupMaster;
