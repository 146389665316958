// @flow
import _ from 'underscore';
import {Backbone} from '../FWBackbone';

class Store {
  _store: {};
  trigger: Function;
  once: Function;

  constructor() {
    this._store = {};
    _.extend(this, Backbone.Events);
    return this;
  }

  /**
   * Sets value to the store
   * @param name
   * @param object
   */
  set(name: string, object: {}) {
    let store = this;
    if (typeof name === 'object') {
      let options = _.extend(
        {
          fetch: false,
          fetchSuccess: function() {}
        },
        object
      );
      _.each(name, function(obj, key) {
        store.set(key, obj);
        if (options.fetch) {
          obj.fetch({success: options.fetchSuccess});
        }
      });
    } else {
      store._store[name] = object;
      store.trigger('update:' + name);
    }
    return object;
  }

  /**
   * Gets a value from the store
   * @param key
   * @returns {*}
   */
  get(key: string) {
    return this._store[key];
  }

  /**
   * Fire object.fetch() for everything in the store.
   */
  fetchAll() {
    _.each(this._store, function(obj) {
      obj.fetch();
    });
  }

  loaded(key: string, cb: Function) {
    let obj = this.get(key);
    if (obj) {
      cb(obj);
    } else {
      this.once('update:' + key, cb.bind(this, obj));
    }
  }
}

export {Store};
