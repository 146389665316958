import React from 'react';
import './counterStyles.scss';

class HitCounter extends React.Component {

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  componentDidMount(){
    if (!FW.inEditor()) {
      $.get(`/hit-counter/${this.props.section.get('id')}/hit`).then((r) => {
        this.setState({
          hits: Number(r)
        })
      });
    } else {
      this.setState({
        hits: Number(FW.store.get('section-' + this.props.section.get('id') + '-counter'))
      })
    }
  }

  render() {
    const className = this.props.section.prop('counter_style');
    let showBackground = false;
    switch(className){
      case 'default': showBackground = false; break;
      case 'squares': showBackground = true; break;
      case 'circles': showBackground = true; break;
    }
    const shadowCSS = this.props.section.ls('text-shadow') ? this.props.section.ls('text-shadow').shadow_css : '';
    const options = this.props.section.prop('counter_options');
    const font = options.font;
    const fontSize = options.fontSize || 12;
    const unitStyle = {
      color: options.textColor ? options.textColor : '#999',
      backgroundColor: showBackground ? options.backgroundColor || '#fff' : 'rgba(0,0,0,0)',
      fontSize: fontSize
    };
    if (font && font !== 'default') {
      unitStyle.fontFamily = font;
    }
    if(options.hide_from_public && !FW.inEditor()) {
      return <span/>;
    }

    let initialCount = this.props.section.prop('initial_count');
    initialCount = Number(initialCount);
    if(isNaN(initialCount))
      initialCount = 0;
    //Add one for current hit
    const totalHits = this.state.hits + initialCount;
    const characters = (''+totalHits).split('');
    while(characters.length < options.minDigits || 0)
      characters.unshift('0');
    const counterElements = characters.map((char, i) => {
      return <span key={i} className={'counterUnit'}>
        <span style={unitStyle} className={className}>{char}</span>
      </span>
    });

    const title = this.props.section.prop('title');
    return (
      <div style={{textAlign: 'center', margin: '0 auto', paddingBottom: '10px'}}>
          <span style={{textShadow: shadowCSS}}>
            <div>
              {counterElements}
            </div>
          </span>
      </div>
    );
  }

}

export default HitCounter;
