import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {ApplicationReference, ApplicationReferences} from './ApplicationReference';
import {Application, Applications} from './Application';

class ApplicableItem extends Model {

  get modelUrl() {
    return '/applicable-items';
  }

  get defaults() {
    return {
      type: 'applicable_item'
    };
  }

}

ApplicableItem.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'applications',
    relatedModel: Application,
    collectionType: Applications,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'applicable_item'
    }
  }
]

class ApplicableItems extends Collection {}
ApplicableItems.prototype.model = ApplicableItem;

export {ApplicableItems, ApplicableItem}
