import {Model} from './Model';
import {Collection} from './Collection';
import {Backbone} from '../../FWBackbone';
import {ApplicationFormField, ApplicationFormFields} from './ApplicationFormField';
import Validator from 'validatorjs';

class ApplicationFormPage extends Model {

  get modelUrl() {
    return '/application-form-pages';
  }

  get defaults() {
    return {
      type: 'application_form_pages'
    };
  }

  isComplete(application, language) {
    const answers = application.get('answers');
    const fields = this.get('application_form_fields');
    let messages = {};
    const rules = Object.fromEntries(fields.map((field) => {
      let fieldRules = [];
      field.get('options').forEach((o) => {
        if (o.get('required_selection')) {
          fieldRules.push('in:' + o.get('id'));
          messages['in.' + field.get('id')] = language === 'french' ? o.get('name_french') + ' doit être sélectionné.' : o.get('name') + ' must be selected.';
        }
      });
      if (field.get('required')) {
        fieldRules.push('required');
      }
      return [field.get('id'), fieldRules];
    }).filter((f, v) => {
      return f.length > 0;
    }));
    const requiredMessage = language === 'french' ? 'Réponse obligatoire' : 'This field is required.';
    const validation = new Validator(answers, rules, {
      required: requiredMessage,
      ...messages
    });
    return [validation.passes(), validation.errors.all()];
  }

}

ApplicationFormPage.prototype.relations = [
  {
    type: Backbone.Relational.HasMany,
    key: 'application_form_fields',
    relatedModel: ApplicationFormField,
    collectionType: ApplicationFormFields,
    collectionOptions: Model.defaultCollectionOptions,
    reverseRelation: {
      key: 'application_form_page'
    }
  }
]

class ApplicationFormPages extends Collection {}
ApplicationFormPages.prototype.model = ApplicationFormPage;
ApplicationFormPages.prototype.sortField = "sort_order";
ApplicationFormPages.prototype.sortOrder = "ASC";

export {ApplicationFormPages, ApplicationFormPage}
