import React from 'react';
import Loading from 'CommonElements/Loading';

class Wrapper extends React.Component {
  render() {
    let response = !this.props.loading ? (
      <div
        style={{height: '100%', width: '100%', position: 'relative', ...this.props.style}}
        className={this.props.className}
      >
        {this.props.children}
      </div>
    ) : (
      <Loading />
    );

    return response;
  }
}

export default Wrapper;
