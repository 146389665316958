import {Model} from './Model';
import prop from './Decorators/Prop';
import updatesPage from './Decorators/UpdatesPage';

@prop
@updatesPage
class PageSectionLayoutSetting extends Model {
  get modelUrl() {
    return '/page-section-layout-settings';
  }

  get defaults() {
    return {
      type: 'page_section_layout_settings'
    };
  }
}

export {PageSectionLayoutSetting};
