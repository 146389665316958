import React from 'react';
import AudioPlayer from 'Components/Audio/AudioPlayer';

class Audio extends React.Component {
  render() {
    let section = this.props.section;
    let details = section.prop('file_details');
    return (
      <AudioPlayer
        elementID={`section-${section.get('id')}`}
        audioID={details.audio_id}
        playlistID={details.playlist_id}
        autoPlay={details.autoplay}
      />
    );
  }
}

export default Audio;
