import {Model} from './Model';
import {Collection} from './Collection';

class UserPermission extends Model {
  /**
   * The subUrl for this model
   */
  get modelUrl() {
    return '/user-permissions';
  }

  /**
   */
  get defaults() {
    return {
      type: 'user_permission'
    };
  }
}

class UserPermissions extends Collection {}
UserPermissions.prototype.model = UserPermission;

export {UserPermission, UserPermissions};
